import PropTypes from "prop-types";
import React, {Component} from "react";
import Summary from "components/page/dashboard/comparative/Summary";
import ComparativeTable from "components/page/dashboard/comparative/ComparativeTable";
import ResultDetailsModal from "components/page/dashboard/predictions/resultDetailsModal/ResultDetailsModal";
import GraphsModal from "components/page/dashboard/predictions/graphsModal/GraphsModal";

export default class ComparativeResults extends Component {
    static propTypes = {
        jobExecution: PropTypes.object.isRequired,
    };

    render() {
        return (
            <React.Fragment>
                <Summary
                    statistics={this.props.jobExecution.comparative_stats[0]}
                    completedDate={this.props.jobExecution.completed_date}
                />
                <ComparativeTable execution={this.props.jobExecution} />
                <ResultDetailsModal />
                <GraphsModal />
            </React.Fragment>
        );
    }
}
