import * as actionType from "../types";

export const onResetJobs = () => {
    return {
        type: actionType.RESET_JOBS,
    };
};

export const onJobsReceived = (jobs) => {
    return {
        type: actionType.JOBS_RECEIVED,
        jobs,
    };
};

export const onJobFetchFail = (error) => {
    return {
        type: actionType.JOB_FETCH_FAIL,
        error,
    };
};

export const onArchiveJob = (jobId) => {
    return {
        type: actionType.ARCHIVE_JOB,
        jobId,
    };
};

export const onSetFilterStatus = (status, isChosen) => {
    return {
        type: actionType.SET_FILTER_STATUS,
        status,
        isChosen,
    };
};
