import * as actionType from "redux/actions/types";

export const onSetToken = (data) => {
    return {
        type: actionType.SET_TOKEN,
        data,
    };
};

export const onLoginSuccess = (token) => {
    return {
        type: actionType.LOGIN_SUCCESS,
        token,
    };
};

export const onSetInput = (field, value) => {
    return {
        type: actionType.SET_INPUT,
        field,
        value,
    };
};

export const onLogout = () => {
    return {
        type: actionType.LOG_OUT,
    };
};

export const onRestoreSession = (session) => {
    return {
        type: actionType.RESTORE_SESSION,
        session,
    };
};

export const onSetUser = (user) => {
    return {
        type: actionType.SET_USER,
        user,
    };
};
