import React from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const Button = styled.button`
    background: none;
    border: none;
    color: #888;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    line-height: 1;
    cursor: pointer;
`;

export default function CloseButton({onClick}) {
    return (
        <Button type="button" onClick={onClick}>
            <FontAwesomeIcon icon={faTimes} />
        </Button>
    );
}
