import React from "react";
import {Grid, RadioGroup, Radio, FormControlLabel} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    root: {
        border: "1px solid #ced4da",
        borderRadius: "4px",
        height: "6rem",
        display: "flex",
        alignItems: "center",
        padding: "1rem",
        margin: ".4rem",
        position: "relative",
        "&:hover": {
            background: "#F6F8FB",
        },
    },
    rootChecked: {
        background: "rgba(239, 231, 252, 0.4)",
        border: "2px solid #611AD0",
    },
    label: {
        marginLeft: "0",
        marginBottom: "0",
        padding: "1rem 2rem 1rem 0rem",
    },
    radio: {
        position: "absolute",
        right: "0",
        top: "0",
    },
    checked: {
        color: "#611ad0",
    },
    labelText: {
        fontWeight: "800",
        whiteSpace: "nowrap",
    },
    radioHelperText: {
        color: "#0062EC",
        fontWeight: "400",
    },
    radioCheckedHelperText: {
        color: "#611AD0",
        fontWeight: "500",
    },
}));

export default function TabSwitch(props) {
    const classes = useStyles();

    const isChecked = (value) => {
        return props.value === value;
    };

    const getText = (label, value) => {
        const helperText = isChecked(value) ? "Checked" : "Choose";
        return (
            <>
                <span className={classes.labelText}>
                    {label.replace(/_/g, " ")}
                </span>
                <p className={clsx(classes.radioHelperText, isChecked(value) && classes.radioCheckedHelperText)}>{helperText}</p>
            </>
        );
    };

    const renderItem = ({label, value}) => {
        const id = `group-$his.props-${label}`;
        return (
            <Grid
                item xs={6} sm={3} key={value}
            >
                <div className={clsx(classes.root, isChecked(value) && classes.rootChecked)}>
                    <FormControlLabel
                        className={classes.label}
                        key={id}
                        selected={props.value === value}
                        control={
                            <Radio
                                name={props.groupName}
                                value={value}
                                color="default"
                                checked={isChecked(value)}
                                className={clsx(classes.radio, isChecked(value) && classes.checked)}
                            />
                        }
                        onChange={props.onChange}
                        label={getText(label, value)}
                    />

                </div>
            </Grid>
        );
    };

    return (
        <RadioGroup
            row
            aria-label={props.groupName}
            name={props.groupName}
        >
            {props.options.map(renderItem)}
        </RadioGroup>
    );
}
