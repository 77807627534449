import React from "react";
import {Dialog, Box, DialogTitle, DialogActions, Button} from "@material-ui/core";

export default function SimpleDialog(props) {
    const onSubmit = () => {
        if (props.actionType === "remove") {
            props.onRemove();
        }
        else if (props.actionType === "archive") {
            props.onArchive();
        }
    };
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            <Box p={2}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogActions>
                    <Button onClick={props.onClose}>Cancel</Button>
                    <Button
                        color="primary"
                        variant="contained"
                        autoFocus
                        className="submitButton"
                        onClick={onSubmit}
                    >{props.buttonText}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
