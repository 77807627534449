import _ from "lodash";

export default function(options) {
    return _.toPairs(options).map(([value, label]) => ({label, value}));
}

export function listToSelectObject(list, capitalizeLabel = true) {
    return list.map((opt) => {
        const label = capitalizeLabel ? _.capitalize(opt) : opt;
        return {label, value: opt};
    });
}
