import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import FieldRequiredError from "components/form/FieldRequiredError";
import RegionOptions from "components/form/RegionOptions";
import {dateFormat} from "util/dateTime";
import Datetime from "react-datetime";
import CheckboxWrap from "components/form/CheckboxWrap";
import DirectionsIcon from "@material-ui/icons/Directions";
import LocalShipping from "@material-ui/icons/LocalShipping";
import LocalOffer from "@material-ui/icons/LocalOffer";
import HeadingWithIcon from "components/form/HeadingWithIcon";
import {Box, Grid, TextField, Typography} from "@material-ui/core";

export default class QuoteFormFields extends Component {
    static propTypes = {
        quote: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        submitDone: PropTypes.bool.isRequired,
        updateQuoteFormField: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Fragment>
                <HeadingWithIcon
                    variant="h5"
                    gutterBottom={true}
                    icon={<DirectionsIcon />}
                    heading="Direction"
                />
                {this.renderRegionOptions()}
                <hr />
                <HeadingWithIcon
                    variant="h5"
                    gutterBottom={true}
                    icon={<LocalShipping />}
                    heading="Shipment"
                />
                {this.renderShipmentDetails()}
                {this.renderShipmentDetailsExtra()}
                <hr />
                <HeadingWithIcon
                    variant="h5"
                    gutterBottom={true}
                    icon={<LocalOffer />}
                    heading="Offer"
                />
                {this.renderCustomerContacts()}
                {this.renderOfferDetails()}
            </Fragment>
        );
    }

    renderRegionOptions() {
        return (
            <Box pt={2}>
                <Typography variant="body1" gutterBottom style={{fontWeight: "500"}}>Route</Typography>
                <Grid container spacing={2}>
                    <RegionOptions
                        parameters={this.props.quote}
                        submitDone={this.props.submitDone}
                        onChange={this.props.updateQuoteFormField.bind(this)}
                    />
                </Grid>
            </Box>
        );
    }

    renderShipmentDetails() {
        return (
            <Box pt={2}>
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Weight (kg)"
                            type="tel"
                            onChange={(event) => this.props.updateQuoteFormField("weight", event.target.value)}
                            value={this.props.quote.weight || ""}
                        />
                        <FieldRequiredError isVisible={this.props.submitDone && this.props.errors.weight} />
                    </Grid>
                    <Grid item sm={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="LDM"
                            type="tel"
                            onChange={(event) => this.props.updateQuoteFormField("ldm", event.target.value)}
                            value={this.props.quote.ldm || ""}
                        />
                        <FieldRequiredError isVisible={this.props.submitDone && this.props.errors.ldm} />
                    </Grid>
                    <Grid item sm={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Value (&euro;)"
                            type="tel"
                            onChange={(event) => this.props.updateQuoteFormField("value", event.target.value)}
                            value={this.props.quote.value || ""}
                        />
                        <FieldRequiredError isVisible={this.props.submitDone && this.props.errors.value} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item sm={6}>
                        <label>Ready for collection</label>
                        <Datetime
                            className="datepicker"
                            value={this.props.quote.collection_date || ""}
                            timeFormat={false}
                            dateFormat={dateFormat}
                            inputProps={{placeholder: "Enter date", className: "form-control maskedAsMUIinput"}}
                            defaultValue={""}
                            onChange={(date) => this.props.updateQuoteFormField("collection_date", date)}
                        />
                        {!this.isDateValid(this.props.quote.collection_date) && <div className="text-danger">Date must be valid.</div>}
                    </Grid>
                    <Grid item sm={6}>
                        <label>Desired delivery date</label>
                        <Datetime
                            className="datepicker makeAnOffer"
                            value={this.props.quote.delivery_date || ""}
                            timeFormat={false}
                            dateFormat={dateFormat}
                            inputProps={{placeholder: "Enter date", className: "form-control maskedAsMUIinput"}}
                            defaultValue={""}
                            onChange={(date) => this.props.updateQuoteFormField("delivery_date", date)}
                        />
                        {!this.isDateValid(this.props.quote.delivery_date) && <div className="text-danger">Date must be valid.</div>}
                    </Grid>
                </Grid>
            </Box>
        );
    }

    renderShipmentDetailsExtra() {
        return (
            <Box pt={2}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CheckboxWrap
                            value={this.props.quote.dangerous_goods}
                            onChange={(event) => this.props.updateQuoteFormField("dangerous_goods", event.target.checked)}
                        >
                            Dangerous goods
                        </CheckboxWrap>
                        <CheckboxWrap
                            value={this.props.quote.temperature_sensitive}
                            onChange={(event) => this.props.updateQuoteFormField("temperature_sensitive", event.target.checked)}
                        >
                            Temperature sensitive
                        </CheckboxWrap>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    renderCustomerContacts() {
        return (
            <Box pt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Company name"
                            type="text"
                            onChange={(event) => this.props.updateQuoteFormField("company_name", event.target.value)}
                            value={this.props.quote.company_name || ""}
                        />
                        <FieldRequiredError isVisible={this.props.submitDone && this.props.errors.company_name} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Contact person"
                            type="text"
                            onChange={(event) => this.props.updateQuoteFormField("contact_person", event.target.value)}
                            value={this.props.quote.contact_person || ""}
                        />
                        <FieldRequiredError isVisible={this.props.submitDone && this.props.errors.contact_person} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Contact e-mail"
                            type="text"
                            onChange={(event) => this.props.updateQuoteFormField("contact_email", event.target.value)}
                            value={this.props.quote.contact_email || ""}
                        />
                        <FieldRequiredError isVisible={this.props.submitDone && this.props.errors.contact_email} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Contact phone"
                            type="text"
                            onChange={(event) => this.props.updateQuoteFormField("contact_phone", event.target.value)}
                            value={this.props.quote.contact_phone || ""}
                        />
                        <FieldRequiredError isVisible={this.props.submitDone && this.props.errors.contact_phone} />
                    </Grid>
                </Grid>
            </Box >
        );
    }

    renderOfferDetails() {
        return (
            <Box pt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            multiline={true}
                            rows={6}
                            label="Message"
                            type="text"
                            onChange={(event) => this.props.updateQuoteFormField("offer_text", event.target.value)}
                            value={this.props.quote.offer_text || ""}
                        />
                        <FieldRequiredError isVisible={this.props.submitDone && this.props.errors.offer_text} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Price (&euro;)"
                            type="tel"
                            onChange={(event) => this.props.updateQuoteFormField("price", event.target.value)}
                            value={this.props.quote.price || ""}
                        />
                        <FieldRequiredError isVisible={this.props.submitDone && this.props.errors.price} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Datetime
                            className="datepicker makeAnOffer"
                            value={this.props.quote.due_date || this.props.quote.collection_date || ""}
                            timeFormat={false}
                            dateFormat={dateFormat}
                            inputProps={{placeholder: "Due date", className: "form-control maskedAsMUIinput"}}
                            defaultValue={""}
                            onChange={(date) => this.props.updateQuoteFormField("due_date", date)}
                        />
                        {!this.isDateValid(this.props.quote.due_date) && <div className="text-danger">Date must be valid.</div>}
                    </Grid>
                </Grid>
            </Box>
        );
    }

    isDateValid(date) {
        return !date || (date.isValid && date.isValid());
    }
}
