import * as actionType from "redux/actions/types";

export const onSetPage = (page, jobId = null) => {
    return {
        type: actionType.SET_PAGE,
        page,
        jobId,
    };
};

export const onSetCountryList = (countryOptions) => {
    return {
        type: actionType.SET_COUNTRY_LIST,
        countryOptions,
    };
};

export const onSetCountryListFetchFail = (error) => {
    return {
        type: actionType.SET_COUNTRY_LIST_FETCH_FAIL,
        error,
    };
};
