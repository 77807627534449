import * as actionType from "redux/actions/types";

export const sales = (state = null, action) => {
    switch (action.type) {
        case actionType.SALES_RECEIVED:
            return action.sales;

        case actionType.SALES_FETCH_FAIL:
            return {error: action.error};

        default:
            return state;
    }
};
