import React, {Component} from "react";
import PropTypes from "prop-types";
import {FormControlLabel, Checkbox as MuiCheckbox} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";

const StyledCheckbox = withStyles({
    root: {
        "&$checked": {
            color: "#611ad0",
        },
    },
    checked: {},
})((props) => <MuiCheckbox color="default" {...props} />);

export default class Checkbox extends Component {
    static propTypes = {
        isChecked: PropTypes.bool.isRequired,
        children: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        return (
            <FormControlLabel
                disabled={this.props.readOnly}
                control={<StyledCheckbox
                    checked={this.props.isChecked}
                    onChange={(e) => this.props.onChange(e, this.props.children)}
                />}
                label={this.props.children}
            />
        );
    }
}
