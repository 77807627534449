import _ from "lodash";
import styled from "styled-components";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Loading from "components/notifications/Loading";
import ajax from "remotes/ajax";
import {addDefaults, prepareForSend} from "remotes/convertJob";

import {onCloseJobForm, onSaveNewJob, onSaveJobUpdate, updateJobFormField} from "redux/actions/jobs/editingJob";
import JobFormFields from "components/page/jobs/JobFormFields";
import {Button} from "@material-ui/core";
import {ArrowForward} from "@material-ui/icons";
import MuiModal from "../../modal/MuiModal";

const Predictability = styled.span`
    display: inline-block;
    margin-right: 11px;
    margin-top: 7px;
`;

export class JobForm extends Component {
    static propTypes = {
        job: PropTypes.object.isRequired,
        onCloseJobForm: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            required: ["name", "recurrance", "from_country_code", "to_country_code", "prediction_period", "training_period"],
            loading: false,
            submitDone: false,
            isPredictable: null,
        };
        this.getPredictability = _.debounce(this.getPredictability, 300);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.validateForm();

            this.getPredictability(prevProps);
        }
    }

    async getPredictability(prevProps) {
        this.setState({
            isPredictable: null,
        });

        const parameterFields = ["ignore_exclusion_lists", "from_country_code", "from_region_code", "to_country_code", "to_region_code"];
        const parameters = _.pick(this.props.job, parameterFields);
        const prevParameters = _.pick(prevProps.job, parameterFields);

        if (!_.isMatch(prevParameters, parameters) && this.props.job.from_country_code && this.props.job.to_country_code) {
            const {can_calculate} = await ajax.doCheckJob(parameters);

            this.setState({
                isPredictable: can_calculate,
            });
        }
    }

    render() {
        return (
            <MuiModal
                onClose={this.props.onCloseJobForm}
                ariaLabel="client-patterns"
                heading={this.props.job.id ? "Edit prognosis" : "Create prognosis"}
            >
                <form method="#" action="#" onSubmit={this.onSubmitJobForm.bind(this)}>
                    <JobFormFields
                        errors={this.state.errors}
                        job={this.props.job}
                        onUpdateField={this.updateField.bind(this)}
                        submitDone={this.state.submitDone}
                    />
                    <div>
                        {this.renderPredictability()}
                        {Object.keys(this.state.errors).length ? 
                            <span className="error-msg text-danger">
                                Unknown error, try again.
                            </span> : ""
                        }
                        <Button
                            variant="contained"
                            className="submitButton"
                            type="submit"
                            size="large"
                            disabled={this.disableSubmit() || this.state.loading}
                        >
                            {this.state.loading ? 
                                <Loading /> : 
                                this.props.job.id ? "Save Prognosis" : "Create Prognosis"}
                            <ArrowForward style={{margin: "0 0 2px 2px"}} />
                        </Button>
                    </div>
                </form>
            </MuiModal>
        );
    }

    disableSubmit() {
        const anyDataEntered = Object.keys(this.props.job).length;
        const anyErrorsFound = Object.keys(this.state.errors).length;
        return this.state.submitDone && (!anyDataEntered || anyErrorsFound);
    }

    updateField(field, value) {
        this.props.updateJobFormField(field, value);

        if (field === "recurrance" && value === "ONE_TIME") {
            this.props.updateJobFormField("is_default", false);
        }
    }

    renderPredictability() {
        switch (this.state.isPredictable) {
            case true:
                return <Predictability>Predictable.</Predictability>;
            case false:
                return <Predictability>Insufficient data. Try different parameters.</Predictability>;
            case null:
                return undefined;
        }
    }

    async onSubmitJobForm(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            submitDone: true,
        });

        if (this.validateForm()) {
            this.setState({
                loading: true,
                errors: {},
            });

            const missingAdded = addDefaults(this.props.job);
            const preparedData = prepareForSend(missingAdded);

            try {
                if (this.props.job.id) {
                    const returnedData = await ajax.doUpdateJob(preparedData);
                    return this.props.onSaveJobUpdate(returnedData);
                }
                else {
                    const returnedData = await ajax.doAddJob(preparedData);
                    this.props.onSaveNewJob(returnedData);
                }
            }
            catch (error) {
                this.setState({
                    loading: false,
                    errors: error,
                });
            }
        }
    }

    validateForm() {
        const isUndefined = (value) => value === undefined;
        const isEmptyString = (value) => _.isString(value) && value.trim().length === 0;

        const isInvalid = _.overSome([isUndefined, isEmptyString]);

        const missingFieldList = this.state.required.filter((field) => isInvalid(this.props.job[field]));
        const errorPairs = missingFieldList.map((field) => [field, true]);

        const errors = _.fromPairs(errorPairs);

        this.setState({
            // NB! setState is async!
            errors,
        });

        return !Object.keys(errors).length;
    }
}

function mapStateToProps(state) {
    return {
        job: state.editingJob.job,
    };
}

export default connect(
    mapStateToProps,
    {
        onSaveNewJob,
        onSaveJobUpdate,
        onCloseJobForm,
        updateJobFormField,
    },
)(JobForm);
