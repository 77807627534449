import _ from "lodash";
import * as actionType from "redux/actions/types";

export const emailSending = (state = {}, action) => {
    switch (action.type) {
        case actionType.OPEN_EMAIL_SENDING:
            return {
                predictionId: action.predictionId,
                customerName: action.customerName,
            };

        case actionType.LOAD_EMAIL_CONTACTS:
            return {
                ...state,
                contacts: action.contacts,
            };

        case actionType.LOAD_EMAIL_CONTACTS_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case actionType.REMOVE_EMAIL_SENDING_CONTACT:
            return {
                ...state,
                contacts: _.reject(state.contacts, {email_address: action.email}),
            };

        case actionType.CHANGE_NEW_CONTACT:
            return {
                ...state,
                newContact: {
                    ...state.newContact,
                    [action.field]: action.value,
                },
            };

        case actionType.ADD_NEW_CONTACT:
            return {
                ...state,
                contacts: [
                    ...state.contacts,
                    {
                        contact_name: state.newContact.name,
                        email_address: state.newContact.email,
                    },
                ],
                newContact: {},
            };

        case actionType.EMAIL_SEND_REQUESTED:
            return {};

        case actionType.EMAIL_SEND_REQUEST_FAILED:
            return {
                ...state,
                sendError: action.error,
            };

        case actionType.CLOSE_EMAIL_SENDING:
            return {};

        default:
            return state;
    }
};
