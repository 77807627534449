import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import moment from "moment";
import {onCloseQuoteView} from "redux/actions/quotes";
import {fetchQuotesOnce} from "remotes/fetching";
import Route from "components/notifications/Route";
import MuiModal from "components/modal/MuiModal";
import Info from "../../notifications/Info";
import {Box, Grid} from "@material-ui/core";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import HeadingWithIcon from "../../form/HeadingWithIcon";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import HistoryIcon from "@material-ui/icons/History";
import QuoteModalGridElement from "./QuoteModalGridElement";

export class QuoteHistory extends Component {
    static propTypes = {
        isHistoryOpen: PropTypes.bool.isRequired,
        quote: PropTypes.object.isRequired,
        onCloseQuoteView: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
        };

        // If no quotes loaded, get them.
        fetchQuotesOnce();
    }

    render() {
        if (!this.props.isHistoryOpen) {
            return false;
        }

        return (
            <MuiModal
                heading="Quote history"
                ariaLabel="quote-history"
                onClose={this.props.onCloseQuoteView}
            >

                {this.props.quote.status != "Opened" &&
                    <Info
                        severity="warning"
                        message="Customer has opened offer 10 minutes ago, but has not approved -
                        please call to contact person: +372 53306389"
                    />
                }
                <Box p={2}>
                    <HeadingWithIcon
                        variant="h5"
                        gutterBottom={true}
                        icon={<LocalShippingIcon />}
                        heading="Shipment"
                    />
                    <Box pt={2} pb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                                <QuoteModalGridElement
                                    label="Route"
                                    value={<Route params={this.props.quote} />}
                                />
                                <QuoteModalGridElement
                                    label="Weight"
                                    value={this.props.quote.weight + " kg" || ""}
                                />
                                <QuoteModalGridElement
                                    label="LDM"
                                    value={this.props.quote.ldm || ""}
                                />
                                <QuoteModalGridElement
                                    label="Value"
                                    value={this.props.quote.value + " €" || ""}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <QuoteModalGridElement
                                    label="Ready for collection"
                                    value={moment(this.props.quote.collection_date).format("DD.MM.YYYY") || ""}
                                />
                                <QuoteModalGridElement
                                    label="Desired delivery date"
                                    value={moment(this.props.quote.delivery_date).format("DD.MM.YYYY") || ""}
                                />
                                <QuoteModalGridElement
                                    label="Additional info"
                                    value={<div>
                                        {this.props.quote.dangerous_goods ? "Dangerous goods" : ""}
                                        {this.props.quote.dangerous_goods && this.props.quote.temperature_sensitive ? ", " : ""}
                                        {this.props.quote.temperature_sensitive ? "Temperature sensitive" : ""}
                                    </div>}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <hr />
                    <HeadingWithIcon
                        variant="h5"
                        gutterBottom={true}
                        icon={<LocalOfferIcon />}
                        heading="Offer"
                    />
                    <Box pt={2} pb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                                <QuoteModalGridElement
                                    label="Company name"
                                    value={this.props.quote.company_name || ""}
                                />
                                <QuoteModalGridElement
                                    label="Contact Person"
                                    value={this.props.quote.contact_person || ""}
                                />
                                <QuoteModalGridElement
                                    label="Contact Person"
                                    value={this.props.quote.contact_person || ""}
                                />

                                <QuoteModalGridElement
                                    label="Contact e-mail"
                                    value={this.props.quote.contact_email || ""}
                                />
                                <QuoteModalGridElement
                                    label="Contact phone"
                                    value={this.props.quote.contact_phone || ""}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <QuoteModalGridElement
                                    label="Price"
                                    value={this.props.quote.price + " €" || ""}
                                />
                                <QuoteModalGridElement
                                    label="Due date"
                                    value={moment(this.props.quote.due_date).format("DD.MM.YYYY") || ""}
                                />
                                <QuoteModalGridElement
                                    label="Offer text"
                                    value={this.props.quote.offer_text || ""}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <hr />
                    <HeadingWithIcon
                        variant="h5"
                        gutterBottom={true}
                        icon={<HistoryIcon />}
                        heading="Timeline"
                    />
                    <Box pt={2} pb={2}>
                        <table
                            className="table table-with-actions"
                            style={{fontSize: "1rem"}}
                        >
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Description</th>
                                    <th>User</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>29.05.2019 10:00</td>
                                    <td>Quote submitted</td>
                                    <td>aleksander.vall@flowit.ee</td>
                                </tr>
                                <tr>
                                    <td>29.05.2019 10:11</td>
                                    <td>Offer made</td>
                                    <td>admin.user@logistics.ee</td>
                                </tr>
                                <tr>
                                    <td>29.05.2019 10:22</td>
                                    <td>Offer opened</td>
                                    <td>aleksander.vall@flowit.ee</td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                </Box>
            </MuiModal >
        );
    }
}

function mapStateToProps(state) {
    return {
        isHistoryOpen: state.viewQuoteHistory.isOpen || false,
        quote: state.viewQuoteHistory.quote,
    };
}

export default connect(
    mapStateToProps,
    {onCloseQuoteView},
)(QuoteHistory);
