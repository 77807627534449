import * as actionType from "redux/actions/types";

export const onOpenGraph = (predictionId) => {
    return {
        type: actionType.OPEN_GRAPH,
        predictionId,
    };
};
export const onLoadGraph = (plots) => {
    return {
        type: actionType.LOAD_GRAPH,
        plots,
    };
};
export const onLoadGraphFailed = (error) => {
    return {
        type: actionType.LOAD_GRAPH_FAILED,
        error,
    };
};
export const onCloseGraph = () => {
    return {
        type: actionType.CLOSE_GRAPH,
    };
};
