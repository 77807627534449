import store from "redux/store";
import ajax from "remotes/ajax";
import moment from "moment";
import {onExclusionFetchFail, onExclusionsReceived} from "redux/actions/exclusions";
import {onQuoteFetchFail, onQuotesReceived} from "redux/actions/quotes";
import {onSalesFetchFail, onSalesReceived} from "redux/actions/statistics";

export async function fetchExclusions() {
    try {
        const exclusions = await ajax.doGetExclusions();
        store.dispatch(onExclusionsReceived(exclusions));
    }
    catch (error) {
        store.dispatch(onExclusionFetchFail(error.statusText));
    }
}

export async function fetchExclusionsOnce() {
    if (!store.getState().exclusions) {
        await fetchExclusions();
    }
}

export async function fetchQuotes() {
    try {
        const quotes = [
            {
                id: 1,
                collection_date: moment("2019-05-24"),
                from_country_code: "DK",
                from_region_code: "35577",
                to_country_code: "CS",
                to_region_code: "55433",
                value: "100.00",
                weight: "1111",
                ldm: "11",
                dangerous_goods: false,
                temperature_sensitive: true,
                comments: "Test",
                company_name: "Test & Co",
                contact_email: "test@flowit.ee",
                contact_person: "Contact Person",
                contact_phone: "+44 444 444 444",
                offer_text: "Payment terms: 14 days",
                price: "100.00",
                due_date: moment("2019-05-30"),
                status: "Won",
            }, {
                id: 2,
                collection_date: moment("2019-06-20"),
                delivery_date: moment("2019-06-30"),
                from_country_code: "EE",
                from_region_code: "10412",
                to_country_code: "DK",
                to_region_code: "20097",
                value: "10000.00",
                weight: "1111",
                ldm: "11",
                dangerous_goods: false,
                temperature_sensitive: true,
                comments: "",
                company_name: "Flowit Estonia OÜ",
                contact_email: "aleksander.vall@flowit.ee",
                contact_person: "Aleksander Vall",
                contact_phone: "+372 53306389",
                status: "Submitted",
            }, {
                id: 3,
                collection_date: moment("2019-05-30"),
                from_country_code: "CS",
                from_region_code: "12345",
                to_country_code: "DK",
                to_region_code: "32123",
                value: "999.00",
                weight: "1111",
                ldm: "11",
                dangerous_goods: false,
                temperature_sensitive: true,
                comments: "Test",
                company_name: "Test & Co",
                contact_email: "test@flowit.ee",
                contact_person: "Contact Person",
                contact_phone: "+44 444 444 444",
                offer_text: "Payment terms: 14 days",
                price: "100.00",
                due_date: moment("2019-05-30"),
                status: "Offer made",
            },
        ];
        store.dispatch(onQuotesReceived(quotes));
    }
    catch (error) {
        store.dispatch(onQuoteFetchFail(error.statusText));
    }
}

export async function fetchQuotesOnce() {
    if (!store.getState().quotes) {
        await fetchQuotes();
    }
}

export async function fetchSales() {
    try {
        const sales = [
            {
                salesman_id: 1,
                salesman_name: "John Smith",
                quotes_asked: "1000",
                offers_made: "900",
                offers_opened: "750",
                won: "360",
                won_rate: "40%",
            }, {
                salesman_id: 2,
                salesman_name: "Jane Smith",
                quotes_asked: "800",
                offers_made: "750",
                offers_opened: "600",
                won: "330",
                won_rate: "44%",
            }, {
                salesman_id: 3,
                salesman_name: "Rocky Balboa",
                quotes_asked: "600",
                offers_made: "600",
                offers_opened: "500",
                won: "300",
                won_rate: "50%",
            },
        ];
        store.dispatch(onSalesReceived(sales));
    }
    catch (error) {
        store.dispatch(onSalesFetchFail(error.statusText));
    }
}
