import * as actionType from "redux/actions/types";

const defaultState = {username: "", password: ""};
export const auth = (state = defaultState, action) => {
    switch (action.type) {
        case actionType.LOGIN_SUCCESS:
            return {...state, token: action.token, error: undefined};

        case actionType.SET_INPUT:
            return {...state, [action.field]: action.value};

        case actionType.RESTORE_SESSION:
            return {token: action.session.token};

        default:
            return state;
    }
};

export const user = (state = {}, action) => {
    switch (action.type) {
        case actionType.SET_USER:
            return {...state, ...action.user};

        default:
            return state;
    }
};
