import React, {Component} from "react";
import PropTypes from "prop-types";
import Sale from "components/page/statistics/Sale";
import SalesFunnel from "components/page/statistics/SalesFunnel";
import Loading from "components/notifications/Loading";
import connect from "react-redux/es/connect/connect";
import {onSalesFetchFail, onSalesReceived} from "redux/actions/statistics";
import {fetchSales} from "remotes/fetching";
import Filter from "components/page/statistics/Filter";
import {Paper, Box} from "@material-ui/core";

export class Sales extends Component {
    static propTypes = {
        sales: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        onSalesReceived: PropTypes.func.isRequired,
    };

    async componentDidMount() {
        this.props.onSalesReceived(null);

        fetchSales();
    }

    render() {
        return (
            <span>
                <Box>
                    <Paper elevation={3}>
                        <Filter />
                        {this.renderTable()}
                    </Paper>
                </Box>
                <Box pt={2}>
                    <Paper elevation={3}>
                        <SalesFunnel sales={this.props.sales} />
                    </Paper>
                </Box>
            </span>
        );
    }

    renderTable() {
        if (!this.props.sales) {
            return (
                <div className="card-content text-center">
                    <Loading />
                </div>
            );
        }

        if (this.props.sales.error) {
            return <div className="card-body text-danger text-center">Error! {this.props.sales.error}</div>;
        }

        if (!this.props.sales.length) {
            return <div className="card-content text-center">No sales statistics to show.</div>;
        }

        return (
            <div className="card-body table-full-width">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Salesman</th>
                            <th>Quotes asked</th>
                            <th>Offers made</th>
                            <th>Offers opened</th>
                            <th>Won</th>
                            <th>Won rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.sales.map((sale) => (
                            <Sale key={sale.salesman_id} sale={sale} />
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        sales: state.sales,
    };
}

export default connect(
    mapStateToProps,
    {onSalesReceived, onSalesFetchFail},
)(Sales);
