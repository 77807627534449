import _ from "lodash";
import * as actionType from "redux/actions/types";

export const exclusions = (state = null, action) => {
    switch (action.type) {
        case actionType.EXCLUSIONS_RECEIVED:
            return action.exclusions;

        case actionType.EXCLUSION_FETCH_FAIL:
            return {error: action.error};

        case actionType.SAVE_NEW_EXCLUSION:
            return [action.exclusion, ...state];

        case actionType.SAVE_EXCLUSION_UPDATE:
        case actionType.ARCHIVE_EXCLUSION:
            return state.map((exclusion) => (exclusion.id === action.exclusion.id ? action.exclusion : exclusion));

        case actionType.REMOVE_EXCLUSION:
            return _.reject(state, {id: action.exclusionId});

        default:
            return state;
    }
};

export const clients = (state = [], action) => {
    switch (action.type) {
        case actionType.SET_CLIENT_LIST:
            return action.clients;

        case actionType.SET_CLIENT_LIST_FETCH_ERROR:
            return {error: action.error};

        default:
            return state;
    }
};

export const editingExclusion = (state = {exclusion: {}}, action) => {
    switch (action.type) {
        case actionType.CREATE_EXCLUSION:
            return {
                isOpen: true,
                exclusion: {
                    is_global: false,
                },
            };
        case actionType.PREFILL_EXCLUSION_FORM:
            return {
                isOpen: true,
                exclusion: {
                    is_global: false,
                    ...action.exclusion,
                },
            };
        case actionType.EDIT_EXCLUSION:
            return {
                isOpen: true,
                exclusion: action.exclusion,
            };
        case actionType.UPDATE_EXCLUSION_CLIENT_FIELD:
            return {
                ...state,
                exclusion: {
                    ...state.exclusion,
                    cust_no: action.client_number,
                    cust_name: action.client_name,
                },
            };
        case actionType.UPDATE_EXCLUSION_FORM_FIELD:
            return {
                ...state,
                exclusion: {
                    ...state.exclusion,
                    [action.key]: action.value,
                },
            };
        case actionType.SAVE_NEW_EXCLUSION:
        case actionType.SAVE_EXCLUSION_UPDATE:
        case actionType.CLOSE_EXCLUSION_FORM:
            return {
                isOpen: false,
                exclusion: {},
            };

        default:
            return state;
    }
};
