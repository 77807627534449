import moment from "moment";
import * as actionType from "redux/actions/types";
import {dateTimeFormat} from "util/dateTime";

export const jobs = (state = null, action) => {
    switch (action.type) {
        case actionType.JOBS_RECEIVED:
            return action.jobs;

        case actionType.JOB_FETCH_FAIL:
            return {error: action.error};

        case actionType.ARCHIVE_JOB: {
            const addEndDate = (job) => ({...job, end_date: moment().format(dateTimeFormat)});
            return state.map((job) => (job.id === action.jobId ? addEndDate(job) : job));
        }
        case actionType.SAVE_NEW_JOB:
            return [action.job, ...state];

        case actionType.SAVE_JOB_UPDATE: {
            const setDefaultToFalse = (job) => ({...job, is_default: false});
            const defaultsRemoved = state.map((job) => (action.job.is_default ? setDefaultToFalse(job) : job));
            return defaultsRemoved.map((job) => (job.id === action.job.id ? action.job : job));
        }

        default:
            return state;
    }
};
