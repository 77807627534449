import _ from "lodash";
import moment from "moment";
import styled, {css} from "styled-components";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import SortArrow from "components/table/SortArrow";
import {onOpenResultDetails} from "redux/actions/dashboard/resultDetails";
import GraphButton from "components/page/dashboard/predictions/GraphButton";
import Trend from "components/page/dashboard/predictions/Trend";
import {Paper, Grid, Box} from "@material-ui/core";
import Tooltip from "components/form/Tooltip";

const StyledLink = styled.a`
    &&& {
        cursor: pointer;
        font-weight: 500,
        text-decoration: none;
        color: inherit
    }
`;

const align = css`
    text-align: center;
`;

const CenterTh = styled.th`
    ${align}
`;

const CenterTd = styled.td`
    ${align}
`;

class ComparativeTable extends Component {
    static propTypes = {
        execution: PropTypes.object.isRequired,
    };

    state = {
        column: "prev",
        direction: "desc",
    };

    render() {
        try {
            return this.renderMissing() || this.renderTable();
        }
        catch (e) {
            console.error(e); // eslint-disable-line no-console
            return <div className="text-center">Error when parsing data</div>;
        }
    }

    renderMissing() {
        if (!this.props.execution) {
            return <div className="text-center">Loading...</div>;
        }

        if (!this.props.execution.predictions.length) {
            return <div className="text-center">Nothing to show.</div>;
        }
    }

    renderTable() {
        const prepared = prepare(this.props.execution);
        const sorted = sort(prepared, this.state);

        return (
            <Grid container>
                <Grid item sm={12}>
                    <Paper elevation={3}>
                        <Box p={2}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        {this.renderTh("Client name", "name")}
                                        {this.renderCenterTh("Previous week", "prev")}
                                        {this.renderCenterTh("Current week", "cur")}
                                        {this.renderCenterTh("Next week", "next")}
                                    </tr>
                                </thead>
                                <tbody>
                                    {sorted.map((row) => {
                                        return (
                                            <tr key={row.client_number}>
                                                {this.renderClientNameRow(row)}
                                                <CenterTd>
                                                    <Tooltip title={getTitle(row.was_hit_last_week)}>
                                                        <div>
                                                            {addPercent(row.prev)}
                                                        </div>
                                                    </Tooltip>
                                                </CenterTd>
                                                <CenterTd>{addPercent(row.cur)}</CenterTd>
                                                <CenterTd>{addPercent(row.next)}</CenterTd>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    renderClientNameRow(row) {
        const nameAndNumber = `${row.client_name} (${row.client_number})`;
        return (
            <td className="confidence-td">
                <GraphButton predictionId={row.id} />
                <Trend direction={row.trend} />
                <Tooltip title="Click to see result details">
                    <StyledLink onClick={() => this.onOpenResultDetails(row.id)}>
                        {nameAndNumber}
                    </StyledLink>
                </Tooltip>
            </td>
        );
    }

    renderTh(label, field) {
        return (
            <th onClick={() => this.sortBy(field)}>
                {label}
                <SortArrow current={field} activeColumn={this.state.column} activeDirection={this.state.direction} />
            </th>
        );
    }

    renderCenterTh(label, field) {
        return (
            <CenterTh onClick={() => this.sortBy(field)}>
                {label}
                <SortArrow current={field} activeColumn={this.state.column} activeDirection={this.state.direction} />
            </CenterTh>
        );
    }

    sortBy(field) {
        const {column, direction} = this.state;
        if (column === field) {
            this.setState({
                column,
                direction: direction === "desc" ? "asc" : "desc",
            });
        }
        else {
            this.setState({
                column: field,
                direction: "desc",
            });
        }
    }

    onOpenResultDetails(predictionId) {
        this.props.onOpenResultDetails(predictionId);
    }
}

function mapStateToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    {onOpenResultDetails},
)(ComparativeTable);

function prepare(jobExecution) {
    return jobExecution.predictions.map(process);
}

function process(prediction) {
    const results = prediction.results.map(addWeek);
    const currentWeek = moment().isoWeek();
    const bestOfCurrent = bestOfWeek(results, currentWeek);
    return {
        ...prediction,
        results,
        prev: bestOfWeek(results, currentWeek - 1).confidence,
        cur: bestOfCurrent.confidence,
        next: bestOfWeek(results, currentWeek + 1).confidence,
        trend: bestOfCurrent.trend,
    };
}

function addWeek(result) {
    return {
        ...result,
        week: moment.utc(result.order_date || result.pickup_date).isoWeek(),
    };
}

function bestOfWeek(results, week) {
    const ofWeek = _.filter(results, {week});
    return _.maxBy(ofWeek, "confidence") || {};
}

function sort(results, {column, direction}) {
    return _.orderBy(results, [column], [direction]);
}

function addPercent(value) {
    if (!value) {
        return "N/A";
    }

    return `${value}%`;
}

function getTitle(isHit) {
    const successText = "Hit! Customer put in an order!";
    const missText = "Miss! Customer did not put in an order.";

    if (isHit === true) {
        return {title: successText};
    }

    if (isHit === null) {
        return {};
    }

    return missText;
}

// function getColor(isHit) {
//     if (isHit === true) {
//         return "#87cb16";
//     }

//     if (isHit === null) {
//         return "transparent";
//     }

//     return "#dc3545c1";
// }
