/* eslint-disable react/no-did-mount-set-state */
import _ from "lodash";
import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {listToSelectObject} from "util/toSelectObject";
import ajax from "remotes/ajax";
import {onSetCountryList, onSetCountryListFetchFail} from "redux/actions";
import {Grid, FormControl, InputLabel, MenuItem, TextField} from "@material-ui/core";
import MuiSelect from "components/form/MuiSelect";

class RegionOptions extends Component {
    static propTypes = {
        parameters: PropTypes.object.isRequired,
        countryOptions: PropTypes.array.isRequired,
        submitDone: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onSetCountryList: PropTypes.func.isRequired,
        onSetCountryListFetchFail: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            hasError: false,
        };
    }

    async componentDidMount() {
        if (this.props.countryOptions.length) {
            return;
        }

        try {
            this.setState({
                isLoading: true,
                hasError: false,
            });
            const countries = await ajax.doGetCountryCodes();
            this.props.onSetCountryList(_.sortBy(countries));
            this.setState({
                isLoading: false,
            });
        }
        catch (error) {
            this.props.onSetCountryListFetchFail(error.statusText);
            this.setState({
                isLoading: false,
                hasError: true,
            });
        }
    }

    render() {
        const fields = ["from_country_code", "to_country_code"];

        const anyMissing =
            _(this.props.parameters)
                .pick(fields) // get only those fields from job parameters object
                .values() // get values as list
                .value().length < fields.length; // unwrap

        return (
            <Fragment>
                <Grid item xs={3} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="fromCountryCode" style={{paddingLeft: "1rem"}}>From country</InputLabel>
                        <MuiSelect
                            name="fromCountryCode"
                            onChange={(event) => this.props.onChange("from_country_code", event.target.value)}
                            value={this.props.parameters.from_country_code || ""}
                        >
                            {this.renderOptions()}
                        </MuiSelect>
                    </FormControl>
                </Grid>
                <Grid item xs={3} sm={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Region code"
                        type="text"
                        onChange={(event) => this.onChangeRegion("from_region_code", event.target.value)}
                        value={this.props.parameters.from_region_code || ""}
                    />
                </Grid>
                <Grid item xs={3} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="toCountryCode" style={{paddingLeft: "1rem"}}>To country</InputLabel>
                        <MuiSelect
                            name="toCountryCode"
                            onChange={(event) => this.props.onChange("to_country_code", event.target.value)}
                            value={this.props.parameters.to_country_code || ""}
                        >
                            {this.renderOptions()}
                        </MuiSelect>
                    </FormControl>
                </Grid>
                <Grid item xs={3} sm={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Region code"
                        type="text"
                        onChange={(event) => this.onChangeRegion("to_region_code", event.target.value)}
                        value={this.props.parameters.to_region_code || ""}
                    />
                </Grid>
                <div>{this.props.submitDone && anyMissing && <div className="text-danger">Country codes are required.</div>}</div>
            </Fragment>
        );
    }

    onChangeRegion(field, value) {
        const cleanedValue = value.replace(/[^A-Z0-9]/gi, "");
        this.props.onChange(field, cleanedValue);
    }

    renderOptions() {
        const countries = listToSelectObject(this.props.countryOptions, false);
        return countries.map((country, index) => {
            return (
                <MenuItem
                    label={country.label}
                    value={country.value}
                    key={index}
                    name={country.value}
                >{country.label}
                </MenuItem>
            );
        });
    }
}

function mapStateToProps(state) {
    return {
        countryOptions: state.countryOptions,
    };
}

export default connect(
    mapStateToProps,
    {onSetCountryList, onSetCountryListFetchFail},
)(RegionOptions);
