import {combineReducers} from "redux";
import * as actionType from "redux/actions/types";

const confidenceDesc = {column: "confidence", direction: "desc"};
export const sortingReducer = (sortAction, defaultSorting) => (state = defaultSorting || confidenceDesc, action) => {
    switch (action.type) {
        case sortAction:
            if (state.column === action.column) {
                return {
                    column: state.column,
                    direction: state.direction === "desc" ? "asc" : "desc",
                };
            }
            else {
                return {
                    column: action.column,
                    direction: "desc",
                };
            }

        default:
            return state;
    }
};

export default combineReducers({
    predictionTable: sortingReducer(actionType.SORT_PREDICTION_RESULTS),
    shipmentsTable: sortingReducer(actionType.SORT_SHIPMENTS),
    predictionDetailsTable: sortingReducer(actionType.SORT_PREDICTION_DETAILS, {}),
});
