import {Component} from "react";

export default class EscClosable extends Component {
    constructor(props) {
        super(props);
        this.handler = this.handler.bind(this);
    }

    handler(event) {
        if (event.defaultPrevented) {
            return; // Should do nothing if the default action has been cancelled
        }

        if (this.isEsc(event)) {
            this.props.onCloseModal();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handler, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handler, false);
    }

    render() {
        return this.props.children;
    }

    isEsc(event) {
        if (event.key !== undefined) {
            return event.key === "Escape";
        }
        else if (event.keyIdentifier !== undefined) {
            return event.keyIdentifier === "Esc";
        }
        else if (event.keyCode !== undefined) {
            return event.keyCode === 27;
        }
    }
}
