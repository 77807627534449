import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import Quote from "components/page/quotes/Quote";
import Loading from "components/notifications/Loading";
import connect from "react-redux/es/connect/connect";
import {onQuoteFetchFail, onQuotesReceived} from "redux/actions/quotes";
import {fetchQuotes} from "remotes/fetching";
import {Box} from "@material-ui/core";

export class Quotes extends Component {
    static propTypes = {
        quotes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        onQuotesReceived: PropTypes.func.isRequired,
        chosenStatuses: PropTypes.object,
    };

    async componentDidMount() {
        this.props.onQuotesReceived(null);

        fetchQuotes();
    }

    render() {
        return (
            <Box>
                {this.renderTable()}
            </Box>
        );
    }

    renderTable() {
        if (!this.props.quotes) {
            return (
                <div className="text-center">
                    <Loading />
                </div>
            );
        }

        if (this.props.quotes.error) {
            return <div className="text-danger text-center">Error! {this.props.quotes.error}</div>;
        }

        if (!this.props.quotes.length) {
            return <div className="text-center">No quotes to show.</div>;
        }

        const filteredQuotes = this.filterQuotes();
        if (!filteredQuotes.length) {
            return <div className="text-center">None of the {this.props.quotes.length} quotes matched active filters.</div>;
        }

        return (
            <Box p={2}>
                <div className="table-full-width">
                    <table className="table table-with-actions">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Status</th>
                                <th>Type</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Client</th>
                                <th>Weight (kg)</th>
                                <th>LDM</th>
                                <th>Price (&euro;)</th>
                                <th className="text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredQuotes.map((quote) => (
                                <Quote key={quote.id} quote={quote} />
                            ))}
                        </tbody>
                    </table>
                </div>
            </Box>
        );
    }

    filterQuotes() {
        return this.filterByStatus(this.props.quotes);
    }

    filterByStatus(data) {
        const statusValues = this.props.chosenStatuses;
        const activeStatuses = Object.keys(statusValues).filter((status) => statusValues[status]);
        if (activeStatuses.length) {
            return data.filter((row) => {
                return _.includes(activeStatuses, row.status);
            });
        }
        return data;
    }
}

function mapStateToProps(state) {
    return {
        quotes: state.quotes,
        chosenStatuses: state.quoteSearch.chosenStatuses,
    };
}

export default connect(
    mapStateToProps,
    {onQuotesReceived, onQuoteFetchFail},
)(Quotes);
