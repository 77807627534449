import moment from "moment";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Grid, Box, Paper} from "@material-ui/core";

export default class Summary extends Component {
    static propTypes = {
        statistics: PropTypes.object,
        completedDate: PropTypes.string,
    };

    render() {
        if (!this.props.statistics) {
            return false;
        }

        const {
            next_week_cbm: cbm,
            next_week_ldm: ldm,
            next_week_payweight: payweight,
            last_week_accuracy,
        } = this.props.statistics;

        const {hi, med, low} = last_week_accuracy[0] || {};
        const week = moment(this.props.completedDate).isoWeek();

        return (
            <Grid container spacing={2} style={{marginBottom: "2rem"}}>
                <Grid item xs={12} sm={12}>
                    <Paper elevation={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Box p={2}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Prediction hitrate (w{week - 1})</th>
                                                <th />
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Hi</td>
                                                <td>Med</td>
                                                <td>Low</td>
                                            </tr>
                                            <tr>
                                                <td>{hi}%</td>
                                                <td>{med}%</td>
                                                <td>{low}%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box p={2}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Volumes prediction (w{week + 1})</th>
                                                <th />
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>LDM</td>
                                                <td>CBM</td>
                                                <td>Gross weight</td>
                                            </tr>
                                            <tr>
                                                <td>{ldm}m</td>
                                                <td>{cbm}m³</td>
                                                <td>{payweight}kg</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}
