import * as actionType from "redux/actions/types";

export const onSortPredictionResults = (column) => {
    return {
        type: actionType.SORT_PREDICTION_RESULTS,
        column,
    };
};

export const onSortPredictionDetails = (column) => {
    return {
        type: actionType.SORT_PREDICTION_DETAILS,
        column,
    };
};

export const onSortShipments = (column) => {
    return {
        type: actionType.SORT_SHIPMENTS,
        column,
    };
};
