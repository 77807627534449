import * as actionType from "redux/actions/types";

export const benefits = (state = {}, action) => {
    switch (action.type) {
        case actionType.RESET_BENEFITS:
        case actionType.RESET_DASHBOARD:
            return {};

        case actionType.SET_BENEFITS:
            return action.benefits;

        case actionType.BENEFIT_FETCH_FAIL:
            return {error: action.error};

        default:
            return state;
    }
};
