import React, {Component} from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import Info from "components/notifications/Info";
import ajax from "remotes/ajax";
import fetchPredictions from "remotes/fetchPredictions";

export class PredictionTableInfo extends Component {
    static propTypes = {
        jobId: PropTypes.number,
        jobStatus: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            queueLength: null,
        };
    }

    async componentDidMount() {
        const minute = 1000 * 60;
        if (this.props.jobStatus === "QUEUED") {
            await this.getQueueLength();
            this.interval = setInterval(async() => {
                const newValue = this.state.queueLength - 1;

                this.setState({
                    queueLength: newValue,
                });

                if (newValue % 10 === 0) {
                    const queue_time_to_finish = await this.getQueueLength();
                    if (queue_time_to_finish === 0) {
                        this.interval && clearInterval(this.interval);
                        fetchPredictions(this.props.jobId);
                    }
                }
            }, minute);
        }
    }

    async getQueueLength() {
        const {queue_time_to_finish} = await ajax.doGetQueueLength();
        this.setState({
            queueLength: queue_time_to_finish,
        });

        return queue_time_to_finish;
    }

    componentWillUnmount() {
        this.interval && clearInterval(this.interval);
    }

    render() {
        switch (this.props.jobStatus) {
            case "QUEUED": {
                const nMinutes = `in ${this.state.queueLength} minutes`;
                const anyMinute = "any minute now";
                const when = this.state.queueLength > 0 ? nMinutes : anyMinute;
                return (<Info
                    message={`Prediction is queued, will complete ${when}.`}
                    severity="info"
                />);
            }
            case "IN_PROGRESS":
                return <Info message="Calculating in progress, result list incomplete." />;

            default:
                return false;
        }
    }
}

function mapStateToProps(state) {
    return {
        jobId: state.jobId,
    };
}

export default connect(mapStateToProps)(PredictionTableInfo);
