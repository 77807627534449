import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {connect} from "react-redux";
import pages from "maps/pages";
import {onSetPage} from "redux/actions";
import {onEditJob} from "redux/actions/jobs/editingJob";
import {Button} from "@material-ui/core";

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
`;

export class JobActions extends Component {
    static propTypes = {
        job: PropTypes.object.isRequired,
        onEditJob: PropTypes.func.isRequired,
        onSetPage: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Actions>
                {this.props.job.end_date ? (
                    undefined
                ) : (
                    <Button
                        type="button"
                        className="linkStyleButton"
                        onClick={this.editJob.bind(this)}
                    >
                        EDIT
                    </Button>
                )}

                {this.props.job.executions && this.props.job.executions.length ? (
                    <Button
                        variant="contained"
                        type="submit"
                        className="submitButton"
                        style={{marginLeft: "0"}}
                        onClick={this.showResults.bind(this)}
                    >
                        RESULTS
                    </Button>
                ) : (
                    undefined
                )
                }
            </Actions>
        );
    }

    editJob() {
        const {parameters, ...baseData} = this.props.job;
        const neededParameters = _.pick(parameters[0], [
            "ignore_exclusion_lists",
            "from_country_code",
            "from_region_code",
            "to_country_code",
            "to_region_code",
        ]);
        const flattenedData = {...baseData, ...neededParameters};
        this.props.onEditJob(flattenedData);
    }

    showResults() {
        this.props.onSetPage(pages.PREDICTION, this.props.job.id);
    }
}

export default connect(
    undefined,
    {onEditJob, onSetPage},
)(JobActions);
