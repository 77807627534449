import React, {Component} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Tooltip from "components/form/Tooltip";

const Wrapper = styled.span`
    margin: 0 10px;
    display: flex;
    align-items: center;
`;

export default class Trend extends Component {
    static propTypes = {
        direction: PropTypes.string,
    };

    render() {
        return (
            <Tooltip title={this.getTooltipText()}>
                <Wrapper>
                    {this.renderIcon()}
                </Wrapper>
            </Tooltip>
        );
    }

    getTooltipText() {
        return this.props.direction ? `Trend is ${this.props.direction.toLowerCase()}` : "";
    }

    renderIcon() {
        switch (this.props.direction) {
            case "POSITIVE":
                return <ArrowUpwardIcon className="positive" fontSize="large" />;
            case "NEGATIVE":
                return <ArrowDownwardIcon className="negative" fontSize="large" />;
            case "STAGNANT":
                return <ArrowForwardIcon className="stagnant" fontSize="large" />;
            default:
                return "";
        }
    }
}
