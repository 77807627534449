export const accuracyColors = {
    NONE: "primary",
    CORRECT: "success",
    UNCLEAR: "warning",
    WRONG: "danger",
};

export const stateColors = {
    FRESH: "#43A047",
    QUEUED: "#1E88E5",
    IN_PROGRESS: "#8E24AA",
    OUTDATED: "#a4a8ad",
    FAILED: "#F4511E",
};

export const quoteStatusColors = {
    Submitted: "#1E88E5",
    "Offer made": "#FFB300",
    Opened: "#8E24AA",
    Won: "#43A047",
    Archived: "#a4a8ad",
};
