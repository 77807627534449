import React, {Component} from "react";
import PropTypes from "prop-types";
import Loading from "components/notifications/Loading";
import JobsList from "components/page/jobs/JobsList";

export default class JobsWrapper extends Component {
    static propTypes = {
        jobs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    };

    render() {
        if (!this.props.jobs) {
            return (
                <div className="card card-stats">
                    <div className="card-content text-center">
                        <Loading />
                    </div>
                </div>
            );
        }

        if (this.props.jobs.error) {
            return (
                <div className="text-center card">
                    <div className="card-body text-danger">Error! {this.props.jobs.error}</div>
                </div>
            );
        }

        if (!this.props.jobs.length) {
            return (
                <div className="card card-stats">
                    <div className="card-content text-center">Nothing to show.</div>
                </div>
            );
        }

        return <JobsList jobs={this.props.jobs} />;
    }
}
