import React, {Component} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class Loading extends Component {
    render() {
        return (
            <div style={{marginLeft: "1rem"}}>
                <CircularProgress 
                    size="1rem"
                    color="inherit"
                />
            </div>
        );
    }
}

