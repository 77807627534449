import * as actionType from "redux/actions/types";
import {combineReducers} from "redux";
import {defaultQuoteStatusFilter} from "maps/filters";

export const chosenStatuses = (status = defaultQuoteStatusFilter, action) => {
    switch (action.type) {
        case actionType.SET_FILTER_STATUS:
            return {
                ...status,
                [action.status]: action.isChosen,
            };

        default:
            return status;
    }
};

const filterReducer = combineReducers({
    chosenStatuses,
});

const quoteSearch = (status, action) => {
    return filterReducer(status, action);
};

export default quoteSearch;
