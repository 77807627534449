import React, {Component} from "react";
import PropTypes from "prop-types";
import Exclusion from "components/page/exclude/Exclusion";
import Loading from "components/notifications/Loading";
import connect from "react-redux/es/connect/connect";
import ExclusionAddButton from "components/page/exclude/ExclusionAddButton";
import {onExclusionFetchFail, onExclusionsReceived} from "redux/actions/exclusions";
import {fetchExclusions} from "remotes/fetching";
import {Paper, Box} from "@material-ui/core";

export class Exclusions extends Component {
    static propTypes = {
        exclusions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        onExclusionsReceived: PropTypes.func.isRequired,
    };

    async componentDidMount() {
        this.props.onExclusionsReceived(null);

        fetchExclusions();
    }

    render() {
        return (
            <Paper elevation={3}>
                <Box p={2}>
                    <ExclusionAddButton />
                    {this.renderTable()}
                </Box>
            </Paper>
        );
    }

    renderTable() {
        if (!this.props.exclusions) {
            return (
                <div className="card-content text-center">
                    <Loading />
                </div>
            );
        }

        if (this.props.exclusions.error) {
            return <div className="card-body text-danger text-center">Error! {this.props.exclusions.error}</div>;
        }

        if (!this.props.exclusions.length) {
            return <div className="card-content text-center">No exclusions to show.</div>;
        }

        return (
            <div className="card-body table-full-width">
                <table className="table table-with-actions">
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Exclusion name</th>
                            <th>Client</th>
                            <th>Details</th>
                            <th>Status</th>
                            <th className="text-right">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.exclusions.map((exclusion, index) => (
                            <Exclusion
                                key={index}
                                exclusion={exclusion}
                            />
                        ))}
                    </tbody>
                </table>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        exclusions: state.exclusions,
    };
}

export default connect(
    mapStateToProps,
    {onExclusionsReceived, onExclusionFetchFail},
)(Exclusions);
