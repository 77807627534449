import * as actionType from "redux/actions/types";

export const onQuotesReceived = (quotes) => {
    return {
        type: actionType.QUOTES_RECEIVED,
        quotes,
    };
};

export const onQuoteFetchFail = (error) => {
    return {
        type: actionType.QUOTE_FETCH_FAIL,
        error,
    };
};

export const onViewQuote = (quote) => {
    return {
        type: actionType.VIEW_QUOTE,
        quote,
    };
};

export const onCloseQuoteView = () => {
    return {
        type: actionType.CLOSE_QUOTE_VIEW,
    };
};

export const onPrefillQuoteForm = (quote) => {
    return {
        type: actionType.PREFILL_QUOTE_FORM,
        quote,
    };
};

export const onEditQuote = (quote) => {
    return {
        type: actionType.EDIT_QUOTE,
        quote,
    };
};

export const onSaveQuoteUpdate = (quote) => {
    return {
        type: actionType.SAVE_QUOTE_UPDATE,
        quote,
    };
};

export const onCloseQuoteForm = () => {
    return {
        type: actionType.CLOSE_QUOTE_FORM,
    };
};

export const onArchiveQuote = (quote) => {
    return {
        type: actionType.ARCHIVE_QUOTE,
        quote,
    };
};

export const updateQuoteFormField = (key, value) => {
    return {
        type: actionType.UPDATE_QUOTE_FORM_FIELD,
        key,
        value,
    };
};

export const onSetFilterStatus = (status, isChosen) => {
    return {
        type: actionType.SET_FILTER_STATUS,
        status,
        isChosen,
    };
};
