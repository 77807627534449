import * as actionType from "redux/actions/types";

export const editingJob = (state = {}, action) => {
    switch (action.type) {
        case actionType.CREATE_JOB:
            return {
                isOpen: true,
                job: {
                    recurrance: "WEEKLY",
                    prediction_period: "WEEK",
                    training_period: "12",
                    timeseries_type: "ORDER_DATE",
                    is_default: false,
                    ignore_exclusion_lists: true,
                },
            };
        case actionType.EDIT_JOB:
            return {
                isOpen: true,
                job: action.job,
            };
        case actionType.UPDATE_JOB_FORM_FIELD:
            return {
                ...state,
                job: {
                    ...state.job,
                    [action.key]: action.value,
                },
            };
        case actionType.SAVE_NEW_JOB:
        case actionType.SAVE_JOB_UPDATE:
        case actionType.CLOSE_JOB_FORM:
            return {
                isOpen: false,
                job: {},
            };
        default:
            return state;
    }
};
