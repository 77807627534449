import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ExclusionForm from "components/page/exclude/ExclusionForm";

class ExclusionModal extends Component {
    static propTypes = {
        isFormOpen: PropTypes.bool.isRequired,
    };

    render() {
        if (!this.props.isFormOpen) {
            return false;
        }

        return <ExclusionForm />;
    }
}

function mapStateToProps(state) {
    return {
        isFormOpen: state.editingExclusion.isOpen || false,
    };
}

export default connect(mapStateToProps)(ExclusionModal);
