import _ from "lodash";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {connect} from "react-redux";
import PredictionCard from "components/page/dashboard/predictions/PredictionCard";
import Pagination from "components/pagination/Pagination";
import resultSorter from "util/resultSorter";
import {onExclusionFetchFail, onExclusionsReceived} from "redux/actions/exclusions";
import isExclusionMatch from "components/page/dashboard/predictions/isExclusionMatch";
import {fetchExclusionsOnce} from "remotes/fetching";
import {isArchived} from "util/decisions";

export class PredictionTableWrapper extends Component {
    static propTypes = {
        jobExecution: PropTypes.object.isRequired,
        predictionTableSorting: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            perPage: 50,
            filterAccuracy: {
                NONE: true,
                CORRECT: false,
                UNCLEAR: true,
                WRONG: false,
            },
            isSpotOnly: false,
        };
    }

    render() {
        const results = this.getFilteredResults();

        const {direction, column} = this.props.predictionTableSorting;
        const sortedResults = resultSorter(results, {direction, column});

        const start = this.state.page * this.state.perPage;
        const end = (this.state.page + 1) * this.state.perPage;
        const total = results.length;
        const pages = Math.ceil(total / this.state.perPage);

        return (
            <React.Fragment>
                <PredictionCard
                    perPage={this.state.perPage}
                    onSetPerPage={this.onSetPerPage.bind(this)}
                    tableData={_.slice(sortedResults, start, end)}
                    start={start}
                    end={end < total ? end : total}
                    total={total}
                    totalResults={this.getPredictions().length}
                    jobStatus={this.props.jobExecution.job.display_status}
                    filterAccuracy={this.state.filterAccuracy} updateFilter={this.updateFilter.bind(this)}
                    isSpotOnly={this.state.isSpotOnly}
                    toggleSpotOnly={::this.toggleSpotOnly}
                />
                <Pagination current={this.state.page} n={pages} onSetPage={this.onSetPage.bind(this)} perPage={this.state.perPage} />
            </React.Fragment>
        );
    }

    getFilteredResults() {
        const predictions = this.getPredictions();
        const flatResults = this.flattenResults(predictions);
        const accuracyFiltered = this.filterResultsByAccuracy(flatResults);
        return this.filterBySpotOnly(accuracyFiltered);
    }

    filterBySpotOnly(results) {
        if (this.state.isSpotOnly && this.props.exclusions) {
            const filteredExclusions = this.filterExclusions().map(({cust_no}) => cust_no);
            const clientNumberSet = new Set(filteredExclusions);
            return results.filter((result) => {
                return !clientNumberSet.has(result.client_number);
            });
        }

        return results;
    }

    filterExclusions() {
        const params = this.props.jobExecution.job.parameters[0];
        return this.props.exclusions.filter((exclusion) => {
            if (isArchived(exclusion)) {
                return false;
            }

            return isExclusionMatch(exclusion, params);
        });
    }

    filterResultsByAccuracy(flatResults) {
        const accuracies = this.state.filterAccuracy;
        const activeAccuracies = Object.keys(accuracies).filter((row) => accuracies[row]);
        if (activeAccuracies.length) {
            return flatResults.filter((row) => {
                if (accuracies.NONE && !row.prediction_accuracy) {
                    return true;
                }

                return _.includes(activeAccuracies, row.prediction_accuracy);
            });
        }
        return flatResults;
    }

    getPredictions() {
        const predictions = this.props.jobExecution.predictions;
        if (predictions && predictions.length) {
            return predictions;
        }

        return [];
    }

    async toggleSpotOnly(isSpotOnly) {
        this.setState({isSpotOnly});

        if (isSpotOnly) {
            fetchExclusionsOnce();
        }
    }

    flattenResults(predictions) {
        return predictions.map(({results, id: predictionId, ...prediction}) => {
            const filteredResults = results.filter((result) => {
                return result.result_type != "WEEKLY";
            });
            return {predictionId, ...filteredResults[0], ...prediction}; 
        });
    }

    onSetPage(event, pageNr) {
        this.setState({
            page: pageNr - 1,
        });
    }

    onSetPerPage(perPage) {
        let pageNr = this.state.page;
        const newStart = this.state.page * perPage;
        const total = this.getFilteredResults().length;
        const pages = Math.ceil(total / perPage);

        if (newStart > total) {
            pageNr = pages - 1;
        }

        this.setState({
            perPage: perPage,
            page: pageNr,
        });
    }

    updateFilter(field, checked) {
        this.setState({
            filterAccuracy: {
                ...this.state.filterAccuracy,
                [field]: checked,
            },
        });
    }
}

function mapStateToProps(state) {
    return {
        predictionTableSorting: state.sorting.predictionTable,
        exclusions: state.exclusions,
    };
}

export default connect(mapStateToProps, {
    onExclusionsReceived,
    onExclusionFetchFail,
})(PredictionTableWrapper);
