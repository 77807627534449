import {combineReducers} from "redux";
import * as actionType from "redux/actions/types";
import {auth, user} from "redux/reducers/auth";
import {clients, editingExclusion, exclusions} from "redux/reducers/exclusions";
import {editingQuote, viewQuoteHistory, quotes} from "redux/reducers/quotes";
import quoteSearch from "redux/reducers/quoteSearch";
import {sales} from "redux/reducers/sales";
import statisticsFilter from "redux/reducers/statisticsFilter";
import {jobs} from "redux/reducers/jobs/jobs";
import jobSearch from "redux/reducers/jobSearch";
import pages from "maps/pages";
import {jobExecution} from "redux/reducers/dashboard/dashboard";
import {resultDetails} from "redux/reducers/dashboard/resultDetails";
import {benefits} from "redux/reducers/dashboard/benefits";
import {editingJob} from "redux/reducers/jobs/editingJob";
import {emailSending} from "redux/reducers/dashboard/emailSending";
import {graphs} from "redux/reducers/dashboard/graphs";
import sorting from "redux/reducers/dashboard/sorting";

const page = (state = pages.LOGIN, action) => {
    switch (action.type) {
        case actionType.SET_PAGE:
            return action.page;

        case actionType.LOGIN_SUCCESS:
            return pages.DASHBOARD;

        case actionType.RESTORE_SESSION:
            return action.session.page;

        default:
            return state;
    }
};

const jobId = (state = null, action) => {
    switch (action.type) {
        case actionType.SET_PAGE:
            return action.jobId || null;

        case actionType.RESTORE_SESSION:
            return action.session.jobId || null;

        default:
            return state;
    }
};

const countryOptions = (state = [], action) => {
    switch (action.type) {
        case actionType.SET_COUNTRY_LIST:
            return action.countryOptions;

        default:
            return state;
    }
};

const appReducer = combineReducers({
    auth,
    benefits,
    clients,
    countryOptions,
    editingExclusion,
    editingJob,
    emailSending,
    graphs,
    exclusions,
    jobExecution,
    jobId,
    jobs,
    jobSearch,
    page,
    resultDetails,
    sorting,
    user,
    editingQuote,
    viewQuoteHistory,
    quotes,
    quoteSearch,
    sales,
    statisticsFilter,
});

const rootReducer = (state, action) => {
    if (action.type === actionType.LOG_OUT) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
