import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
// import AiPromo from "components/page/dashboard/AiPromo";
import {fetchBenefits} from "remotes/fetchPredictions";

class Benefits extends Component {
    static propTypes = {
        // benefits: PropTypes.object.isRequired,
        executionId: PropTypes.number,
    };

    render() {
        return (
            <div>
                {/* <AiPromo*/}
                {/*    color="warning"*/}
                {/*    error={!!this.props.benefits.error}*/}
                {/*    icon="pe-7s-target"*/}
                {/*    title="Active predictions"*/}
                {/*    unit="shipments"*/}
                {/*    updateNow={this.updateBenefits.bind(this)}*/}
                {/*    value={this.props.benefits.next_week_potential}*/}
                {/* />*/}
                {/* <AiPromo */}
                {/*    color="success"*/}
                {/*    error={!!this.props.benefits.error}*/}
                {/*    icon="pe-7s-server"*/}
                {/*    title="Estimated LDM"*/}
                {/*    unit="LDM"*/}
                {/*    updateNow={this.updateBenefits.bind(this)}*/}
                {/*    value={this.props.benefits.estimated_ldm}*/}
                {/* />*/}
                {/* <AiPromo*/}
                {/*    color="primary"*/}
                {/*    error={!!this.props.benefits.error}*/}
                {/*    icon="pe-7s-mail"*/}
                {/*    title="Time saved by automated e-mails 30 days"*/}
                {/*    unit="min"*/}
                {/*    updateNow={this.updateBenefits.bind(this)}*/}
                {/*    value={this.props.benefits.time_saved_by_emails}*/}
                {/* />*/}
            </div>
        );
    }

    updateBenefits() {
        fetchBenefits(this.props.executionId);
    }
}

function mapStateToProps(state) {
    return {
        benefits: state.benefits,
        executionId: _.get(state, ["jobExecution", "id"]),
    };
}

export default connect(mapStateToProps)(Benefits);
