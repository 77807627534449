import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import ClientSelect from "components/page/exclude/ClientSelect";
import RegionOptions from "components/form/RegionOptions";
import {dateFormat} from "util/dateTime";
import Datetime from "react-datetime";
import CheckboxWrap from "components/form/CheckboxWrap";
import {Box, FormControl, Grid, TextField, InputLabel} from "@material-ui/core";
import {CalendarToday} from "@material-ui/icons";

export default class ExclusionFormFields extends Component {
    static propTypes = {
        exclusion: PropTypes.object.isRequired,
        userIsAdmin: PropTypes.bool.isRequired,
        submitDone: PropTypes.bool.isRequired,
        updateExclusionFormField: PropTypes.func.isRequired,
        updateExclusionClientField: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Fragment>
                {this.renderExclusionName()}
                {this.renderCustomerName()}
                {this.renderRegionOptions()}
                {this.renderArchiveDate()}
                {this.renderGlobalExclusionField()}
            </Fragment>
        );
    }

    renderExclusionName() {
        return (
            <Box pb={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoFocus
                            label="Exclusion name"
                            type="text"
                            onChange={(event) => this.props.updateExclusionFormField("name", event.target.value)}
                            value={this.props.exclusion.name || ""}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    }

    renderCustomerName() {
        return (
            <Box pb={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <ClientSelect
                                placeholder="Enter name"
                                onChange={(value) => this.props.updateExclusionClientField(value)}
                                value={this.formatCustomerSelectValue()}
                                label="Customer name"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    renderRegionOptions() {
        return (
            <Box pb={2}>
                <InputLabel>Route</InputLabel>
                <Grid container spacing={2}>
                    <RegionOptions
                        parameters={this.props.exclusion}
                        submitDone={this.props.submitDone}
                        onChange={this.props.updateExclusionFormField.bind(this)}
                    />
                </Grid>
            </Box>
        );
    }

    renderArchiveDate() {
        return (
            <Box pb={2}>
                {/* <InputLabel>Archive date</InputLabel> */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} className="calendar-icon-container">
                        <CalendarToday />
                        <Datetime
                            className="datepicker"
                            value={this.props.exclusion.end_date || ""}
                            timeFormat={false}
                            dateFormat={dateFormat}
                            inputProps={{placeholder: "Archive date", className: "form-control maskedAsMUIinput"}}
                            defaultValue={""}
                            onChange={(date) => this.props.updateExclusionFormField("end_date", date)}
                        />
                        {!this.isDateValid(this.props.exclusion.end_date) && <div className="text-danger">Date must be valid.</div>}
                    </Grid>
                </Grid>
            </Box>
        );
    }

    renderGlobalExclusionField() {
        if (this.props.userIsAdmin) {
            return (
                <CheckboxWrap
                    disabled={!this.props.userIsAdmin}
                    value={this.props.exclusion.is_global}
                    onChange={(event) => this.props.updateExclusionFormField("is_global", event.target.checked)}
                >
                    Apply exclusion globally (admin only)
                </CheckboxWrap>
            );
        }
        else {
            return null;
        }
    }

    isDateValid(date) {
        return !date || (date.isValid && date.isValid());
    }

    formatCustomerSelectValue() {
        const {cust_no, cust_name} = this.props.exclusion;
        if (cust_no && cust_name) {
            return {
                value: cust_no,
                label: cust_name,
            };
        }
    }
}
