import React from "react";
import Search from "components/page/quotes/Search";
import QuotesList from "components/page/quotes/QuotesList";
import QuoteModal from "components/page/quotes/QuoteModal";
import QuoteHistory from "components/page/quotes/QuoteHistory";
import {Paper} from "@material-ui/core";

export default function QuotePage() {
    return (
        <div className="content">
            <QuoteModal />
            <Paper>
                <QuoteHistory />
                <Search />
                <QuotesList />
            </Paper>
        </div>
    );
}
