import _ from "lodash";
import moment from "moment";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {dateFormat} from "util/dateTime";
import {onSortPredictionDetails} from "redux/actions/dashboard/sorting";
import SortArrow from "components/table/SortArrow";
import resultSorter from "util/resultSorter";
import Checkbox from "components/form/Checkbox";
import {Box} from "@material-ui/core";

export class PredictionsTab extends Component {
    static propTypes = {
        resultData: PropTypes.object.isRequired,
        predictionDetailsSorting: PropTypes.object.isRequired,
        onSort: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLowConfidenceHidden: true,
        };
    }

    getSortingOptions() {
        if (_.isEmpty(this.props.predictionDetailsSorting)) {
            return {
                column: this.isOrderDate() ? "order_date" : "pickup_date",
                direction: "asc",
            };
        }

        return this.props.predictionDetailsSorting;
    }

    render() {
        return (
            <div>
                <Checkbox
                    isChecked={this.state.isLowConfidenceHidden}
                    onChange={(event) => this.setState({isLowConfidenceHidden: event.target.checked})}
                >
                    Hide low confidence predictions
                </Checkbox>
                {this.renderResultsTable()}
            </div>
        );
    }

    renderResultsTable() {
        const {column, direction} = this.getSortingOptions();
        const filteredResults = this.filterResultsByConfidence(this.props.resultData.predictions.results);
        const sortedResults = resultSorter(filteredResults, {direction, column});
        if (sortedResults.length) {
            return (
                <table className="table">
                    <thead>
                        <tr>
                            <th onClick={() => this.sort("confidence")}>Confidence {this.renderSort("confidence")}</th>
                            {this.getColumns().map(({field, label}) => this.renderTh(field, label))}
                        </tr>
                    </thead>
                    <tbody>{sortedResults.map((row) => this.renderRow(row))}</tbody>
                </table>
            );
        } 
        else {
            return (
                <Box p={2}>
                    <p>No predictions to display</p>
                </Box>
            );
        }
    }

    renderTh(field, label) {
        return (
            <th key={field} onClick={() => this.sort(field)}>
                {label} {this.renderSort(field)}
            </th>
        );
    }

    renderRow(row) {
        return (
            <tr key={row.id}>
                <td>
                    {row.confidence}%
                </td>
                {this.getColumns().map(({field, isDate}) => {
                    if (isDate && row[field]) {
                        return <td key={field}>{moment(row[field]).format(dateFormat)}</td>;
                    }

                    return <td key={field}>{row[field]}</td>;
                })}
            </tr>
        );
    }

    sort(column) {
        this.props.onSort(column);
    }

    filterResultsByConfidence(results) {
        const filteredResults = results.filter(result => {
            return result.result_type != "WEEKLY";
        });
        if (this.state.isLowConfidenceHidden) {
            return filteredResults.filter(({confidence}) => confidence >= 9);
        }

        return filteredResults;
    }

    renderSort(field) {
        const {column, direction} = this.getSortingOptions();
        return <SortArrow current={field} activeColumn={column} activeDirection={direction} />;
    }

    isOrderDate() {
        const list = this.props.resultData.predictions.results;
        return !list.length || !!list[0].order_date;
    }

    getColumns() {
        const orderOrPickupDate = this.isOrderDate() ?
            {field: "order_date", label: "Order date", isDate: true} :
            {field: "pickup_date", label: "Pickup date", isDate: true};

        return [
            orderOrPickupDate,
            {field: "estimated_cbm", label: "CBM (m³)"},
            {field: "estimated_ldm", label: "LDM (m)"},
            {field: "estimated_payweight", label: "Payweight (kg)"},
        ];
    }
}

function mapStateToProps(state) {
    return {
        predictionDetailsSorting: state.sorting.predictionDetailsTable,
    };
}

export default connect(
    mapStateToProps,
    {onSort: onSortPredictionDetails},
)(PredictionsTab);
