import React, {Component} from "react";
import PropTypes from "prop-types";
import DashCardHeader from "components/page/dashboard/predictions/DashCardHeader";
import PredictionTable from "components/page/dashboard/predictions/PredictionTable";
import ResultDetailsModal from "components/page/dashboard/predictions/resultDetailsModal/ResultDetailsModal";
import EmailSendingModal from "components/page/dashboard/predictions/emailSendingModal/EmailSendingModal";
import GraphsModal from "components/page/dashboard/predictions/graphsModal/GraphsModal";
import {Paper, Box} from "@material-ui/core";

export default class PredictionCard extends Component {
    static propTypes = {
        jobStatus: PropTypes.string.isRequired,
        tableData: PropTypes.array.isRequired,
        perPage: PropTypes.number.isRequired,
        start: PropTypes.number.isRequired,
        end: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        totalResults: PropTypes.number.isRequired,
        isSpotOnly: PropTypes.bool.isRequired,
        filterAccuracy: PropTypes.object.isRequired,
        onSetPerPage: PropTypes.func.isRequired,
        updateFilter: PropTypes.func.isRequired,
        toggleSpotOnly: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Paper elevation={3}>
                <Box p={2}>
                    <DashCardHeader
                        perPage={this.props.perPage}
                        onSetPerPage={this.props.onSetPerPage}
                        filterAccuracy={this.props.filterAccuracy}
                        updateFilter={this.props.updateFilter}
                        isSpotOnly={this.props.isSpotOnly}
                        toggleSpotOnly={this.props.toggleSpotOnly}
                        predictionNum={this.props.total}
                    />
                    <PredictionTable
                        start={this.props.start}
                        end={this.props.end}
                        total={this.props.total}
                        totalResults={this.props.totalResults}
                        tableData={this.props.tableData}
                        jobStatus={this.props.jobStatus}
                    />
                    <ResultDetailsModal />
                    <EmailSendingModal />
                    <GraphsModal />
                </Box>
            </Paper>
        );
    }
}
