import React, {Component} from "react";
import PropTypes from "prop-types";
import formatRoute from "util/formatRoute";

export default class Route extends Component {
    static propTypes = {
        params: PropTypes.object,
    };

    render() {
        if (!this.props.params || (!this.props.params.from_country_code && !this.props.params.to_country_code)) {
            return false;
        }

        return <span>{formatRoute(this.props.params)}</span>;
    }
}
