import React, {Component} from "react";
import PropTypes from "prop-types";
import {Grid, Checkbox, FormControlLabel} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";

const StyledCheckbox = withStyles({
    root: {
        "&$checked": {
            color: "#611ad0",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class CheckboxWrap extends Component {
    static propTypes = {
        value: PropTypes.bool.isRequired,
        disabled: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
    };

    shouldComponentUpdate(nextProps) {
        return this.props.value !== nextProps.value ||
            this.props.disabled !== nextProps.disabled ||
            this.props.children !== nextProps.children;
    }

    render() {
        return (
            <Grid container>
                <FormControlLabel
                    disabled={this.props.disabled}
                    control={<StyledCheckbox
                        checked={this.props.value}
                        onChange={this.props.onChange}
                    />}
                    label={this.props.children}
                />
            </Grid>
        );
    }
}
