import _ from "lodash";
import styled from "styled-components";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import CheckboxLarge from "components/form/CheckboxLarge";
import {predictionStates} from "maps/classifiers";
import {onSetFilterStatus} from "redux/actions/jobs/jobs";
import {onCreateJob} from "redux/actions/jobs/editingJob";
import {defaultStatusFilter} from "maps/filters";
import BlueCirclePlusButton from "components/page/exclude/BlueCirclePlusButton";
import {Grid, Box, Typography} from "@material-ui/core";
import {stateColors} from "../../../maps/colorCodes";

const NewPredictionButton = styled.div`
    float: right;
`;

class Search extends Component {
    static propTypes = {
        chosenStates: PropTypes.object.isRequired,
        onCreateJob: PropTypes.func.isRequired,
        onSetFilterStatus: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Box p={2} pt={0}>
                <NewPredictionButton>
                    <BlueCirclePlusButton onClick={this.props.onCreateJob}>Add new prediction</BlueCirclePlusButton>
                </NewPredictionButton>
                <Typography gutterBottom style={{fontWeight: "700"}}>Status:</Typography>
                <Grid container spacing={2}>
                    {_.keys(defaultStatusFilter).map((value) => {
                        const inverted = _.invert(predictionStates);
                        const key = inverted[value];
                        return (
                            <Grid item xs={6} sm={3} key={key}>
                                <CheckboxLarge
                                    color={stateColors[key]}
                                    isChecked={!!this.props.chosenStates[value]}
                                    onChange={(event) => this.props.onSetFilterStatus(value, event.target.checked)}
                                >
                                    {value}
                                </CheckboxLarge>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box >
        );
    }

    getCheckboxColor(key) {
        switch (key) {
            case "FRESH":
                return "#43A047";
            case "QUEUED":
                return "#1E88E5";
            case "OUTDATED":
                return "#a4a8ad";
            case "FAILED":
                return "#F4511E";
            default:
                return "";
        }
    }
}

function mapStateToProps(state) {
    return {
        chosenStates: state.jobSearch.chosenStates,
    };
}

export default connect(
    mapStateToProps,
    {onCreateJob, onSetFilterStatus},
)(Search);
