import * as actionType from "redux/actions/types";

export const onCreateJob = () => {
    return {
        type: actionType.CREATE_JOB,
    };
};
export const onEditJob = (job) => {
    return {
        type: actionType.EDIT_JOB,
        job,
    };
};
export const updateJobFormField = (key, value) => {
    return {
        type: actionType.UPDATE_JOB_FORM_FIELD,
        key,
        value,
    };
};
export const onSaveNewJob = (job) => {
    return {
        type: actionType.SAVE_NEW_JOB,
        job,
    };
};
export const onSaveJobUpdate = (job) => {
    return {
        type: actionType.SAVE_JOB_UPDATE,
        job,
    };
};
export const onCloseJobForm = () => {
    return {
        type: actionType.CLOSE_JOB_FORM,
    };
};
