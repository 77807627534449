import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ajax from "remotes/ajax";
import Loading from "components/notifications/Loading";
import ResultDetailsModalContent from "components/page/dashboard/predictions/resultDetailsModal/ResultDetailsModalContent";
import {onCloseResultDetails, onLoadResultDetails, onLoadResultDetailsFailed} from "redux/actions/dashboard/resultDetails";
import {onPrefillExclusionForm} from "redux/actions/exclusions";
import ExclusionModal from "components/page/exclude/ExclusionModal";
import formatRoute from "util/formatRoute";
import {isDuplicate} from "components/page/dashboard/predictions/isExclusionMatch";
import {fetchExclusionsOnce} from "remotes/fetching";
import Info from "components/notifications/Info";
import MuiModal from "components/modal/MuiModal";

export class ResultDetailsModal extends Component {
    static propTypes = {
        predictionId: PropTypes.number,
        exclusionCreated: PropTypes.bool,
        regionParameters: PropTypes.object.isRequired,
        onLoadResultDetails: PropTypes.func.isRequired,
        onLoadResultDetailsFailed: PropTypes.func.isRequired,
        onCloseResultDetails: PropTypes.func.isRequired,
        onPrefillExclusionForm: PropTypes.func.isRequired,
    };

    componentDidMount() {
        fetchExclusionsOnce();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.predictionId || prevProps.predictionId === this.props.predictionId) {
            return;
        }

        try {
            const resultDetails = await ajax.doGetPredictionResults(this.props.predictionId);
            const adjustedResultDetails = {
                ...resultDetails,
                past_shipments: resultDetails.past_shipments.map(this.addRegionToShipment),
            };
            this.props.onLoadResultDetails(adjustedResultDetails);
        }
        catch (error) {
            this.props.onLoadResultDetailsFailed(error.statusText);
        }
    }

    render() {
        if (!this.props.predictionId) {
            return false;
        }

        return (
            <MuiModal
                onClose={this.props.onCloseResultDetails}
                ariaLabel="result-details"
                heading={this.getHeading()}
            >
                <div>{this.renderExclusionAdd()}</div>
                {this.renderContent()}
                <ExclusionModal />
            </MuiModal>
        );
    }

    renderExclusionAdd() {
        if (this.props.exclusionCreated) {
            return <Info severity="success" message="Exclusion added!" />;
        }

        if (!this.getPredictions()) {
            return false;
        }

        if (isDuplicate(this.preparedExclusion())) {
            return <Info severity="success" message="Exclusion already exists" />;
        }

        return <Info severity="info" message="Add exclusion" onClick={this.handleExclusionAdd.bind(this)} />;
    }

    getPredictions() {
        return _.get(this.props, "resultData.predictions");
    }

    preparedExclusion() {
        const predictions = this.getPredictions();
        if (!predictions) {
            return {};
        }

        const {from_country_code, from_region_code, to_country_code, to_region_code} = this.props.regionParameters;
        return {
            name: `${predictions.client_name} (${formatRoute(this.props.regionParameters)})`,
            cust_no: predictions.client_number,
            cust_name: predictions.client_name,
            from_country_code,
            from_region_code,
            to_country_code,
            to_region_code,
        };
    }

    handleExclusionAdd() {
        this.props.onPrefillExclusionForm(this.preparedExclusion());
    }

    getHeading() {
        const predictions = this.getPredictions();
        if (!predictions) {
            return "";
        }

        return `Result details for ${predictions.client_name} (${predictions.client_number})`;
    }

    renderContent() {
        if (this.props.error) {
            return <div className="card-body text-danger">Sorry, something went wrong. {this.props.error}</div>;
        }

        if (_.isEmpty(this.props.resultData)) {
            return (
                <div className="text-center">
                    <Loading />
                </div>
            );
        }

        return <ResultDetailsModalContent resultData={this.props.resultData} />;
    }

    addRegionToShipment(shipment) {
        const {from_country_code, from_region_code, to_country_code, to_region_code} = shipment;

        return {
            ...shipment,
            from_region: `${from_country_code} ${from_region_code}`,
            to_region: `${to_country_code} ${to_region_code}`,
        };
    }
}

function mapStateToProps(state) {
    return {
        predictionId: state.resultDetails.predictionId,
        error: state.resultDetails.error,
        resultData: state.resultDetails.resultData,
        exclusionCreated: state.resultDetails.exclusionCreated,
        regionParameters: _.get(state, "jobExecution.job.parameters[0]"),
    };
}

export default connect(
    mapStateToProps,
    {
        onLoadResultDetails,
        onLoadResultDetailsFailed,
        onCloseResultDetails,
        onPrefillExclusionForm,
    },
)(ResultDetailsModal);
