import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {onCreateExclusion} from "redux/actions/exclusions";
import BlueCirclePlusButton from "components/page/exclude/BlueCirclePlusButton";
import {Box} from "@material-ui/core";

class ExclusionAddButton extends Component {
    static propTypes = {
        onCreateExclusion: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Box p={1} pr={2} style={{float: "right"}}>
                <BlueCirclePlusButton onClick={this.props.onCreateExclusion}>Add new exclusion</BlueCirclePlusButton>
            </Box>
        );
    }
}

export default connect(
    undefined,
    {onCreateExclusion},
)(ExclusionAddButton);
