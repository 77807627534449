import {createTheme} from "@material-ui/core/styles";

const theme = createTheme({
    overrides: {
        MuiCssBaseline: {
            "@global": {
                html: {
                    fontSize: "80%",
                },
                body: {
                    margin: 0,
                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'," + 
                                "'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans'," + 
                                "'Helvetica Neue', sans-serif",
                    backgroundColor: "#F6F8FB",
                    color: "#1F0242",
                },
            },
        },
    },
});

export {theme};
