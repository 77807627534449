import React, {Component} from "react";
import PropTypes from "prop-types";
import Plot from "react-plotly.js";
import {saleStatus} from "maps/classifiers";

export default class SalesFunnel extends Component {
    static propTypes = {
        sales: PropTypes.array,
    };

    parseValuesFromSales() {
        const results = [];

        Object.keys(saleStatus).forEach((status) => {
            this.props.sales.forEach((sale) => {
                if (results[status]) {
                    results[status] = results[status] + Number(sale[status]);
                }
                else {
                    results[status] = Number(sale[status]);
                }
            });
        });

        return Object.values(results);
    }

    getData() {
        const total = {
            type: "funnel",
            y: [],
            x: [],
            textinfo: "label+value",
            hoverinfo: "skip",
        };
        total.y = Object.values(saleStatus);
        total.x = this.parseValuesFromSales();
        return [total];
    }

    getLayout() {
        return {
            autosize: true,
            title: "Sales funnel",
            funnelmode: "stack",
            showlegend: false,
            yaxis: {type: "category", autorange: "reversed", showticklabels: false},
            xaxis: {type: "linear"},
        };
    }

    render() {
        if (!this.props.sales) {
            return "";
        }

        return (
            <Plot
                data={this.getData()}
                layout={this.getLayout()}
                style={{width: "96%"}}
            />
        );
    }
}
