import React, {Component} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSortDown, faSortUp} from "@fortawesome/free-solid-svg-icons";

export default class SortArrow extends Component {
    static propTypes = {
        activeColumn: PropTypes.string.isRequired,
        activeDirection: PropTypes.string.isRequired,
        current: PropTypes.string.isRequired,
    };

    render() {
        if (this.props.activeColumn === this.props.current) {
            if (this.props.activeDirection === "asc") {
                return <FontAwesomeIcon className="sorting" icon={faSortUp} />;
            }

            return <FontAwesomeIcon className="sorting" icon={faSortDown} />;
        }

        return false;
    }
}
