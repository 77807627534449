import _ from "lodash";
import React, {Component} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    onAddNewContact,
    onChangeNewContact,
    onEmailSendRequested,
    onEmailSendRequestFailed,
    onRemoveEmailContact,
} from "redux/actions/dashboard/emailSending";
import ajax from "remotes/ajax";
import ContactList from "components/page/dashboard/predictions/emailSendingModal/ContactList";
import ContactAddForm from "components/page/dashboard/predictions/emailSendingModal/ContactAddForm";
import formatRoute from "util/formatRoute";

const Title = styled.h5`
    margin-top: 2em;
`;

const Textarea = styled.textarea`
    width: 558px;
    margin: 1em 0;
    height: 73px;
`;

export class EmailSendingModalContent extends Component {
    static propTypes = {
        predictionId: PropTypes.number.isRequired,
        contacts: PropTypes.array.isRequired,
        newContact: PropTypes.object.isRequired,
        sendError: PropTypes.string,
        regionParameters: PropTypes.object.isRequired,
        customerName: PropTypes.string.isRequired,
        onRemoveEmailContact: PropTypes.func.isRequired,
        onChangeNewContact: PropTypes.func.isRequired,
        onAddNewContact: PropTypes.func.isRequired,
        onEmailSendRequested: PropTypes.func.isRequired,
        onEmailSendRequestFailed: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            emailContent: this.getDefaultText(),
        };
    }

    render() {
        return (
            <div className="card-body spacier-form form-horizontal">
                <h5>Send results to:</h5>
                <ContactList contacts={this.props.contacts} removeContact={this.removeContact.bind(this)} />
                <ContactAddForm
                    newContact={this.props.newContact}
                    addContact={this.addContact.bind(this)}
                    onChangeNewContact={this.props.onChangeNewContact}
                />

                <Title>Edit e-mail content:</Title>
                <Textarea
                    className="form-control"
                    value={this.state.emailContent}
                    placeholder="Enter intro text to e-mail here. If you do not edit this field,
                    the e-mail will be sent without the intro text."
                    onChange={(event) => {
                        this.setState({emailContent: event.target.value});
                    }}
                />

                <span className="text-danger pull-right">{this.props.sendError}</span>
                <button
                    type="button"
                    disabled={!this.props.contacts.length}
                    className="btn btn-primary float-right btn-wd mr-left"
                    onClick={this.requestEmailSend.bind(this)}
                >
                    Send
                </button>
            </div>
        );
    }

    getDefaultText() {
        const customerName = this.props.customerName || "";
        const route = formatRoute(this.props.regionParameters) || "";
        return (`Vastavalt ${customerName} varasematele vedudele võiks teil olla käesoleval nädalal veovajadus suunal ${route}.`);
    }

    async removeContact(contact) {
        this.props.onRemoveEmailContact(contact.email_address);

        if (contact.id) {
            try {
                await ajax.doRemoveContact(contact.id);
            }
            catch (error) {
                // should do something
            }
        }
    }

    addContact(event) {
        event.preventDefault();
        this.props.onAddNewContact();
    }

    async requestEmailSend() {
        try {
            const postBody = {
                prediction_id: this.props.predictionId,
                emails: this.props.contacts.map(({contact_name, email_address}) => ({
                    name: contact_name,
                    email: email_address,
                })),
                email_content: this.state.emailContent,
            };
            await ajax.doSendPredictionsToEmail(postBody);
            this.props.onEmailSendRequested(this.props.predictionId);
        }
        catch (error) {
            this.props.onEmailSendRequestFailed(error.statusText + ": " + error.responseText);
        }
    }
}

function mapStateToProps(state) {
    return {
        newContact: state.emailSending.newContact || {},
        sendError: state.emailSending.sendError,
        regionParameters: _.get(state, "jobExecution.job.parameters[0]"),
    };
}

export default connect(
    mapStateToProps,
    {
        onRemoveEmailContact,
        onChangeNewContact,
        onAddNewContact,
        onEmailSendRequested,
        onEmailSendRequestFailed,
    },
)(EmailSendingModalContent);
