import React from "react";
import {FormControlLabel, Checkbox} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
    root: {
        border: "1px solid #ced4da",
        borderRadius: "4px",
        height: "5rem",
        display: "flex",
        alignItems: "center",
        padding: "1rem",
        position: "relative",
        "&:hover": {
            background: "#F6F8FB",
        },
    },
    rootChecked: {
        borderColor: (props) => props.color,
        backgroundColor: (props) => props.color + "18",
    },
    label: {
        marginLeft: "0",
        marginBottom: "0",
        padding: "2rem 14rem 2rem 2rem",
    },
    checkbox: {
        position: "absolute",
        right: "0",
        top: "0",
    },
    checked: {
        color: (props) => props.color,
    },
    labelText: {
        fontWeight: "800",
    },
    checkBoxCheckedHelperText: {
        color: "#611AD0",
        fontWeight: "500",
    },
});

export default function CheckboxLarge(props) {
    const classes = useStyles(props);
    const getText = () => {
        return (
            <>
                <span className={classes.labelText}>
                    {props.children}
                </span>
            </>
        );
    };

    return (
        <div className={clsx(classes.root, props.isChecked && classes.rootChecked)}>
            <div>
                <FormControlLabel
                    className={classes.label}
                    disabled={props.readOnly}
                    control={<Checkbox
                        color="default"
                        className={clsx(classes.checkbox, props.isChecked && classes.checked)}
                        checked={props.isChecked}
                        onChange={(e) => props.onChange(e, props.children)}
                    />}
                    label={getText()}
                />
            </div>
        </div>
    );
}

