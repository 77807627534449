import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ajax from "remotes/ajax";
import {onLoginSuccess, onSetInput} from "redux/actions/auth";
import {Container, Box, Paper, Typography, FormControl, TextField} from "@material-ui/core";
import MuiSubmitButton from "../form/MuiSubmitButton";

export class Login extends Component {
    static propTypes = {
        username: PropTypes.string,
        password: PropTypes.string,
        error: PropTypes.string,
        onSetInput: PropTypes.func.isRequired,
        onLoginSuccess: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    render() {
        return (
            <Container disableGutters maxWidth={false}>
                <Box mt={6} ml={2} mr={2}>
                    <Paper
                        elevation={3}
                        style={{maxWidth: "70rem", margin: "auto"}}
                    >
                        <Box p={8}>
                            <form className="form" onSubmit={(event) => this.logIn(event)}>
                                <Typography
                                    gutterBottom
                                    variant="h4"
                                    style={{fontWeight: "500"}}
                                >
                                    Login
                                </Typography>
                                <FormControl
                                    fullWidth={true}
                                >
                                    <Box mt={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth={true}
                                            label="Username"
                                            type="text"
                                            name="email"
                                            onChange={(event) => {
                                                this.updateInput("username", event.target.value);
                                            }}
                                            value={this.props.username || ""}
                                            error={this.isError()}
                                            helperText={this.props.error}
                                        />
                                    </Box>
                                    <Box mt={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth={true}
                                            label="Password"
                                            type="password"
                                            name="email"
                                            onChange={(event) => {
                                                this.updateInput("password", event.target.value);
                                            }}
                                            value={this.props.password || ""}
                                            error={this.isError()}
                                            helperText={this.props.error}
                                        />
                                    </Box>
                                    <Box mt={4}>
                                        <MuiSubmitButton
                                            buttonText="Login"
                                            arrowButton={false}
                                            onSubmit={(event) => this.logIn(event)}
                                            loading={this.state.loading}
                                        />
                                    </Box>
                                </FormControl>
                            </form>
                        </Box>
                    </Paper>
                </Box>
            </Container>

        );
    }

    isError() {
        return this.props.error && this.props.error.length > 0;
    }

    updateInput(field, value) {
        this.props.onSetInput(field, value);
    }

    async logIn(event) {
        event.preventDefault();

        if (!this.props.username || !this.props.password) {
            return;
        }

        this.setState({
            loading: true,
        });

        try {
            const {token} = await ajax.doLogin(this.props.username, this.props.password);
            this.props.onLoginSuccess(token);
        }
        catch (err) {
            this.setState({
                loading: false,
            });

            if (err && err.status === 400) {
                this.props.onSetInput("error", "Incorrect username or password.");
            }
            else {
                this.props.onSetInput("error", err.statusText);
            }
        }
    }
}

function mapStateToProps(state) {
    return {
        username: state.auth.username,
        password: state.auth.password,
        error: state.auth.error,
    };
}

export default connect(
    mapStateToProps,
    {onLoginSuccess, onSetInput},
)(Login);
