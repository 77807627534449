import React, {Component} from "react";
import clsx from "clsx";
import {connect} from "react-redux";
import Sidebar from "components/page/Sidebar";
import ajax from "remotes/ajax";
import {onLogout, onSetUser} from "redux/actions/auth";
import pages from "maps/pages";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {withStyles} from "@material-ui/styles";
import PropTypes from "prop-types";
import {Typography, Box} from "@material-ui/core";

const styles = () => ({
    content: {
        flexGrow: 1,
        padding: 3,
    },
});

export class LoggedInWrapper extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            sidebarOpen: false,
        };
    }

    componentDidMount() {
        ajax.doGetUser().then((user) => {
            if (user.length) {
                this.props.onSetUser(user[0]);
            }
        });
    }

    toggleSidebar = () => {
        this.setState((prevState) => ({
            sidebarOpen: !prevState.sidebarOpen,
        }));
    };

    render() {
        const {classes} = this.props;
        return (
            <>
                <Sidebar 
                    sidebarOpen={this.state.sidebarOpen} 
                    toggleSidebar={this.toggleSidebar} 
                />
                <div className={clsx("main-panel", classes.content)}>
                    <Box p={2}>
                        <nav className="navbar navbar-expand-lg">
                            <Typography
                                gutterBottom
                                variant="h4"
                                style={{fontWeight: "500"}}
                            >
                                {this.getTitle()}
                            </Typography>
                            <ExitToAppIcon onClick={this.handleLogout.bind(this)} className="pointer" />
                        </nav>
                        {this.props.children}
                    </Box>
                </div>
            </>
        );
    }

    handleLogout() {
        ajax.doLogout();
        this.props.onLogout();
    }

    getTitle() {
        switch (this.props.page) {
            case pages.PREDICTION:
                return "Results";
            case pages.DASHBOARD:
                return "New Shipments";
            case pages.JOBS:
                return "My Predictions";
            case pages.QUOTES:
                return "Quotes List";
            case pages.STATISTICS:
                return "Sales Statistics";
            case pages.EXCLUSIONS:
                return "Exclusion list";
        }
    }
}

export default connect(
    undefined,
    {onSetUser, onLogout},
)(withStyles(styles)(LoggedInWrapper));
