import React, {Component} from "react";
// import styled from "styled-components";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {onCloseGraph, onLoadGraph, onLoadGraphFailed} from "redux/actions/dashboard/graphs";
import ajax from "remotes/ajax";
import Loading from "components/notifications/Loading";
import MuiModal from "../../../../modal/MuiModal";

class GraphsModal extends Component {
    static propTypes = {
        predictionId: PropTypes.number,
        plots: PropTypes.object,
        onLoadGraph: PropTypes.func.isRequired,
        onLoadGraphFailed: PropTypes.func.isRequired,
        onCloseGraph: PropTypes.func.isRequired,
    };

    async loadPlotly() {
        try {
            const {default: Plot} = await import(/* webpackMode: "lazy" */
                "react-plotly.js");
            this.Plot = Plot;
        }
        catch (err) {
            // ignore
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.predictionId || prevProps.predictionId === this.props.predictionId) {
            return;
        }

        await this.loadPlotly();

        try {
            const plots = await ajax.doGetStatisticalData(this.props.predictionId, 6);
            this.props.onLoadGraph(plots);
        }
        catch (error) {
            this.props.onLoadGraphFailed(error.statusText);
        }
    }

    render() {
        if (!this.props.predictionId) {
            return false;
        }

        // if (!this.props.plots) {
        //     return (
        //         <div className="text-center">
        //             <Loading />
        //         </div>
        //     );
        // }

        return (
            <MuiModal
                onClose={this.props.onCloseGraph}
                ariaLabel="client-patterns"
                heading={this.getHeading()}
                fullWidth={true}
            >
                {this.renderContent()}
            </MuiModal>
        );
    }

    getHeading() {
        if (!this.props.plots) {
            return "";
        }

        return `Order patterns for ${this.props.plots.client_name} (${this.props.plots.client_number})`;
    }

    renderContent() {
        if (!this.props.plots) {
            return (
                <div className="text-center">
                    <Loading />
                </div>
            );
        }

        const Plot = this.Plot;
        return (
            <div className="card-body text-center">
                <Plot
                    data={this.props.plots.order_plot}
                    layout={{
                        autosize: true,
                        title: "Weekly order intake (past)",
                        barmode: "stack",
                        yaxis: {tickmode: "linear", tick0: 0, dtick: 1},
                    }}
                    style={{width: "100%", height: "50%"}}
                />

                <Plot
                    data={this.props.plots.probability_plot}
                    layout={{autosize: true, title: "Customer behavior pattern and trend"}}
                    style={{width: "100%", height: "50%"}}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        predictionId: state.graphs.predictionId,
        error: state.graphs.error,
        plots: state.graphs.plots,
    };
}

export default connect(
    mapStateToProps,
    {
        onCloseGraph,
        onLoadGraph,
        onLoadGraphFailed,
    },
)(GraphsModal);
