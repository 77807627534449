import * as actionType from "redux/actions/types";

export const resultDetails = (state = {}, action) => {
    switch (action.type) {
        case actionType.OPEN_RESULT_DETAILS:
            return {
                predictionId: action.predictionId,
            };

        case actionType.LOAD_RESULT_DETAILS:
            return {
                ...state,
                resultData: action.resultData,
            };

        case actionType.LOAD_RESULT_DETAILS_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case actionType.CLOSE_RESULT_DETAILS:
            return {};

        case actionType.SAVE_NEW_EXCLUSION:
            return {
                ...state,
                exclusionCreated: true,
            };

        default:
            return state;
    }
};
