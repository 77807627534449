import store from "redux/store";

export function addDefaults(data) {
    return {
        user: store.getState().user.id,
        ...data,
    };
}

export function prepareForSend(data) {
    const {
        id,
        name,
        is_default,
        recurrance,
        user,
        from_country_code,
        from_region_code,
        prediction_period,
        to_country_code,
        to_region_code,
        ignore_exclusion_lists,
        training_period,
        timeseries_type,
    } = data;

    return {
        id,
        name,
        is_default,
        recurrance,
        prediction_period,
        user,
        training_period,
        timeseries_type,
        parameters: [
            {
                ignore_exclusion_lists,
                from_country_code,
                from_region_code,
                to_country_code,
                to_region_code,
            },
        ],
    };
}
