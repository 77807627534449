import React from "react";
import AddIcon from "@material-ui/icons/Add";
import {Link, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    button: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        color: "#0062ec",
        fontWeight: "600",
    },
    text: {
        fontSize: "1.2rem",
    },
    icon: {
        marginRght: "0.5rem",
        marginBottom: "0.2rem",
    },
}));

export default function BlueCirclePlusButton(props) {
    const classes = useStyles();
    return (
        <Link type="button" className={classes.button} onClick={props.onClick}>
            <AddIcon className={classes.icon} />
            <Typography variant="body1" className={classes.text}>
                {props.children}
            </Typography>
        </Link>
    );
}
