export const predictionStates = {
    FRESH: "Fresh",
    QUEUED: "Queued",
    IN_PROGRESS: "In progress",
    OUTDATED: "Outdated",
    FAILED: "Failed",
};

export const accuracies = {
    NONE: "None",
    CORRECT: "Correct",
    UNCLEAR: "Unclear",
    WRONG: "Wrong",
};

export const accuracyFilter = {
    NONE: "None",
    CORRECT: "Matches",
    UNCLEAR: "Uncertain",
    WRONG: "Mistakes",
};

export const accuracyButton = {
    NONE: "None",
    CORRECT: "Match",
    UNCLEAR: "Uncertain",
    WRONG: "Mistake",
};

export const frequency = {
    ONE_TIME: "Single",
    WEEKLY: "Weekly",
    MONTHLY: "Monthly",
    COMPARATIVE: "Comparative",
};

export const trainingPeriods = {
    6: "6 Months",
    12: "12 Months",
    24: "24 Months",
};

export const quouteStatus = {
    SUBMITTED: "Submitted",
    OFFER: "Offer made",
    OPENED: "Opened",
    WON: "Won",
    ARCHIVED: "Archived",
};

export const saleStatus = {
    quotes_asked: "Quotes asked",
    offers_made: "Offers made",
    offers_opened: "Offers opened",
    won: "Won",
};
