import {compose, createStore, applyMiddleware} from "redux";
import {createLogger} from "redux-logger";
import {persistStore} from "redux-persist";
import rootReducer from "redux/reducers/index";
import Session from "util/Session";

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(
            createLogger({
                collapsed: true,
            }),
        ),
    ),
);
persistStore(store);
export default store;

Session.read();
store.subscribe(() => {
    Session.write();
});
