import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import CheckboxLarge from "components/form/CheckboxLarge";
import {onSetFilterStatus} from "redux/actions/quotes";
import {defaultQuoteStatusFilter} from "maps/filters";
import {Box, Typography, Grid} from "@material-ui/core";
import {quoteStatusColors} from "maps/colorCodes";

class Search extends Component {
    static propTypes = {
        chosenStatuses: PropTypes.object.isRequired,
        onSetFilterStatus: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Box p={2}>
                <Typography gutterBottom style={{fontWeight: "700"}}>Status:</Typography>
                <Box pt={1}>
                    <Grid container spacing={2}>
                        {_.keys(defaultQuoteStatusFilter).map((value) => {
                            return (
                                <Grid item xs={6} sm={3} key={value}>
                                    <CheckboxLarge
                                        key={value}
                                        color={quoteStatusColors[value]}
                                        isChecked={!!this.props.chosenStatuses[value]}
                                        onChange={(event) => this.props.onSetFilterStatus(value, event.target.checked)}
                                    >
                                        {value}
                                    </CheckboxLarge>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </Box>
        );
    }

    getCheckboxColor(value) {
        switch (value) {
            case "Submitted":
                return "#1E88E5";
            case "Opened":
                return "#8E24AA";
            case "Won":
                return "#43A047";
            case "Offer made":
                return "#FFB300";
            default:
                return "";
        }
    }
}

function mapStateToProps(state) {
    return {
        chosenStatuses: state.quoteSearch.chosenStatuses,
    };
}

export default connect(
    mapStateToProps,
    {onSetFilterStatus},
)(Search);
