import React, {Component} from "react";
import {Provider} from "react-redux";
import store from "redux/store";
import MainPages from "components/page/MainPages";
import "styles/bootstrap.min.css";
import "styles/sass/faceLift.scss";
import "styles/sass/faceLiftColors.scss";
import {theme} from "../../styles/materialUIstyles/cssBaseline";
import {ThemeProvider} from "@material-ui/core/styles";
import {CssBaseline} from "@material-ui/core";

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <MainPages />
                </ThemeProvider>
            </Provider>
        );
    }
}

export default App;
