import React, {Component} from "react";
import clsx from "clsx";
import styled from "styled-components";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import moment from "moment";
import {dateFormat} from "util/dateTime";
import Trend from "components/page/dashboard/predictions/Trend";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {onOpenResultDetails} from "redux/actions/dashboard/resultDetails";
import {onOpenEmailSending} from "redux/actions/dashboard/emailSending";
import GraphButton from "components/page/dashboard/predictions/GraphButton";
import Tooltip from "components/form/Tooltip";

const DetailsLink = styled.a`
    &&& {
        cursor: pointer;
        fontWeight: 500;
        text-decoration: none;
        color: inherit;
    }
`;

export class DashTableRow extends Component {
    static propTypes = {
        row: PropTypes.object.isRequired,
        isOrderDate: PropTypes.bool.isRequired,
        onOpenResultDetails: PropTypes.func.isRequired,
        onOpenEmailSending: PropTypes.func.isRequired,
    };

    render() {
        return (
            <tr className="confidence-row">
                {this.renderConfidenceRow()}
                {this.renderClientNameRow()}
                <td>{moment(this.renderPickupOrOrderDate()).format(dateFormat)}</td>
                <td>{this.props.row.estimated_cbm}</td>
                <td>{this.props.row.estimated_ldm}</td>
                <td>{this.props.row.estimated_payweight}</td>
                {/* {this.renderEmailSend()}*/}
                {/* <DashboardActionCell*/}
                {/*    id={this.props.row.predictionId}*/}
                {/*    accuracy={this.props.row.prediction_accuracy}*/}
                {/*    client_name={this.props.row.client_name}*/}
                {/*    client_number={this.props.row.client_number}*/}
                {/* />*/}
            </tr>
        );
    }

    renderPickupOrOrderDate() {
        if (this.props.isOrderDate) {
            return this.props.row.order_date;
        }

        return this.props.row.pickup_date;
    }

    renderConfidenceRow() {
        return (
            <td className="confidence-td">
                {this.renderConfidence()}
                <Trend direction={this.props.row.trend} />
                <GraphButton predictionId={this.props.row.predictionId} />
            </td>
        );
    }

    renderConfidence() {
        const confidence = this.getConfidence();
        return (
            <Tooltip title={`Confidence is ${this.props.row.confidence}`}>
                <span className={clsx("rating ", confidence)}>
                    {confidence}
                </span>
            </Tooltip>
        );
    }

    getConfidence() {
        return (this.props.row.confidence >= 70) ? "high" : ((this.props.row.confidence >= 40) ? "medium" : "low");
    }

    renderEmailSend() {
        const colorClass = this.props.row.email_sent ? "text-success" : "";
        return (
            <td className="td-actions">
                <a
                    rel="tooltip"
                    title="Send e-mail"
                    className={`btn btn-link action-btn hover-success ${colorClass}`}
                    onClick={() => {
                        this.props.onOpenEmailSending(this.props.row.predictionId, this.props.row.client_name);
                    }}
                >
                    <FontAwesomeIcon icon={faEnvelope} />
                </a>
            </td>
        );
    }

    renderClientNameRow() {
        const nameAndNumber = `${this.props.row.client_name} (${this.props.row.client_number})`;
        return (
            <td>
                <Tooltip title="Click to see result details">
                    <DetailsLink onClick={this.onOpenResultDetails.bind(this)}>
                        {nameAndNumber}
                    </DetailsLink>
                </Tooltip>
            </td>
        );
    }

    onOpenResultDetails() {
        this.props.onOpenResultDetails(this.props.row.predictionId);
    }
}

export default connect(
    undefined,
    {onOpenResultDetails, onOpenEmailSending},
)(DashTableRow);
