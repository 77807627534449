import React, {Component} from "react";
import PropTypes from "prop-types";

export default class FieldRequiredError extends Component {
    static propTypes = {
        isVisible: PropTypes.bool,
    };

    render() {
        if (!this.props.isVisible) {
            return false;
        }

        return <div className="text-danger">This field is required.</div>;
    }
}
