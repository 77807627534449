import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {listToSelectObject} from "util/toSelectObject";
import pages from "maps/pages";
import Route from "components/notifications/Route";
import {frequency} from "maps/classifiers";
import {Typography, Select, MenuItem} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
// import PredictionSearch from "components/page/dashboard/predictions/PredictionSearch";
// import Checkbox from "components/form/Checkbox";

const HumbleSelect = withStyles({
    root: {
        width: "4rem",
        padding: "1rem",
    },
})((props) => <Select {...props} />);

export class DashCardHeader extends Component {
    static propTypes = {
        perPage: PropTypes.number.isRequired,
        page: PropTypes.string.isRequired,
        // isSpotOnly: PropTypes.bool.isRequired,
        job: PropTypes.object,
        onSetPerPage: PropTypes.func.isRequired,
        predictionNum: PropTypes.number,
        // toggleSpotOnly: PropTypes.func.isRequired,
        // updateFilter: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div style={{display: "flex", alignItems: "center"}}>
                <Typography
                    gutterBottom
                    variant="h5"
                    style={{fontWeight: "600", marginRight: "auto"}}
                >
                    {this.renderTitle()}: {this.props.job.name}, {frequency[this.props.job.recurrance]}{" "}
                    <Route params={this.props.job.parameters[0]} />
                </Typography>
                {this.props.predictionNum >= 10 &&
                    <div>
                        <Typography gutterBottom variant="body2">
                            Items on page
                        </Typography>
                        <HumbleSelect
                            variant="outlined"
                            name="perPage"
                            value={this.props.perPage}
                            onChange={(option) => this.props.onSetPerPage(option.target.value)}
                        >
                            {this.renderPerPageOptions()}
                        </HumbleSelect>
                    </div>
                }

                {/* <div>*/}
                {/*    <PredictionSearch filterAccuracy={this.props.filterAccuracy} updateFilter={this.props.updateFilter} />*/}
                {/*    <Checkbox isChecked={this.props.isSpotOnly} onChange={(event) => this.props.toggleSpotOnly(event.target.checked)}>*/}
                {/*        Spot only*/}
                {/*    </Checkbox>*/}
                {/* </div>*/}
            </div>
        );
    }

    renderTitle() {
        return this.props.page === pages.DASHBOARD ? "My Default Prediction" : "Prediction";
    }

    renderPerPageOptions() {
        return listToSelectObject([10, 20, 50]).map((option, index) => {
            return (
                <MenuItem
                    label={option.label}
                    value={option.value}
                    key={index}
                    name={option.label}
                >{option.label}
                </MenuItem>
            );
        });
    }
}

function mapStateToProps(state) {
    return {
        page: state.page,
        job: _.get(state, ["jobExecution", "job"]),
    };
}

export default connect(mapStateToProps)(DashCardHeader);
