import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Select} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    menuPaper: {
        maxHeight: "30rem",
    },
}));

export default function MuiSelect(props) {
    const classes = useStyles();

    return (
        <Select
            variant="outlined"
            name={props.name}
            onChange={props.onChange}
            value={props.value}
            MenuProps={{classes: {paper: classes.menuPaper}}}
        >
            {props.children}
        </Select>
    );
}
