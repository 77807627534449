import * as actionType from "redux/actions/types";

export const onOpenResultDetails = (predictionId) => {
    return {
        type: actionType.OPEN_RESULT_DETAILS,
        predictionId,
    };
};
export const onLoadResultDetails = (resultData) => {
    return {
        type: actionType.LOAD_RESULT_DETAILS,
        resultData,
    };
};
export const onLoadResultDetailsFailed = (error) => {
    return {
        type: actionType.LOAD_RESULT_DETAILS_FAILED,
        error,
    };
};
export const onCloseResultDetails = () => {
    return {
        type: actionType.CLOSE_RESULT_DETAILS,
    };
};
