import React, {Component} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import PastShipmentsTab from "components/page/dashboard/predictions/resultDetailsModal/PastShipmentsTab";
import PredictionsTab from "components/page/dashboard/predictions/resultDetailsModal/PredictionsTab";
import {Tabs, Tab} from "@material-ui/core";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {withStyles} from "@material-ui/styles";

const TABS = {
    PREDICTIONS: "Predictions",
    PAST_SHIPMENTS: "Past shipments",
    PAST_SPOTS: "Failed spots",
};

const ScrollTabContent = styled.div`
    max-height: 60vh;
    overflow-y: auto;
`;

const theme = createTheme({
    palette: {
        primary: {
            main: "#611ad0",
        },
    },
});

const StyledTab = withStyles({
    root: {
        fontWeight: "600",
    },
})((props) => <Tab {...props} />);

export default class ResultDetailsModalContent extends Component {
    static propTypes = {
        resultData: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            active: TABS.PREDICTIONS,
        };
    }

    renderTabs() {
        return (
            <MuiThemeProvider theme={theme}>
                <Tabs
                    value={this.state.active}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(event, newvalue) => this.setState({active: newvalue})}
                    style={{fontWeight: "600"}}
                >
                    <StyledTab label={TABS.PREDICTIONS} key={TABS.PREDICTIONS} value={TABS.PREDICTIONS} />
                    <StyledTab label={TABS.PAST_SHIPMENTS} key={TABS.PAST_SHIPMENTS} value={TABS.PAST_SHIPMENTS} />
                    {this.props.resultData.past_spots.length > 0 &&
                        <StyledTab label={TABS.PAST_SPOTS} key={TABS.PAST_SPOTS} value={TABS.PAST_SPOTS} />
                    }
                </Tabs>
            </MuiThemeProvider>
        );
    }

    render() {
        return (
            <div>
                {this.renderTabs()}
                <div className="tab-content">
                    <ScrollTabContent className="tab-pane fade show active">{this.renderContent()}</ScrollTabContent>
                </div>
            </div>
        );
    }

    renderContent() {
        return {
            [TABS.PREDICTIONS]: <PredictionsTab resultData={this.props.resultData} />,
            [TABS.PAST_SHIPMENTS]: <PastShipmentsTab shipments={this.props.resultData.past_shipments} />,
            [TABS.PAST_SPOTS]: <PastShipmentsTab shipments={this.props.resultData.past_spots} />,
        }[this.state.active];
    }
}
