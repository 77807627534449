import React from "react";
import {Button} from "@material-ui/core";
import {ArrowForward} from "@material-ui/icons";
import Loading from "components/notifications/Loading";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    button: {
        display: "flex",
        marginLeft: "auto",
        background: "#43d5a8",
        color: "#ffffff",
        "&:hover": {
            background: "#29b88c",
        },
    },
    buttonCenter: {
        margin: "auto",
    },
    icon: {
        margin: "0 0 2px 2px",
    },
}));

export default function MuiSubmitButton(props) {
    const {buttonText, disabled, onSubmit, loading, arrowButton} = props;
    const classes = useStyles();
    return (
        <Button
            variant="contained"
            className={clsx(classes.button, !arrowButton && classes.buttonCenter)}
            type="submit"
            size="large"
            disabled={disabled}
            onSubmit={onSubmit}
        >
            {buttonText}
            {arrowButton && <ArrowForward className={classes.icon} />}
            {loading && <Loading />}
        </Button>
    );
}
