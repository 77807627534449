import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Loading from "components/notifications/Loading";
import PredictionTableWrapper from "components/page/dashboard/predictions/PredictionTableWrapper";
import ComparativeResults from "components/page/dashboard/comparative/ComparativeResults";

class Predictions extends Component {
    static propTypes = {
        jobExecution: PropTypes.object,
    };

    render() {
        return (
            <>
                {this.renderContent()}
            </>
        );
    }

    renderContent() {
        if (!this.props.jobExecution) {
            return (
                <div className="text-center">
                    <Loading />
                </div>
            );
        }

        if (this.props.jobExecution.error) {
            return <div className="text-center text-danger">Error! {this.props.jobExecution.error}</div>;
        }

        if (this.props.jobExecution.job.recurrance === "COMPARATIVE") {
            return <ComparativeResults jobExecution={this.props.jobExecution} />;
        }

        return (
            <div>
                <PredictionTableWrapper jobExecution={this.props.jobExecution} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        jobExecution: state.jobExecution,
    };
}

export default connect(mapStateToProps)(Predictions);
