import React, {Component} from "react";
import {connect} from "react-redux";
import Login from "components/page/Login";
import LoggedInWrapper from "components/page/LoggedInWrapper";
import Dashboard from "components/page/dashboard/Dashboard";
import PredictionsPage from "components/page/dashboard/PredictionsPage";
import JobPage from "components/page/jobs/JobPage";
import QuotePage from "components/page/quotes/QuotePage";
import StatisticsPage from "components/page/statistics/StatisticsPage";
import ExclusionPage from "components/page/exclude/ExclusionPage";
import pages from "maps/pages";

export class MainPages extends Component {
    render() {
        if (this.props.page && this.props.page !== pages.LOGIN) {
            return <LoggedInWrapper page={this.props.page}>{this.renderLoggedInPage()}</LoggedInWrapper>;
        }
        else {
            return <Login />;
        }
    }

    renderLoggedInPage() {
        switch (this.props.page) {
            case pages.DASHBOARD:
                return <Dashboard />;
            case pages.PREDICTION:
                return <PredictionsPage />;
            case pages.JOBS:
                return <JobPage />;
            case pages.QUOTES:
                return <QuotePage />;
            case pages.STATISTICS:
                return <StatisticsPage />;
            case pages.EXCLUSIONS:
                return <ExclusionPage />;
        }
    }
}

function mapStateToProps(state) {
    return {
        page: state.page,
    };
}

export default connect(mapStateToProps)(MainPages);
