import React, {Component} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";

const Notification = styled.div`
    font-size: 14px;
    font-style: italic;
    margin-bottom: 28px;
`;

const List = styled.ul`
    margin-bottom: 2em;
`;

export default class ContactList extends Component {
    static propTypes = {
        contacts: PropTypes.array.isRequired,
        removeContact: PropTypes.func.isRequired,
    };

    render() {
        if (!this.props.contacts.length) {
            return <Notification>No contacts found.</Notification>;
        }

        return (
            <List>
                {this.props.contacts.map((contact) => (
                    <li key={contact.email_address}>
                        {contact.contact_name} &lt;
                        {contact.email_address}
                        &gt;
                        <button type="button" className="btn btn-link btn-xs smaller-x" onClick={() => this.props.removeContact(contact)}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </button>
                    </li>
                ))}
            </List>
        );
    }
}
