import styled from "styled-components";
import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {onOpenGraph} from "redux/actions/dashboard/graphs";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Tooltip from "components/form/Tooltip";

const GraphIcon = styled.a`
    &&& {
        color: #9E9E9E;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
`;

class GraphButton extends React.Component {
    static propTypes = {
        predictionId: PropTypes.number.isRequired,
        onOpenGraph: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Tooltip title="Open order graph">
                <GraphIcon
                    onClick={() => this.props.onOpenGraph(this.props.predictionId)}
                >
                    <AssessmentIcon fontSize="large" />
                </GraphIcon>
            </Tooltip>
        );
    }
}

export default connect(undefined, {onOpenGraph})(GraphButton);
