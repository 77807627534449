import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class Sale extends Component {
    static propTypes = {
        sale: PropTypes.object.isRequired,
    };

    render() {
        return (
            <tr className="sale-item">
                <td>{this.props.sale.salesman_name}</td>
                <td>{this.props.sale.quotes_asked}</td>
                <td>{this.props.sale.offers_made}</td>
                <td>{this.props.sale.offers_opened}</td>
                <td>{this.props.sale.won}</td>
                <td>{this.props.sale.won_rate}</td>
            </tr>
        );
    }
}

export default connect(
    undefined,
)(Sale);
