import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import {Dialog, DialogContent, Box, Typography} from "@material-ui/core";

export default function MuiModal(props) {
    return (
        <Dialog
            onClose={props.onClose}
            aria-labelledby={props.ariaLabel}
            open={true}
            maxWidth="lg"
            fullWidth={props.fullWidth}
        >
            <DialogContent>
                <Box p={2}>
                    <CloseIcon style={{float: "right", cursor: "pointer"}} onClick={props.onClose} />
                    <Box p={2}>
                        <Typography
                            gutterBottom
                            variant="h4"
                            align="center"
                            style={{fontWeight: 500}}
                        >
                            {props.heading}
                        </Typography>
                        {props.smallHeading &&
                            <Typography
                                gutterBottom
                                variant="h6"
                                align="center"
                                style={{fontWeight: 500}}
                            >
                                {props.smallHeading}
                            </Typography>
                        }
                        <Box pt={2}>
                            {props.children}
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
