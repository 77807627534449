import * as actionType from "redux/actions/types";

export const onOpenEmailSending = (predictionId, customerName) => {
    return {
        type: actionType.OPEN_EMAIL_SENDING,
        predictionId,
        customerName,
    };
};

export const onLoadEmailContacts = (contacts) => {
    return {
        type: actionType.LOAD_EMAIL_CONTACTS,
        contacts,
    };
};

export const onLoadEmailContactsFailed = (error) => {
    return {
        type: actionType.LOAD_EMAIL_CONTACTS_FAILED,
        error,
    };
};

export const onRemoveEmailContact = (email) => {
    return {
        type: actionType.REMOVE_EMAIL_SENDING_CONTACT,
        email,
    };
};

export const onChangeNewContact = (field, value) => {
    return {
        type: actionType.CHANGE_NEW_CONTACT,
        field,
        value,
    };
};

export const onAddNewContact = () => {
    return {
        type: actionType.ADD_NEW_CONTACT,
    };
};

export const onEmailSendRequested = (predictionId) => {
    return {
        type: actionType.EMAIL_SEND_REQUESTED,
        predictionId,
    };
};

export const onEmailSendRequestFailed = (error) => {
    return {
        type: actionType.EMAIL_SEND_REQUEST_FAILED,
        error,
    };
};

export const onCloseEmailSending = () => {
    return {
        type: actionType.CLOSE_EMAIL_SENDING,
    };
};
