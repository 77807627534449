import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ajax from "remotes/ajax";
import {onArchiveJob} from "redux/actions/jobs/jobs";
import moment from "moment";
import {dateFormat} from "util/dateTime";
import {Link} from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import SimpleDialog from "components/form/SimpleDialog";

export class JobArchiving extends Component {
    static propTypes = {
        job: PropTypes.object.isRequired,
        onArchiveJob: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
        };
    }

    render() {
        if (this.props.job.end_date) {
            return (
                <div style={{float: "right", fontWeight: "700"}}>
                    Archived on {moment(this.props.job.end_date).format(dateFormat)}
                </div>
            );
        }

        if (this.props.job.is_default) {
            return false;
        }

        return (
            <div className="float-right">
                <Link type="button" className="linkStyleButton" onClick={this.openDialog.bind(this)}>
                    <ArchiveIcon className="icon" />
                    Archive / Delete
                </Link>
                <SimpleDialog
                    open={this.state.dialogOpen}
                    actionType="archive"
                    title="Are you sure you want to archive this prediction?"
                    onArchive={this.onArchiveClick.bind(this)}
                    onClose={this.closeDialog.bind(this)}
                    buttonText="Archive"
                />
            </div>
        );
    }

    async onArchiveClick() {
        await ajax.doArchiveJob(this.props.job.id);
        this.props.onArchiveJob(this.props.job.id);
    }

    openDialog() {
        this.setState({
            dialogOpen: true,
        });
    }

    closeDialog() {
        this.setState({
            dialogOpen: false,
        });
    }
}

export default connect(
    undefined,
    {onArchiveJob},
)(JobArchiving);
