import * as actionType from "redux/actions/types";

export const onExclusionsReceived = (exclusions) => {
    return {
        type: actionType.EXCLUSIONS_RECEIVED,
        exclusions,
    };
};

export const onExclusionFetchFail = (error) => {
    return {
        type: actionType.EXCLUSION_FETCH_FAIL,
        error,
    };
};

export const onCreateExclusion = () => {
    return {
        type: actionType.CREATE_EXCLUSION,
    };
};

export const onPrefillExclusionForm = (exclusion) => {
    return {
        type: actionType.PREFILL_EXCLUSION_FORM,
        exclusion,
    };
};

export const onEditExclusion = (exclusion) => {
    return {
        type: actionType.EDIT_EXCLUSION,
        exclusion,
    };
};

export const onSaveNewExclusion = (exclusion) => {
    return {
        type: actionType.SAVE_NEW_EXCLUSION,
        exclusion,
    };
};

export const onSaveExclusionUpdate = (exclusion) => {
    return {
        type: actionType.SAVE_EXCLUSION_UPDATE,
        exclusion,
    };
};

export const onCloseExclusionForm = () => {
    return {
        type: actionType.CLOSE_EXCLUSION_FORM,
    };
};

export const onArchiveExclusion = (exclusion) => {
    return {
        type: actionType.ARCHIVE_EXCLUSION,
        exclusion,
    };
};

export const onRemoveExclusion = (exclusionId) => {
    return {
        type: actionType.REMOVE_EXCLUSION,
        exclusionId,
    };
};

export const onSetClientList = (clients) => {
    return {
        type: actionType.SET_CLIENT_LIST,
        clients,
    };
};

export const onSetClientListFetchError = (error) => {
    return {
        type: actionType.SET_CLIENT_LIST_FETCH_ERROR,
        error,
    };
};

export const updateExclusionFormField = (key, value) => {
    return {
        type: actionType.UPDATE_EXCLUSION_FORM_FIELD,
        key,
        value,
    };
};

export const updateExclusionClientField = ({client_name, client_number}) => {
    return {
        type: actionType.UPDATE_EXCLUSION_CLIENT_FIELD,
        client_name,
        client_number,
    };
};
