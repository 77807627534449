import moment from "moment";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import styled from "styled-components";
import {dateFormat} from "util/dateTime";
import SortArrow from "components/table/SortArrow";
import {onSortShipments} from "redux/actions/dashboard/sorting";
import resultSorter from "util/resultSorter";

const Table = styled.table`
    white-space: nowrap;
`;

export class PastShipmentsTab extends Component {
    static propTypes = {
        shipments: PropTypes.array.isRequired,
        shipmentsTableSorting: PropTypes.object.isRequired,
        onSort: PropTypes.func.isRequired,
    };

    render() {
        const {column, direction} = this.props.shipmentsTableSorting;
        const sortedResults = resultSorter(this.props.shipments, {direction, column});
        return (
            <Table className="table">
                <thead>
                    <tr>{this.getColumns().map(({field, label}) => this.renderTh(field, label))}</tr>
                </thead>
                <tbody>{sortedResults.map((row) => this.renderRow(row))}</tbody>
            </Table>
        );
    }

    renderRow(row) {
        return (
            <tr key={row.id}>
                {this.getColumns().map(({field, isDate}) => {
                    if (isDate) {
                        return <td key={field}>{moment(row[field]).format(dateFormat)}</td>;
                    }

                    return <td key={field}>{row[field]}</td>;
                })}
            </tr>
        );
    }

    renderTh(field, label) {
        return (
            <th key={field} onClick={() => this.sort(field)}>
                {label} {this.renderSort(field)}
            </th>
        );
    }

    sort(column) {
        this.props.onSort(column);
    }

    renderSort(field) {
        const {column, direction} = this.props.shipmentsTableSorting;
        return <SortArrow current={field} activeColumn={column} activeDirection={direction} />;
    }

    isOrderDate() {
        const list = this.props.shipments;
        return !list.length || !!list[0].order_date;
    }

    getColumns() {
        const orderOrPickupDate = this.isOrderDate() ?
            {field: "order_date", label: "Order date", isDate: true} :
            {field: "pickup_date", label: "Pickup date", isDate: true};

        return [
            {field: "shipment", label: "Shipment"},
            orderOrPickupDate,
            // {field: "order_date", label: "Order date", isDate: true},
            // {field: "colli", label: "Colli"},
            // {field: "type", label: "Type"},
            {field: "gross_weight", label: "Gross weight"},
            {field: "cbm", label: "CBM (m³)"},
            {field: "ldm", label: "LDM (m)"},
            {field: "payweight", label: "Payweight (kg)"},
            // {field: "hazard", label: "Hazard"},
            // {field: "temp_from", label: "Temp from"},
            // {field: "temp_to", label: "Temp to"},
            // {field: "from_country_code", label: "From country code"},
            // {field: "from_region_code", label: "From region code"},
            {field: "from_city", label: "From city"},
            {field: "from_region", label: "From"},
            {field: "to_city", label: "To city"},
            {field: "to_region", label: "To"},
            // {field: "to_country_code", label: "To country code"},
            // {field: "to_region_code", label: "To region code"},
            // {field: "pickup_date", label: "Pickup date", isDate: true},
            // {field: "delivery_date", label: "Delivery date", isDate: true},
            // {field: "cust_no", label: "cust_no"},
            // {field: "cust_name", label: "cust_name"},
            // {field: "price", label: "Price (€)"},
            // {field: "inv_department", label: "Inv department"},
        ];
    }
}

function mapStateToProps(state) {
    return {
        shipmentsTableSorting: state.sorting.shipmentsTable,
    };
}

export default connect(
    mapStateToProps,
    {onSort: onSortShipments},
)(PastShipmentsTab);
