import * as actionType from "redux/actions/types";

export const graphs = (state = {}, action) => {
    switch (action.type) {
        case actionType.OPEN_GRAPH:
            return {
                predictionId: action.predictionId,
            };

        case actionType.LOAD_GRAPH:
            return {
                ...state,
                plots: action.plots,
            };

        case actionType.LOAD_GRAPH_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case actionType.CLOSE_GRAPH:
            return {};

        default:
            return state;
    }
};
