import React from "react";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        fontSize: "1rem",
    },

}));
export default function QuoteModalGridElement(props) {
    const classes = useStyles();

    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={6} sm={6}>
                <label>{props.label}</label>
            </Grid>
            <Grid item xs={6} sm={6}>
                <strong>{props.value}</strong>
            </Grid>
        </Grid>
    );
}
