import React, {Component} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Paper, Typography} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";

const theme = createTheme({
    typography: {
        body2: {
            fontWeight: "500",
        },
    },
});

const PredictionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
`;
const IconContainer = styled.div`
    margin-left: 2rem;
`;

export default class JobExecQuantities extends Component {
    static propTypes = {
        icon: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        unit: PropTypes.string.isRequired,
        value: PropTypes.number,
    };

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Paper elevation={3}>
                    <PredictionsContainer>
                        <IconContainer>
                            <FontAwesomeIcon
                                icon={this.props.icon}
                                style={{fontSize: "2rem"}}
                            />
                        </IconContainer>
                        <div style={{margin: "auto"}}>
                            <Typography
                                gutterBottom
                                variant="body2"
                            >
                                {this.props.title}
                            </Typography>
                            <Typography
                                gutterBottom
                                variant="h5"
                            >
                                {this.props.value === undefined ? "-" : this.props.value} {this.props.unit}
                            </Typography>
                        </div>
                    </PredictionsContainer>
                </Paper>
            </MuiThemeProvider>
        );
    }
}
