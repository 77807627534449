import React, {Component} from "react";
import PropTypes from "prop-types";
import Route from "components/notifications/Route";
import {frequency, predictionStates} from "maps/classifiers";
import moment from "moment";
import {dateFormat, dateFormatWoYear} from "util/dateTime";
import {isArchived} from "util/decisions";
import {Typography, Grid} from "@material-ui/core";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";

const theme = createTheme({
    typography: {
        h6: {
            fontWeight: "500",
            fontSize: "1.2rem",
        },
        body1: {
            fontSize: "1rem",
        },
    },
});

export default class JobDetails extends Component {
    static propTypes = {
        isPredictable: PropTypes.bool,
        job: PropTypes.object.isRequired,
    };

    render() {
        const isJobArchived = isArchived(this.props.job);
        return (
            <MuiThemeProvider theme={theme}>
                <Grid container spacing={2}>
                    <Grid item sm={2}>
                        <Typography variant="body2">Type</Typography>
                        <Typography variant="body1">{frequency[this.props.job.recurrance]}</Typography>
                    </Grid>
                    <Grid item sm={2}>
                        <Typography variant="body2">Status</Typography>
                        <div>
                            <Typography variant="body1" style={{fontWeight: 600}}>
                                {isJobArchived ? predictionStates.OUTDATED : predictionStates[this.props.job.display_status]}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sm={2}>
                        <Typography variant="body2">Route</Typography>
                        <Typography variant="body1"><Route params={this.props.job.parameters[0]} /></Typography>
                    </Grid>
                    <Grid item sm={2}>
                        <Typography variant="body2">Period</Typography>
                        <Typography variant="body1">{this.props.job.prediction_period}</Typography>
                    </Grid>
                    <Grid item sm={2}>
                        <Typography variant="body2">Predictability</Typography>
                        <Typography variant="body1">{this.renderPredictability()}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Typography variant="body2">Details</Typography>
                        <Typography variant="body1">{this.renderDetails()}</Typography>
                    </Grid>
                </Grid>

            </MuiThemeProvider>
        );
    }

    renderPredictability() {
        switch (this.props.isPredictable) {
            case true:
                return "Predictable";
            case false:
                return "Insufficient data";
            default:
                return undefined;
        }
    }

    renderLastRun() {
        if (!this.props.job.executions || !this.props.job.executions.length) {
            return "N/A";
        }
        return moment(this.props.job.executions[0].created_date).format(dateFormatWoYear);
    }

    renderNextRun() {
        if (!this.props.job.executions || !this.props.job.executions.length) {
            return "N/A";
        }
        return moment(this.props.job.executions[0].created_date)
            .add(1, "weeks")
            .format(dateFormat);
    }

    renderExecCounts() {
        if (!this.props.job.executions || !this.props.job.executions.length) {
            return "N/A";
        }
        return this.props.job.executions[0].prediction_count;
    }

    renderIgnoreExclusion(job) {
        return job.parameters.length && job.parameters[0].ignore_exclusion_lists ? "True" : "False";
    }

    renderDetails() {
        const job = this.props.job;
        return (
            <span>
                Dataframe: {job.training_period}
                m, Ignore Exclusion Lists: {this.renderIgnoreExclusion(job)}, Results: {job.results}
                <br />
                Last run: {this.renderLastRun()}, Next run: {this.renderNextRun()}, Number of predictions: {this.renderExecCounts()}
            </span>
        );
    }
}
