import _ from "lodash";
import styled from "styled-components";
import moment from "moment";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Loading from "components/notifications/Loading";
import ajax from "remotes/ajax";
import {
    onCloseExclusionForm,
    onSaveExclusionUpdate,
    onSaveNewExclusion,
    updateExclusionClientField,
    updateExclusionFormField,
} from "redux/actions/exclusions";
import {dateTimeFormat} from "util/dateTime";
import ExclusionFormFields from "components/page/exclude/ExclusionFormFields";
import {isDuplicate} from "components/page/dashboard/predictions/isExclusionMatch";
import {fetchExclusionsOnce} from "remotes/fetching";
import {Box, Button} from "@material-ui/core";
import Info from "components/notifications/Info";
import {ArrowForward} from "@material-ui/icons";
import MuiModal from "../../modal/MuiModal";

const Footer = styled.div`
    margin-top: 30px;
`;

export class ExclusionForm extends Component {
    static propTypes = {
        exclusion: PropTypes.object.isRequired,
        userIsAdmin: PropTypes.bool.isRequired,
        onSaveNewExclusion: PropTypes.func.isRequired,
        onSaveExclusionUpdate: PropTypes.func.isRequired,
        updateExclusionFormField: PropTypes.func.isRequired,
        updateExclusionClientField: PropTypes.func.isRequired,
        onCloseExclusionForm: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            required: ["name", "cust_name", "from_country_code", "to_country_code"],
            submitDone: false,
        };

        // If no exclusions loaded, get them for upcoming duplicate checking.
        fetchExclusionsOnce();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.validateForm();
        }
    }

    render() {
        return (
            <MuiModal
                ariaLabel="exclusionform"
                onClose={this.props.onCloseExclusionForm}
                heading={this.props.exclusion.id ? "Edit" : "Add new"} exclusion
            >
                <form method="#" action="#" onSubmit={this.addExclusion.bind(this)}>
                    <Box p={2} className="modalContainer">
                        <ExclusionFormFields
                            exclusion={this.props.exclusion}
                            userIsAdmin={this.props.userIsAdmin}
                            errors={this.state.errors}
                            submitDone={this.state.submitDone}
                            updateExclusionClientField={this.props.updateExclusionClientField}
                            updateExclusionFormField={this.props.updateExclusionFormField}
                        />
                        <Footer className="text-right">
                            {Object.keys(this.state.errors).length ? 
                                <Info 
                                    severity="error" 
                                    message="Unknown error, try again." 
                                /> : ""
                            }
                            {this.isDuplicate() ? 
                                <Info 
                                    severity="error" 
                                    message="Exclusion with this customer and route already exists!" 
                                /> : ""
                            }
                            <Button type="submit" disabled={this.isDisabled()} className="submitButton">
                                {this.state.loading ? (
                                    <Loading />
                                ) : this.props.exclusion.id ? (
                                    "Save Exclusion"
                                ) : (
                                    "Create Exclusion"
                                )}
                                <ArrowForward style={{margin: "0 0 2px 2px"}} />
                            </Button>
                        </Footer>
                    </Box>
                </form>
            </MuiModal>
        );
    }

    isDisabled() {
        if (this.state.loading || this.isDuplicate()) {
            return true;
        }
        return this.state.submitDone && !this.props.exclusion.name;
    }

    async addExclusion(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            submitDone: true,
        });

        if (this.validateForm() && !this.isDuplicate()) {
            this.setState({
                loading: true,
                error: false,
            });

            const formatIfDefined = (date) => date && date.length && moment(date).format(dateTimeFormat);

            try {
                const correctedFormData = {
                    ...this.props.exclusion,
                    end_date: formatIfDefined(this.props.exclusion.end_date),
                };

                if (this.props.exclusion.id) {
                    const responseData = await ajax.doUpdateExclusion(correctedFormData);
                    this.props.onSaveExclusionUpdate({...correctedFormData, ...responseData});
                }
                else {
                    const responseData = await ajax.doAddExclusion(correctedFormData);
                    this.props.onSaveNewExclusion({...correctedFormData, ...responseData});
                }
            }
            catch (error) {
                this.setState({
                    error: true,
                    loading: false,
                });

                console.error(error); // eslint-disable-line no-console
            }
        }
    }

    isDateValid(date) {
        return !date || (date.isValid && date.isValid());
    }

    validateForm() {
        const isUndefined = (value) => value === undefined;
        const isEmptyString = (value) => _.isString(value) && value.trim().length === 0;

        const isInvalid = _.overSome([isUndefined, isEmptyString]);

        const missingFieldList = this.state.required.filter((field) => isInvalid(this.props.exclusion[field]));
        const errorPairs = missingFieldList.map((field) => [field, true]);

        if (!this.isDateValid(this.props.exclusion.end_date)) {
            errorPairs.push(["end_date", true]);
        }

        const errors = _.fromPairs(errorPairs);

        this.setState({
            // NB! setState is async!
            errors,
        });

        return !Object.keys(errors).length;
    }

    isDuplicate() {
        return isDuplicate(this.props.exclusion);
    }
}

function mapStateToProps(state) {
    return {
        exclusion: state.editingExclusion.exclusion,
        userIsAdmin: state.user.is_superuser,
    };
}

export default connect(
    mapStateToProps,
    {onSaveNewExclusion, onSaveExclusionUpdate, updateExclusionFormField, updateExclusionClientField, onCloseExclusionForm},
)(ExclusionForm);
