import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Job from "components/page/jobs/Job";
import {predictionStates} from "maps/classifiers";
import {isArchived} from "util/decisions";
import {Box} from "@material-ui/core";

class JobsList extends Component {
    static propTypes = {
        jobs: PropTypes.array.isRequired,
        chosenStates: PropTypes.object,
    };

    render() {
        const Card = ({children}) => (
            <div>
                <div>{children}</div>
            </div>
        );

        if (!this.props.jobs.length) {
            return <Card>Did not find any jobs.</Card>;
        }

        const filteredJobs = this.filterJobs();
        if (!filteredJobs.length) {
            return <Card>None of the {this.props.jobs.length} jobs matched active filters.</Card>;
        }

        return (
            <div>
                {filteredJobs.map((job) => (
                    <div key={job.id}>
                        <Box p={3}>
                            <Job job={job} />
                        </Box>
                        <hr />
                    </div>
                ))}
            </div>
        );
    }

    filterJobs() {
        return this.filterByStatus(this.props.jobs);
    }

    filterByStatus(data) {
        const statusValues = this.props.chosenStates;
        const activeStates = Object.keys(statusValues).filter((state) => statusValues[state]);
        if (activeStates.length) {
            return data.filter((row) => {
                if (!statusValues[predictionStates.OUTDATED] && isArchived(row)) {
                    return false;
                }

                if (predictionStates[row.display_status] === predictionStates.IN_PROGRESS) {
                    return _.includes(activeStates, predictionStates.QUEUED);
                }

                return _.includes(activeStates, predictionStates[row.display_status]);
            });
        }
        return data;
    }
}

function mapStateToProps(state) {
    return {
        jobs: state.jobs,
        chosenStates: state.jobSearch.chosenStates,
    };
}

export default connect(mapStateToProps)(JobsList);
