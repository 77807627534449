import React, {Component} from "react";
import Benefits from "components/page/dashboard/Benefits";
import Prediction from "components/page/dashboard/Predictions";
import fetchPredictions from "remotes/fetchPredictions";
import {Container} from "@material-ui/core";

export default class Dashboard extends Component {
    componentDidMount() {
        fetchPredictions();
    }

    render() {
        return (
            <div className="content">
                <Container disableGutters maxWidth={false}>
                    <Benefits />
                    <Prediction />
                </Container>
            </div>
        );
    }
}
