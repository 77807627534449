import * as actionType from "../../actions/types";

export const jobExecution = (state = null, action) => {
    switch (action.type) {
        case actionType.SET_JOB_EXECUTION:
            return action.jobExecution;

        case actionType.JOB_EXECUTION_FETCH_FAIL:
            return {error: action.error};

        case actionType.RESET_DASHBOARD:
            return null;

        case actionType.UPDATE_PREDICTION:
            return {
                ...state,
                predictions: state.predictions.map((prediction) =>
                    prediction.id === action.prediction.id ? action.prediction : prediction,
                ),
            };

        case actionType.EMAIL_SEND_REQUESTED: {
            const addEmail = (prediction) => ({
                ...prediction,
                email_sent: true,
            });

            return {
                ...state,
                predictions: state.predictions.map((prediction) =>
                    prediction.id === action.predictionId ? addEmail(prediction) : prediction,
                ),
            };
        }

        default:
            return state;
    }
};
