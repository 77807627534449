import React from "react";
import {Pagination as MuiPagination} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        "& button": {
            fontSize: "1rem",
        },
    },
}));

export default function Pagination(props) {
    const classes = useStyles();

    if (props.n < 2) {
        return false;
    }

    return (

        <MuiPagination
            count={props.n}
            className={classes.root}
            shape="rounded"
            page={props.current}
            onChange={props.onSetPage}
        />
    );
}
