export function formatRouteFrom(parameters) {
    const {from_country_code, from_region_code} = parameters;
    const from_region_code_space = from_region_code ? " " + from_region_code : "";
    return `${from_country_code}${from_region_code_space}`;
}

export function formatRouteTo(parameters) {
    const {to_country_code, to_region_code} = parameters;
    const to_region_code_space = to_region_code ? " " + to_region_code : "";
    return `${to_country_code}${to_region_code_space}`;
}

export default function formatRoute(parameters) {
    const from_route = formatRouteFrom(parameters);
    const to_route = formatRouteTo(parameters);
    return `${from_route} -> ${to_route}`;
}
