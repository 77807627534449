import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import DashTableRow from "components/page/dashboard/predictions/DashTableRow";
import {onSortPredictionResults} from "redux/actions/dashboard/sorting";
import PredictionTableInfo from "components/page/dashboard/predictions/PredictionTableInfo";
import SortArrow from "components/table/SortArrow";
import {Box, Typography} from "@material-ui/core";

export class PredictionTable extends Component {
    static propTypes = {
        tableData: PropTypes.array.isRequired,
        start: PropTypes.number.isRequired,
        end: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        totalResults: PropTypes.number.isRequired,
        predictionTableSorting: PropTypes.object.isRequired,
        jobStatus: PropTypes.string.isRequired,
        onSortPredictionResults: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div>
                <PredictionTableInfo jobStatus={this.props.jobStatus} />

                {this.renderMissing() || this.renderTable()}
            </div>
        );
    }

    renderMissing() {
        if (!this.props.totalResults) {
            return <div className="text-center">Did not find any predictions.</div>;
        }

        if (!this.props.tableData.length) {
            return <div className="text-center">None of the {this.props.totalResults} predictions matched active filters.</div>;
        }
    }

    renderTable() {
        return (
            <Fragment>
                <Box pt={2}>
                    <table className="table">
                        <thead>
                            <tr>
                                {this.renderTh("confidence", "Confidence")}
                                {this.renderTh("client_name", "Client")}
                                {this.renderPickupOrOrderDate()}
                                {this.renderTh("estimated_cbm", "CBM (m³)")}
                                {this.renderTh("estimated_ldm", "LDM (m)")}
                                {this.renderTh("estimated_payweight", "Payweight (kg)")}
                                {/* <th>Send</th>*/}
                                {/* <th onClick={() => this.sort("prediction_accuracy")} className="text-right">*/}
                                {/*    Accuracy {this.renderSort("prediction_accuracy")}*/}
                                {/* </th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.tableData.map((row) => (
                                <DashTableRow key={row.id} row={row} isOrderDate={this.isOrderDate()} />
                            ))}
                        </tbody>
                    </table>
                </Box>

                <Typography variant="body1" style={{paddingLeft: ".75rem"}}>
                    {this.props.start} - {this.props.end} of {this.props.total}
                </Typography>
            </Fragment>
        );
    }

    isOrderDate() {
        const list = this.props.tableData;
        return !list.length || !!list[0].order_date;
    }

    renderPickupOrOrderDate() {
        if (this.isOrderDate()) {
            return this.renderTh("order_date", "Order date");
        }

        return this.renderTh("pickup_date", "Pickup date");
    }

    renderTh(field, label) {
        return (
            <th onClick={() => this.sort(field)}>
                {label} {this.renderSort(field)}
            </th>
        );
    }

    sort(column) {
        this.props.onSortPredictionResults(column);
    }

    renderSort(field) {
        const {column, direction} = this.props.predictionTableSorting;
        return <SortArrow current={field} activeColumn={column} activeDirection={direction} />;
    }
}

function mapStateToProps(state) {
    return {
        predictionTableSorting: state.sorting.predictionTable,
    };
}

export default connect(
    mapStateToProps,
    {onSortPredictionResults},
)(PredictionTable);
