import * as actionType from "redux/actions/types";

export const quotes = (state = null, action) => {
    switch (action.type) {
        case actionType.QUOTES_RECEIVED:
            return action.quotes;

        case actionType.QUOTE_FETCH_FAIL:
            return {error: action.error};

        case actionType.SAVE_QUOTE_UPDATE:
        case actionType.ARCHIVE_QUOTE:
            return state.map((quote) => (quote.id === action.quote.id ? action.quote : quote));

        default:
            return state;
    }
};

export const editingQuote = (state = {quote: {}}, action) => {
    switch (action.type) {
        case actionType.PREFILL_QUOTE_FORM:
        case actionType.EDIT_QUOTE:
            return {
                isOpen: true,
                quote: action.quote,
            };
        case actionType.UPDATE_QUOTE_FORM_FIELD:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    [action.key]: action.value,
                },
            };
        case actionType.SAVE_QUOTE_UPDATE:
        case actionType.CLOSE_QUOTE_FORM:
            return {
                isOpen: false,
                quote: {},
            };

        default:
            return state;
    }
};

export const viewQuoteHistory = (state = {quote: {}}, action) => {
    switch (action.type) {
        case actionType.VIEW_QUOTE:
            return {
                isOpen: true,
                quote: action.quote,
            };
        case actionType.CLOSE_QUOTE_VIEW:
            return {
                isOpen: false,
                quote: {},
            };

        default:
            return state;
    }
};
