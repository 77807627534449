import styled from "styled-components";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    onCloseQuoteForm,
    onSaveQuoteUpdate,
    updateQuoteFormField,
    onViewQuote,
} from "redux/actions/quotes";
import QuoteFormFields from "components/page/quotes/QuoteFormFields";
import {fetchQuotesOnce} from "remotes/fetching";
import MuiModal from "../../modal/MuiModal";
import MuiSubmitButton from "../../form/MuiSubmitButton";

const Footer = styled.div`
    margin-top: 30px;
`;

const Error = ({children}) => <span className="error-msg text-danger">{children}</span>;

export class QuoteForm extends Component {
    static propTypes = {
        quote: PropTypes.object.isRequired,
        userIsAdmin: PropTypes.bool.isRequired,
        onSaveQuoteUpdate: PropTypes.func.isRequired,
        updateQuoteFormField: PropTypes.func.isRequired,
        onCloseQuoteForm: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            required: ["name", "cust_name", "from_country_code", "to_country_code"],
            submitDone: false,
        };

        // If no quotes loaded, get them.
        fetchQuotesOnce();
    }

    render() {
        return (
            <MuiModal
                heading="Make an offer"
                smallHeading="Land transport"
                ariaLabel="quote-history"
                onClose={this.props.onCloseQuoteForm}
            >
                <form method="#" action="#" className="mr-2" onSubmit={this.sendOffer.bind(this)}>
                    <QuoteFormFields
                        quote={this.props.quote}
                        userIsAdmin={this.props.userIsAdmin}
                        errors={this.state.errors}
                        submitDone={this.state.submitDone}
                        updateQuoteFormField={this.props.updateQuoteFormField}
                    />
                    <Footer className="text-center">
                        {this.state.error ? <Error>Unknown error, try again.</Error> : ""}
                        <MuiSubmitButton
                            disabled={this.isDisabled()}
                            loading={this.state.loading}
                            buttonText="Send"
                            arrowButton={true}
                        />
                    </Footer>
                </form>
            </MuiModal>
        );
    }

    isDisabled() {
        if (this.state.loading) {
            return true;
        }
        return this.state.submitDone;
    }

    async sendOffer(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            submitDone: true,
        });

        this.props.quote.status = "Offer made";
        this.props.onSaveQuoteUpdate({...this.props.quote});

        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(30000);

        this.props.quote.status = "Opened";
        this.props.onSaveQuoteUpdate({...this.props.quote});

        await delay(30000);

        if (this.props.quote.price < 100) {
            this.props.quote.status = "Won";
            this.props.onSaveQuoteUpdate({...this.props.quote});
        }
        else {
            this.props.onViewQuote(this.props.quote);
        }
    }
}

function mapStateToProps(state) {
    return {
        quote: state.editingQuote.quote,
        userIsAdmin: state.user.is_superuser,
    };
}

export default connect(
    mapStateToProps,
    {onSaveQuoteUpdate, updateQuoteFormField, onCloseQuoteForm, onViewQuote},
)(QuoteForm);
