import React from "react";
import Exclusions from "components/page/exclude/Exclusions";
import ExclusionModal from "components/page/exclude/ExclusionModal";
import {Container} from "@material-ui/core";

export default function ExclusionPage() {
    return (
        <Container disableGutters maxWidth={false}>
            <ExclusionModal />
            <Exclusions />
        </Container>
    );
}
