import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import AsyncSelect from "react-select/lib/Async";
import ajax from "remotes/ajax";
import toSelectObject from "util/toSelectObject";
import {onSetClientList, onSetClientListFetchError} from "redux/actions/exclusions";

export class ClientSelect extends Component {
    static propTypes = {
        clients: PropTypes.array.isRequired,
        hasError: PropTypes.bool.isRequired,
        value: PropTypes.object,
        placeholder: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onSetClientList: PropTypes.func.isRequired,
        onSetClientListFetchError: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
    };

    render() {
        return (
            <AsyncSelect
                className="asyncSelect"
                value={this.props.value || {label: this.props.label, value: undefined}}
                onChange={this.onChange.bind(this)}
                options={this.formatClientList(this.props.clients)}
                loadOptions={this.getOptions.bind(this)}
                placeholder={this.props.placeholder}
                loadingMessage={this.getLoadingMessage.bind(this)}
                noOptionsMessage={this.getNoOptionsMessage.bind(this)}
                maxMenuHeight={250}
            />
        );
    }

    getNoOptionsMessage({inputValue}) {
        if (this.props.hasError) {
            return "Error fetching clients.";
        }

        const cleanedValue = this.cleanValue(inputValue);
        if (!this.isLongEnough(cleanedValue)) {
            return "Type at least 3 characters...";
        }

        return "No results";
    }

    getLoadingMessage({inputValue}) {
        const cleanedValue = this.cleanValue(inputValue);
        if (!this.isLongEnough(cleanedValue)) {
            return "Type at least 3 characters...";
        }

        return "Loading ...";
    }

    cleanValue(inputValue = "") {
        return inputValue.trim().replace(/[^A-Z0-9 ]/gi, "");
    }

    isLongEnough(inputValue) {
        return inputValue.length >= 3;
    }

    async getOptions(inputValue, callback) {
        const cleanedValue = this.cleanValue(inputValue);
        if (!this.isLongEnough(cleanedValue)) {
            callback();
            return;
        }

        try {
            const clients = await ajax.doGetClientNames(cleanedValue);
            this.props.onSetClientList(clients);

            const labelValuePairs = this.formatClientList(clients);
            callback(labelValuePairs);
        }
        catch (error) {
            this.props.onSetClientListFetchError(error.statusText);
        }
    }

    formatClientList() {
        const pairs = this.props.clients.map(({client_name, client_number}) => [client_number, `${client_name} (${client_number})`]);
        return toSelectObject(_.fromPairs(pairs));
    }

    onChange(option) {
        const client = _.find(this.props.clients, {client_number: option.value});
        this.props.onChange({client_name: client.client_name, client_number: option.value});
    }
}

function mapStateToProps(state) {
    return {
        clients: state.clients.length ? state.clients : [],
        hasError: !!state.clients.error,
    };
}

export default connect(
    mapStateToProps,
    {onSetClientList, onSetClientListFetchError},
)(ClientSelect);
