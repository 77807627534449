import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Prediction from "components/page/dashboard/Predictions";
import fetchPredictions from "remotes/fetchPredictions";
import Benefits from "components/page/dashboard/Benefits";
import ExecutionQuantities from "components/page/dashboard/ExecutionQuantities";
import {Container} from "@material-ui/core";

export class PredictionsPage extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
    };

    componentDidMount() {
        fetchPredictions(this.props.id);
    }

    render() {
        return (
            <div className="content">
                <Container disableGutters maxWidth={false}>
                    <Benefits />
                    <ExecutionQuantities />
                    <Prediction />
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        id: state.jobId,
    };
}

export default connect(mapStateToProps)(PredictionsPage);
