import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ajax from "remotes/ajax";
import Search from "components/page/jobs/Search";
import JobsWrapper from "components/page/jobs/JobsWrapper";
import JobForm from "components/page/jobs/JobForm";
import {onJobFetchFail, onJobsReceived, onResetJobs} from "redux/actions/jobs/jobs";
import {Container, Paper, Box} from "@material-ui/core";

export class JobPage extends Component {
    static propTypes = {
        jobs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        isEditOpen: PropTypes.bool,
        onResetJobs: PropTypes.func.isRequired,
        onJobsReceived: PropTypes.func.isRequired,
        onJobFetchFail: PropTypes.func.isRequired,
    };

    async componentDidMount() {
        this.props.onResetJobs();

        try {
            const jobs = await ajax.doGetJobs();
            this.props.onJobsReceived(jobs);
        }
        catch (error) {
            this.props.onJobFetchFail(error.statusText);
        }
    }

    render() {
        return (
            <div className="content">
                <Container disableGutters maxWidth={false}>
                    <Paper elevation={3}>
                        <Box p={2}>
                            {this.renderForm()}
                            <Search />
                            <JobsWrapper jobs={this.props.jobs} />
                        </Box>
                    </Paper>
                </Container>
            </div>
        );
    }

    renderForm() {
        if (!this.props.isEditOpen) {
            return false;
        }

        return <JobForm />;
    }
}

function mapStateToProps(state) {
    return {
        jobs: state.jobs,
        isEditOpen: state.editingJob.isOpen,
    };
}

export default connect(
    mapStateToProps,
    {onResetJobs, onJobsReceived, onJobFetchFail},
)(JobPage);
