import _ from "lodash";
import ajax from "remotes/ajax";
import pages from "maps/pages";
import {onSetPage} from "redux/actions";
import {onJobExecutionFetchFail, onResetDashboard, onSetJobExecution} from "redux/actions/dashboard/dashboard";
import store from "redux/store";
import {onResetBenefits, onSetBenefitFetchFail, onSetBenefits} from "redux/actions/dashboard/benefits";

export default function(jobId) {
    store.dispatch(onResetDashboard());

    ajax.doGetJob(jobId)
        .then((job) => {
            if (job.id) {
                getJobExecution(job);
                fetchBenefits();
            }
            else {
                store.dispatch(onSetPage(pages.JOBS));
            }
        })
        .catch((error) => {
            store.dispatch(onJobExecutionFetchFail(error.statusText));
            store.dispatch(onSetBenefitFetchFail(error.statusText));
        });
}

async function getJobExecution(job) {
    const executionId = _.get(job, ["executions", 0, "id"]);
    if (!executionId) {
        store.dispatch(onSetJobExecution({job}));
    }
    else {
        fetchExecution(executionId, job);
    }
}

async function fetchExecution(id, job) {
    try {
        const execution = await ajax.doGetJobExecution(id);
        store.dispatch(onSetJobExecution({...execution, job}));
    }
    catch (error) {
        store.dispatch(onJobExecutionFetchFail(error.statusText));
    }
}

export async function fetchBenefits() {
    store.dispatch(onResetBenefits({}));
    try {
        const benefits = await ajax.doGetBenefits();
        store.dispatch(onSetBenefits(benefits));
    }
    catch (error) {
        store.dispatch(onSetBenefitFetchFail(error.statusText));
    }
}
