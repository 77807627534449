import $ from "jquery";
import store from "redux/store";
import {onLogout} from "redux/actions/auth";
import {dateTimeFormat} from "util/dateTime";
import moment from "moment";

export default {
    doGetJobExecution(id) {
        const url = `job-execution/${id}/`;
        return doGet(url);
    },

    doSetPredictionToCorrect(id, client) {
        const url = `prediction/${id}/`;
        return doPut(url, {prediction_accuracy: "CORRECT", ...client});
    },

    doSetPredictionToUnclear(id, client) {
        const url = `prediction/${id}/`;
        return doPut(url, {prediction_accuracy: "UNCLEAR", ...client});
    },

    doSetPredictionToWrong(id, client) {
        const url = `prediction/${id}/`;
        return doPut(url, {prediction_accuracy: "WRONG", ...client});
    },

    doGetPredictionResults(id) {
        const url = `prediction-details/${id}/`;
        return doGet(url);
    },

    doGetPredictionContacts(id) {
        const url = `prediction-client-contacts/${id}/`;
        return doGet(url);
    },

    doRemoveContact(id) {
        const url = `client-contact-delete/${id}/`;
        return doPut(url);
    },

    doSendPredictionsToEmail(data) {
        const url = "prediction-send-email/";
        return doPost(url, data);
    },

    doGetStatisticalData(id, months) {
        const url = `prediction-statistical-data/${id}/${months}/`;
        return doGet(url);
    },

    // Jobs

    doGetJobs() {
        const url = "job/";
        return doGet(url);
    },

    doGetDefaultJob() {
        const url = "job/?is_default=True";
        return doGet(url).then((jobList) => {
            if (jobList.length) {
                return jobList[0]; // Needs to be unwrapped.
            }

            return jobList;
        });
    },

    doGetJob(id) {
        if (!id) {
            return this.doGetDefaultJob();
        }

        const url = `job/${id}/`;
        return doGet(url);
    },

    doAddJob(job) {
        const url = "job/";
        return doPost(url, job);
    },

    doUpdateJob(job) {
        const url = `job/${job.id}/`;
        return doPut(url, job);
    },

    doArchiveJob(id) {
        const url = `job/${id}/`;
        return doDelete(url);
    },

    doCheckJob(parameters) {
        const url = "check-job";
        return doGet(url, parameters);
    },

    // Exclusions

    doGetExclusions() {
        const url = "exclusion/";
        return doGet(url);
    },

    doAddExclusion(exclusion) {
        const url = "exclusion/";
        return doPost(url, {
            is_global: false,
            reason_for_exclusion: "AGREEMENT",
            created_date: moment().format(dateTimeFormat),
            user_id: store.getState().user.id,
            ...exclusion,
        });
    },

    doUpdateExclusion(exclusion) {
        const url = `exclusion/${exclusion.id}/`;
        return doPatch(url, exclusion);
    },

    doArchiveExclusion(id) {
        const url = `exclusion/${id}/`;
        return doPatch(url, {end_date: moment().format(dateTimeFormat)});
    },

    doExclusionRemove(id) {
        const url = `exclusion/${id}/`;
        return doDelete(url);
    },

    // Quotes

    doGetQuotes() {
        const url = "quote/";
        return doGet(url);
    },

    doUpdateQuote(quote) {
        const url = `quote/${quote.id}/`;
        return doPatch(url, quote);
    },

    doArchiveQuote(id) {
        const url = `quote/${id}/`;
        return doPatch(url, {status: "Archived"});
    },

    // Others

    doLogin(username, password) {
        const url = "auth";
        return doPost(url, {username, password});
    },

    doLogout() {
        const url = "logout";
        return doGet(url);
    },

    doGetClientNames(search) {
        const url = `client/?search_term=${search}`;
        return doGet(url);
    },

    doGetBenefits() {
        const url = "benefits/";
        return doGet(url);
    },

    doGetUser() {
        const url = "user/";
        return doGet(url);
    },

    doGetCountryCodes() {
        const url = "country-codes/";
        return doGet(url);
    },

    doGetQueueLength() {
        const url = "queue-length/";
        return doGet(url);
    },
};

/*
    Firefox can't handle 200 with no response body.

    $.ajax needs either 204 as response or anything as response body.
    https://stackoverflow.com/a/27129567
 */

function doGet(url, data, options) {
    return doAjax(url, "GET", data, options);
}

function doPost(url, data, options) {
    return doAjax(url, "POST", JSON.stringify(data), options);
}

function doPut(url, data, options) {
    return doAjax(url, "PUT", JSON.stringify(data), options);
}

function doPatch(url, data, options) {
    return doAjax(url, "PATCH", JSON.stringify(data), options);
}

function doDelete(url, data, options) {
    return doAjax(url, "DELETE", JSON.stringify(data), options);
}

function doAjax(url, method, data, options = {}) {
    const token = store.getState().auth.token;

    return $.ajax(`https://demo.aitoldyou.com/api/${url}`, {
        ...options,
        method,
        data,
        crossDomain: true,
        contentType: "application/json",
        headers: {
            "X-CSRFToken": getToken(),
            Authorization: token ? `Token ${token}` : undefined,
        },
    }).catch((error) => {
        if (error.status === 403) {
            store.dispatch(onLogout());
        }

        throw error;
    });
}

// https://docs.djangoproject.com/en/2.1/ref/csrf/
function getToken() {
    const name = "csrftoken";
    let cookieValue = null;

    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = $.trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
