const styles = () => ({
    listItem: {
        padding: "0",
    },
    navLink: {
        display: "flex",
        textTransform: "none",
        alignItems: "center",
        color: "inherit",
        paddingLeft: "2.1rem",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        width: "100%",
        whiteSpace: "nowrap",
    },
    navLinkActive: {
        background: "#E0FBF3",
        color: "#29B88C",
    },
    icon: {
        color: "#1F0242",
        minWidth: "45px",
    },
    activeLinkIcon: {
        color: "#29B88C",

    },
    listItemText: {
        fontSize: "1.1rem !important",
        fontWeight: "600 !important",
        color: "$dark-primary !important",
    },
});

export {styles};
