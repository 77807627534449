import store from "redux/store";
import {onRestoreSession} from "redux/actions/auth";

const SESSION = "session";
let currentState;
export default {
    // clear not needed, store.subscribe automatically resets store and updates session.

    read() {
        try {
            const session = JSON.parse(sessionStorage.getItem(SESSION));
            if (session) {
                store.dispatch(onRestoreSession(session));
            }
        }
        catch (err) {} // eslint-disable-line no-empty
    },

    write() {
        const {
            page,
            jobId,
            auth: {token},
        } = store.getState();

        const readyValue = {page, token, jobId};
        const previousState = currentState;
        currentState = readyValue;

        if (!deepEqual(previousState, currentState)) {
            try {
                sessionStorage.setItem(SESSION, JSON.stringify(readyValue));
            }
            catch (err) {
                // Ignore private mode errors.
            }
        }
    },
};

function deepEqual(a = {}, b = {}) {
    if (a.token !== b.token) {
        return false;
    }

    if (a.page !== b.page) {
        return false;
    }

    if (a.jobId !== b.jobId) {
        return false;
    }

    return true;
}
