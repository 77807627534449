import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {listToSelectObject} from "util/toSelectObject";
import {onSetFilter} from "redux/actions/statistics";
import {statisticsPeriodFilter} from "maps/filters";
import {Box, Grid, Typography, Select, MenuItem} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";

const HumbleSelect = withStyles({
    root: {
        width: "4rem",
        padding: "1rem",
    },
})((props) => <Select {...props} />);

class Filter extends Component {
    static propTypes = {
        selectedPeriod: PropTypes.string.isRequired,
        onSetFilter: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Box p={2}>
                <Grid container>
                    <div style={{marginLeft: "auto"}}>
                        <Typography gutterBottom variant="body2">
                            Statistics period:
                        </Typography>
                        <HumbleSelect
                            variant="outlined"
                            name="selectPeriod"
                            onChange={(event) => this.props.onSetFilter(event.target)}
                            value={this.props.selectedPeriod}
                        >
                            {this.renderOptions()}
                        </HumbleSelect>
                    </div>
                </Grid>
            </Box>
        );
    }

    renderOptions() {
        const options = listToSelectObject(statisticsPeriodFilter, false);
        return options.map((option, index) => {
            return (
                <MenuItem
                    label={option.label}
                    value={option.value}
                    key={index}
                    name={option.value}
                >{option.label}
                </MenuItem>
            );
        });
    }
}

function mapStateToProps(state) {
    return {
        selectedPeriod: state.statisticsFilter.selectedPeriod,
    };
}

export default connect(
    mapStateToProps,
    {onSetFilter},
)(Filter);
