import React from "react";
import clsx from "clsx";
import MenuItem from "components/page/navigation/MenuItem";
import pages from "maps/pages";
import {ReactComponent as Logo} from "styles/logo.svg";
import {useStyles} from "../../styles/materialUIstyles/sidebarStyles";
import {AppBar, Drawer, List, Box} from "@material-ui/core";

export default function Sidebar(props) {
    const {sidebarOpen, toggleSidebar} = props;
    const classes = useStyles(props);

    return (
        <div data-image="../../static/assets/img/full-screen-image-3.jpg">
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: sidebarOpen,
                })}
            />
            <Drawer
                variant="permanent"
                className={clsx("sidebar-wrapper", {
                    [classes.drawerOpen]: sidebarOpen,
                    [classes.drawerClose]: !sidebarOpen,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: sidebarOpen,
                        [classes.drawerClose]: !sidebarOpen,
                    }),
                }}
                onMouseEnter={toggleSidebar}
                onMouseLeave={toggleSidebar}
            >
                <div className={classes.toolbar}>
                    <Box
                        p={4}
                        className={classes.logoWrapper}
                    >
                        <Logo className={sidebarOpen ? "" : classes.hide} />
                    </Box>
                    <List className={classes.listItems}>
                        <MenuItem icon="pe-7s-flag" page={pages.DASHBOARD} label={"New shipments"} />
                        <MenuItem icon="pe-7s-plugin" page={pages.JOBS} label={"My predictions"} />
                        <MenuItem icon="pe-7s-calculator" page={pages.QUOTES} label={"Quotes"} />
                        <MenuItem icon="pe-7s-graph2" page={pages.STATISTICS} label={"Statistics"} />
                        <MenuItem icon="pe-7s-news-paper" page={pages.EXCLUSIONS} label={"Exclusion list"} />
                    </List>
                    {/* <div className={classes.toggleIconContainer}>
                        <IconButton className={classes.toggleIcon} onClick={toggleSidebar}>
                            {sidebarOpen ? 
                                <ChevronLeftIcon className={classes.toggleIcon} /> : <ChevronRightIcon className={classes.toggleIcon} />}
                        </IconButton>
                    </div> */}
                </div>
            </Drawer>
        </div>
    );
}
