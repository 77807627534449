import React, {Component} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ShortInput = styled.input`
    display: inline-block;
    width: 250px;
    margin-right: 6px;
`;

export default class ContactAddForm extends Component {
    static propTypes = {
        newContact: PropTypes.object.isRequired,
        addContact: PropTypes.func.isRequired,
        onChangeNewContact: PropTypes.func.isRequired,
    };

    render() {
        return (
            <form onSubmit={this.props.addContact}>
                <h5>Add contact:</h5>
                <ShortInput
                    autoFocus
                    type="text"
                    onChange={(event) => this.props.onChangeNewContact("name", event.target.value)}
                    className="form-control"
                    placeholder="New contact name"
                    value={this.props.newContact.name || ""}
                />
                <ShortInput
                    type="email"
                    onChange={(event) => this.props.onChangeNewContact("email", event.target.value)}
                    className="form-control"
                    placeholder="New contact email"
                    value={this.props.newContact.email || ""}
                />
                <button className="btn btn-xs plus-button" disabled={!this.props.newContact.name || !this.props.newContact.email}>
                    +
                </button>
            </form>
        );
    }
}
