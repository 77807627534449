import {makeStyles} from "@material-ui/core/styles";

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
    appBar: {
        width: "100%",
        height: "0",
        position: "absolute",
        zIndex: theme.zIndex.drawer - 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        height: "0",
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolBar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    hide: {
        display: "none",
    },
    logoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "2rem",
    },
    logoContainerImg: {
        maxWidth: "2rem",
        maxHeight: "2rem",
    },
    logoContainerText: {
        color: "#1F0242",
        fontSize: "1.8rem",
        fontWeight: "600",
        marginBottom: "0",
        marginLeft: "1rem",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    logoWrapper: {
        textAlign: "center",
        height: "9rem",
    },
    listItems: {
        marginTop: "10rem",
        marginBottom: "10rem",
    },
    toggleIconContainer: {
        background: "#611AD0",
        width: "3.5rem",
        borderRadius: "50%",
        display: "block",
        margin: "auto",
    },
    toggleIcon: {
        color: "#FFFFFF",
    },
}));

export {useStyles};
