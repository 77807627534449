// import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import JobExecQuantities from "components/page/dashboard/JobExecQuantities";
import Loading from "../../notifications/Loading";
import {Box, Container, Grid} from "@material-ui/core";
import {faPallet, faBoxes, faDolly} from "@fortawesome/free-solid-svg-icons";

class ExecutionQuantities extends Component {
    static propTypes = {
        jobExecution: PropTypes.object,
    };

    render() {
        if (!this.props.jobExecution) {
            return (
                <div className="card-body text-center">
                    <Loading />
                </div>
            );
        }
        return (
            <Container disableGutters maxWidth={false}>
                <Box mb={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <JobExecQuantities
                                color="warning"
                                // error={!!this.props.quantities.error}
                                icon={faPallet}
                                title="Predicted total Gross Weight"
                                unit="KG"
                                value={this.props.jobExecution.job_execution_quantity[0].gross_weight}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <JobExecQuantities
                                color="success"
                                // error={!!this.props.quantities.error}
                                icon={faBoxes}
                                title="Predicted total LDM"
                                unit="LDM"
                                value={this.props.jobExecution.job_execution_quantity[0].ldm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <JobExecQuantities
                                color="success"
                                // error={!!this.props.benefits.error}
                                icon={faDolly}
                                title="Predicted total CBM"
                                unit="CBM"
                                value={this.props.jobExecution.job_execution_quantity[0].cbm}
                            />
                        </Grid>
                    </Grid>
                </Box >
            </Container >
        );
    }
}

function mapStateToProps(state) {
    return {
        jobExecution: state.jobExecution,
    };
}

export default connect(mapStateToProps)(ExecutionQuantities);
