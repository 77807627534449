import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    typography: {
        fontWeight: "500",
        display: "flex",
        alignItems: "center",
        "& svg": {
            marginRight: ".5rem",
        },
    },
}));

export default function HeadingWithIcon(props) {
    const classes = useStyles();
    return (
        <Typography
            variant={props.variant}
            gutterBottom={props.gutterBottom}
            className={classes.typography}
            align={props.align}
        >
            {props.icon}
            {props.heading}
        </Typography>
    );
}
