import * as actionType from "../types";

export const onSetJobExecution = (jobExecution) => {
    return {
        type: actionType.SET_JOB_EXECUTION,
        jobExecution,
    };
};

export const onJobExecutionFetchFail = (error) => {
    return {
        type: actionType.JOB_EXECUTION_FETCH_FAIL,
        error,
    };
};

export const onResetDashboard = () => {
    return {
        type: actionType.RESET_DASHBOARD,
    };
};

export const updatePrediction = (prediction) => {
    return {
        type: actionType.UPDATE_PREDICTION,
        prediction,
    };
};
