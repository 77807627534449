import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import ajax from "remotes/ajax";
import JobDetails from "components/page/jobs/JobDetails";
import JobActions from "components/page/jobs/JobActions";
import JobArchiving from "components/page/jobs/JobArchiving";
import {predictionStates} from "maps/classifiers";
import {isArchived} from "util/decisions";
import {Typography, Grid, Box} from "@material-ui/core";

export default class Job extends Component {
    static propTypes = {
        job: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isPredictable: undefined,
        };
    }

    async componentDidMount() {
        const parameterFields = ["ignore_exclusion_lists", "from_country_code", "from_region_code", "to_country_code", "to_region_code"];
        const parameters = _.pick(this.props.job.parameters[0], parameterFields);

        if (parameters.from_country_code && parameters.to_country_code) {
            const {can_calculate} = await ajax.doCheckJob(parameters);

            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({
                isPredictable: can_calculate,
            });
        }
    }

    render() {
        return (
            <div className={this.getStatusClass()}>
                <div>
                    <JobArchiving job={this.props.job} />
                    {this.renderDefaultPredictionLabel()}
                    <Typography
                        variant="h5"
                        gutterBottom
                        style={{fontWeight: "600"}}
                    >
                        {this.props.job.name}
                    </Typography>
                    {this.renderGeneratedTime()}
                </div>
                <Box pt={2}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={12} sm={10}>
                            <JobDetails job={this.props.job} isPredictable={this.state.isPredictable} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <JobActions job={this.props.job} />
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    }

    getStatusClass() {
        return isArchived(this.props.job) ?
            "my-prediction prediction-status-" + predictionStates.OUTDATED.toLowerCase() :
            "my-prediction prediction-status-" + predictionStates[this.props.job.display_status].toLowerCase();
    }

    renderDefaultPredictionLabel() {
        if (this.props.job.is_default) {
            return (<div style={{float: "right", fontWeight: "700"}}>
                Default prediction
            </div>);
        }
    }

    renderGeneratedTime() {
        if (this.props.job.created_date) {
            return <p className="card-category">Generated {this.props.job.created_date}</p>;
        }
    }
}
