export const SET_PAGE = "SET_PAGE";

// Dashboard
export const SET_JOB_EXECUTION = "SET_JOB_EXECUTION";
export const JOB_EXECUTION_FETCH_FAIL = "JOB_EXECUTION_FETCH_FAIL";
export const UPDATE_PREDICTION = "UPDATE_PREDICTION";

export const RESET_BENEFITS = "RESET_BENEFITS";
export const SET_BENEFITS = "SET_BENEFITS";
export const BENEFIT_FETCH_FAIL = "BENEFIT_FETCH_FAIL";

export const RESET_DASHBOARD = "RESET_DASHBOARD";
export const SORT_PREDICTION_RESULTS = "SORT_PREDICTION_RESULTS";
export const SORT_PREDICTION_DETAILS = "SORT_PREDICTION_DETAILS";
export const SORT_SHIPMENTS = "SORT_SHIPMENTS";

export const OPEN_RESULT_DETAILS = "OPEN_RESULT_DETAILS";
export const LOAD_RESULT_DETAILS = "LOAD_RESULT_DETAILS";
export const LOAD_RESULT_DETAILS_FAILED = "LOAD_RESULT_DETAILS_FAILED";
export const CLOSE_RESULT_DETAILS = "CLOSE_RESULT_DETAILS";

export const OPEN_GRAPH = "OPEN_GRAPH";
export const LOAD_GRAPH = "LOAD_GRAPH";
export const LOAD_GRAPH_FAILED = "LOAD_GRAPH_FAILED";
export const CLOSE_GRAPH = "CLOSE_GRAPH";

export const OPEN_EMAIL_SENDING = "OPEN_EMAIL_SENDING";

export const LOAD_EMAIL_CONTACTS = "LOAD_EMAIL_CONTACTS";
export const LOAD_EMAIL_CONTACTS_FAILED = "LOAD_EMAIL_CONTACTS_FAILED";
export const REMOVE_EMAIL_SENDING_CONTACT = "REMOVE_EMAIL_SENDING_CONTACT";
export const CHANGE_NEW_CONTACT = "CHANGE_NEW_CONTACT";
export const ADD_NEW_CONTACT = "ADD_NEW_CONTACT";
export const EMAIL_SEND_REQUESTED = "EMAIL_SEND_REQUESTED";
export const EMAIL_SEND_REQUEST_FAILED = "EMAIL_SEND_REQUEST_FAILED";
export const CLOSE_EMAIL_SENDING = "CLOSE_EMAIL_SENDING";

// Auth
export const SET_TOKEN = "SET_TOKEN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOG_OUT = "LOG_OUT";
export const SET_INPUT = "SET_INPUT";
export const RESTORE_SESSION = "RESTORE_SESSION";
export const SET_USER = "SET_USER";

// Jobs
export const RESET_JOBS = "RESET_JOBS";
export const JOBS_RECEIVED = "JOBS_RECEIVED";
export const JOB_FETCH_FAIL = "JOB_FETCH_FAIL";
export const CREATE_JOB = "CREATE_JOB";
export const EDIT_JOB = "EDIT_JOB";
export const ARCHIVE_JOB = "ARCHIVE_JOB";
export const UPDATE_JOB_FORM_FIELD = "UPDATE_JOB_FORM_FIELD";
export const SAVE_NEW_JOB = "SAVE_NEW_JOB";
export const SAVE_JOB_UPDATE = "SAVE_JOB_UPDATE";
export const CLOSE_JOB_FORM = "CLOSE_JOB_FORM";

export const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";
export const SET_COUNTRY_LIST_FETCH_FAIL = "SET_COUNTRY_LIST_FETCH_FAIL";

export const SET_FILTER_STATUS = "SET_FILTER_STATUS";

// Exclusions
export const EXCLUSIONS_RECEIVED = "EXCLUSIONS_RECEIVED";
export const EXCLUSION_FETCH_FAIL = "EXCLUSION_FETCH_FAIL";
export const CREATE_EXCLUSION = "CREATE_EXCLUSION";
export const PREFILL_EXCLUSION_FORM = "PREFILL_EXCLUSION_FORM";
export const EDIT_EXCLUSION = "EDIT_EXCLUSION";
export const SAVE_NEW_EXCLUSION = "SAVE_NEW_EXCLUSION";
export const SAVE_EXCLUSION_UPDATE = "SAVE_EXCLUSION_UPDATE";
export const CLOSE_EXCLUSION_FORM = "CLOSE_EXCLUSION_FORM";
export const ARCHIVE_EXCLUSION = "ARCHIVE_EXCLUSION";
export const REMOVE_EXCLUSION = "REMOVE_EXCLUSION";
export const SET_CLIENT_LIST = "SET_CLIENT_LIST";
export const SET_CLIENT_LIST_FETCH_ERROR = "SET_CLIENT_LIST_FETCH_ERROR";
export const UPDATE_EXCLUSION_FORM_FIELD = "UPDATE_EXCLUSION_FORM_FIELD";
export const UPDATE_EXCLUSION_CLIENT_FIELD = "UPDATE_EXCLUSION_CLIENT_FIELD";

// Quotes
export const QUOTES_RECEIVED = "QUOTES_RECEIVED";
export const QUOTE_FETCH_FAIL = "QUOTE_FETCH_FAIL";
export const PREFILL_QUOTE_FORM = "PREFILL_QUOTE_FORM";
export const VIEW_QUOTE = "VIEW_QUOTE";
export const CLOSE_QUOTE_VIEW = "CLOSE_QUOTE_VIEW";
export const EDIT_QUOTE = "EDIT_QUOTE";
export const SAVE_QUOTE_UPDATE = "SAVE_QUOTE_UPDATE";
export const CLOSE_QUOTE_FORM = "CLOSE_QUOTE_FORM";
export const ARCHIVE_QUOTE = "ARCHIVE_QUOTE";
export const UPDATE_QUOTE_FORM_FIELD = "UPDATE_QUOTE_FORM_FIELD";

// Statistics
export const SALES_RECEIVED = "SALES_RECEIVED";
export const SALES_FETCH_FAIL = "SALES_FETCH_FAIL";
export const LOAD_SALES_FUNNEL = "LOAD_SALES_FUNNEL";
export const SET_FILTER = "SET_FILTER";
