import * as actionType from "redux/actions/types";
import {combineReducers} from "redux";
import {defaultStatisticsPeriod} from "maps/filters";

export const selectedPeriod = (state = defaultStatisticsPeriod, action) => {
    switch (action.type) {
        case actionType.SET_FILTER:
            return action.value;

        default:
            return state;
    }
};

const filterReducer = combineReducers({
    selectedPeriod,
});

const statisticsFilter = (state, action) => {
    return filterReducer(state, action);
};

export default statisticsFilter;
