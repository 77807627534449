import _ from "lodash";

export default function(list, {column, direction}) {
    const sorted = _.sortBy(list, [column]);

    if (direction === "asc") {
        return sorted;
    }
    return _.reverse(sorted);
}
