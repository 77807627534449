import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ajax from "remotes/ajax";
import Loading from "components/notifications/Loading";
import EmailSendingModalContent from "components/page/dashboard/predictions/emailSendingModal/EmailSendingModalContent";
import {onCloseEmailSending, onLoadEmailContacts, onLoadEmailContactsFailed} from "redux/actions/dashboard/emailSending";
import CloseButton from "components/modal/CloseButton";
import EscClosable from "components/modal/EscClosable";

class EmailSendingModal extends Component {
    static propTypes = {
        predictionId: PropTypes.number,
        contacts: PropTypes.array,
        onLoadEmailContacts: PropTypes.func.isRequired,
        onLoadEmailContactsFailed: PropTypes.func.isRequired,
        onCloseEmailSending: PropTypes.func.isRequired,
    };

    async componentDidUpdate(prevProps) {
        if (prevProps.predictionId || prevProps.predictionId === this.props.predictionId) {
            return;
        }

        try {
            const contacts = await ajax.doGetPredictionContacts(this.props.predictionId);
            this.props.onLoadEmailContacts(contacts);
        }
        catch (error) {
            this.props.onLoadEmailContactsFailed(error.statusText);
        }
    }

    render() {
        if (!this.props.predictionId) {
            return false;
        }

        return (
            <EscClosable onCloseModal={this.props.onCloseEmailSending}>
                <div className="overlay-card-wrapper">
                    <div className="overlay-card col-lg-8 col-md-10 col-sm-11">
                        <div className="card">
                            <div className="card-header">
                                <CloseButton onClick={this.props.onCloseEmailSending} />

                                <h4 className="card-title text-primary">Send predictions to email</h4>
                            </div>

                            {this.renderContent()}
                        </div>
                    </div>
                </div>
            </EscClosable>
        );
    }

    renderContent() {
        if (this.props.error) {
            return <div className="card-body text-danger">Error! {this.props.error}</div>;
        }

        if (!this.props.contacts) {
            return (
                <div className="card-body text-center">
                    <Loading />
                </div>
            );
        }

        return (
            <EmailSendingModalContent
                contacts={this.props.contacts}
                predictionId={this.props.predictionId}
                customerName={this.props.customerName}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        predictionId: state.emailSending.predictionId,
        customerName: state.emailSending.customerName,
        error: state.emailSending.error,
        contacts: state.emailSending.contacts,
    };
}

export default connect(
    mapStateToProps,
    {
        onLoadEmailContacts,
        onLoadEmailContactsFailed,
        onCloseEmailSending,
    },
)(EmailSendingModal);
