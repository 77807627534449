import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {onArchiveQuote, onEditQuote, onViewQuote} from "redux/actions/quotes";
import {formatRouteFrom, formatRouteTo} from "util/formatRoute";
import HistoryIcon from "@material-ui/icons/History";
import EditIcon from "@material-ui/icons/Edit";
import ArchiveIcon from "@material-ui/icons/Archive";
import {Link} from "@material-ui/core";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import {withStyles} from "@material-ui/styles";
import Tooltip from "components/form/Tooltip";
import SimpleDialog from "components/form/SimpleDialog";

const StyledLink = withStyles({
    root: {
        cursor: "pointer",
        fontWeight: "600",
    },
})((props) => <Link {...props} />);

class Quote extends Component {
    static propTypes = {
        quote: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
        };
    }

    render() {
        return (
            <tr className={this.getStatusClass()}>
                <td>{this.props.quote.id}</td>
                <td>
                    <StyledLink
                        color="inherit"
                        underline="hover"
                        onClick={this.props.quote.status == "Submitted" ? (
                            this.onClickEdit.bind(this)
                        ) : (
                            this.onClickView.bind(this)
                        )}
                    >
                        {this.props.quote.status}
                    </StyledLink></td>
                <Tooltip title="Land transport">
                    <td><LocalShippingIcon /></td>
                </Tooltip>
                <td>{formatRouteFrom(this.props.quote)}</td>
                <td>{formatRouteTo(this.props.quote)}</td>
                <td>{this.props.quote.company_name}</td>
                <td>{this.props.quote.weight}</td>
                <td>{this.props.quote.ldm}</td>
                <td>{this.props.quote.price}</td>
                <td className="td-actions text-right">
                    {this.props.quote.status == "Submitted" ? (
                        undefined
                    ) : (
                        <Tooltip title="Quote history">
                            <button
                                type="button"
                                className="btn linkStyleButton btn-xs"
                                onClick={this.onClickView.bind(this)}
                            >
                                <HistoryIcon />
                            </button>
                        </Tooltip>
                    )}
                    {this.props.quote.status == "Archived" || this.props.quote.status == "Won" ? (
                        undefined
                    ) : (
                        <Tooltip title="Edit">
                            <button
                                type="button"
                                className="btn linkStyleButton btn-xs"
                                onClick={this.onClickEdit.bind(this)}
                            >
                                <EditIcon />
                            </button>
                        </Tooltip>
                    )}
                    {this.props.quote.status == "Archived" || this.props.quote.status == "Won" ? (
                        undefined
                    ) : (
                        <Tooltip title="Archive">
                            <button
                                type="button"
                                className="btn linkStyleButton btn-xs"
                                onClick={this.openDialog.bind(this)}
                            >
                                <ArchiveIcon />
                            </button>
                        </Tooltip>
                    )}
                </td>
                <SimpleDialog
                    open={this.state.dialogOpen}
                    actionType="archive"
                    title="Are you sure you want to archive this quote?"
                    onArchive={this.onClickArchive.bind(this)}
                    onClose={this.closeDialog.bind(this)}
                    buttonText="Archive"
                />
            </tr>
        );
    }

    async onClickView() {
        this.props.onViewQuote(this.props.quote);
    }

    async onClickEdit() {
        this.props.onEditQuote(this.props.quote);
    }

    async onClickArchive() {
        this.props.quote.status = "Archived";
        this.props.onArchiveQuote(this.props);
        //        const updatedQuote = await ajax.doArchiveQuote(this.props.quote.id);
        //        this.props.onArchiveQuote(updatedQuote);
    }

    openDialog() {
        this.setState({
            dialogOpen: true,
        });
    }

    closeDialog() {
        this.setState({
            dialogOpen: false,
        });
    }

    getStatusClass() {
        return "quotes-status-" + this.props.quote.status.replaceAll(" ", "").toLowerCase();
    }
}

export default connect(
    undefined,
    {onArchiveQuote, onEditQuote, onViewQuote},
)(Quote);
