import React from "react";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        marginTop: "2rem",
    },
    alert: {
        fontSize: "1rem",
        display: "flex",
        alignItems: "center",
    },
    pointer: {
        cursor: "pointer",
    },
}));

export default function Info(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Alert
                severity={props.severity}
                className={clsx(classes.alert, props.onClick && classes.pointer)}
                onClick={props.onClick}
            >
                {props.message}
            </Alert>
        </div>
    );
}
