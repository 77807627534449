import _ from "lodash";
import store from "redux/store";
import {isArchived} from "util/decisions";

export default function isExclusionMatch(exclusion, params) {
    if (params.from_country_code !== exclusion.from_country_code) {
        return false;
    }

    if (params.to_country_code !== exclusion.to_country_code) {
        return false;
    }

    if (!isPartialMatch(exclusion.from_region_code, params.from_region_code)) {
        return false;
    }

    if (!isPartialMatch(exclusion.to_region_code, params.to_region_code)) {
        return false;
    }

    return true;
}

function isPartialMatch(exclusion, param) {
    // If region code is missing in exclusion then param value does not matter.
    if (!exclusion) {
        return true;
    }

    if (param === exclusion) {
        return true;
    }

    // params, exclusion. If params contains exclusion, then match.
    if (param && String(param).indexOf(exclusion) > -1) {
        return true;
    }

    return false;
}

export function isRouteEqual(a, b) {
    const fields = ["from_country_code", "to_country_code", "from_region_code", "to_region_code"];
    const pickNoNull = (obj) =>
        _(obj)
            .pick(fields)
            .pickBy((field) => field !== null && field !== "")
            .value();
    return _.isEqual(pickNoNull(a), pickNoNull(b));
}

export function isDuplicate(newExclusion) {
    const exclusions = store.getState().exclusions;
    if (!exclusions.length || _.isEmpty(newExclusion)) {
        return false;
    }
    return _.some(exclusions, (existingExclusion) => isActiveAndNameAndRouteMatch(existingExclusion, newExclusion));
}

export function isActiveAndNameAndRouteMatch(existingExclusion, newExclusion) {
    if (isArchived(existingExclusion)) {
        return false;
    }
    if (existingExclusion.cust_no !== newExclusion.cust_no) {
        return false;
    }
    return isRouteEqual(existingExclusion, newExclusion);
}
