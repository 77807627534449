import * as actionType from "redux/actions/types";

export const onSalesReceived = (sales) => {
    return {
        type: actionType.SALES_RECEIVED,
        sales,
    };
};

export const onSalesFetchFail = (error) => {
    return {
        type: actionType.SALES_FETCH_FAIL,
        error,
    };
};

export const onLoadSalesFunnel = () => {
    return {
        type: actionType.LOAD_SALES_FUNNEL,
    };
};

export const onSetFilter = (params) => {
    return {
        type: actionType.SET_FILTER,
        value: params.value,
    };
};
