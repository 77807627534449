import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {frequency, trainingPeriods} from "maps/classifiers";
import toSelectObject, {listToSelectObject} from "util/toSelectObject";
import RegionOptions from "components/form/RegionOptions";
import TabSwitch from "components/form/TabSwitch";
import CheckboxWrap from "components/form/CheckboxWrap";
import {Box, FormControl, TextField, Grid, InputLabel} from "@material-ui/core";

export default class JobForm extends Component {
    static propTypes = {
        job: PropTypes.object.isRequired,
        submitDone: PropTypes.bool.isRequired,
        onUpdateField: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.handlePredictionPeriod = ::this.handlePredictionPeriod;
        this.handleRecurrance = ::this.handleRecurrance;
        this.handleTrainingPeriod = ::this.handleTrainingPeriod;
        this.handleTimeSeries = ::this.handleTimeSeries;
    }

    render() {
        return (
            <Fragment>
                {this.renderNameField()}
                {this.renderRouteField()}
                {this.renderTimeSeries()}
                {this.renderPrognosisRate()}
                {this.renderTrainingPeriod()}
                {this.renderPredictionPeriod()}
                {this.renderExclusionIgnore()}
                {this.renderDefaultPrediction()}
            </Fragment>
        );
    }

    renderNameField() {
        return (
            <FormControl fullWidth={true}>
                <TextField
                    variant="outlined"
                    fullWidth={true}
                    label="Prognosis name"
                    autoFocus
                    type="text"
                    onChange={(event) => this.props.onUpdateField("name", event.target.value)}
                    value={this.props.job.name || ""}
                />
            </FormControl>
        );
    }

    renderRouteField() {
        return (
            <Box pt={2}>
                <InputLabel>Route</InputLabel>
                <Grid container spacing={2} >
                    <RegionOptions
                        parameters={this.props.job}
                        submitDone={this.props.submitDone}
                        onChange={this.props.onUpdateField.bind(this)}
                    />
                </Grid>
            </Box>
        );
    }

    handleTimeSeries(event) {
        this.props.onUpdateField("timeseries_type", event.target.value);
    }

    renderTimeSeries() {
        return (
            <Box pt={2}>
                <InputLabel>Time series</InputLabel>
                <TabSwitch
                    onChange={this.handleTimeSeries}
                    options={listToSelectObject(["PICKUP_DATE", "ORDER_DATE"])}
                    value={this.props.job.timeseries_type}
                    groupName="timeseries_type"
                />
            </Box>
        );
    }

    handleRecurrance(event) {
        this.props.onUpdateField("recurrance", event.target.value);
    }

    renderPrognosisRate() {
        return (
            <Box pt={2}>
                <InputLabel>Type</InputLabel>
                <TabSwitch
                    onChange={this.handleRecurrance}
                    options={toSelectObject(frequency)}
                    value={this.props.job.recurrance}
                    groupName="recurrance"
                />
            </Box>
        );
    }

    handleTrainingPeriod(event) {
        this.props.onUpdateField("training_period", parseInt(event.target.value, 10));
    }

    renderTrainingPeriod() {
        return (
            <Box pt={2}>
                <InputLabel>Training period</InputLabel>
                <TabSwitch
                    onChange={this.handleTrainingPeriod}
                    options={toSelectObject(trainingPeriods)}
                    value={String(this.props.job.training_period)}
                    groupName="training_period"
                />
            </Box>
        );
    }

    handlePredictionPeriod(event) {
        this.props.onUpdateField("prediction_period", event.target.value);
    }

    renderPredictionPeriod() {
        return (
            <Box pt={2}>
                <InputLabel>Period</InputLabel>
                <TabSwitch
                    onChange={this.handlePredictionPeriod}
                    options={listToSelectObject(["WEEK", "MONTH"])}
                    value={this.props.job.prediction_period}
                    groupName="prediction_period"
                />
            </Box>
        );
    }

    renderDefaultPrediction() {
        return (
            <Box>
                <CheckboxWrap
                    value={this.props.job.is_default}
                    onChange={(event) => this.props.onUpdateField("is_default", event.target.checked)}
                >
                    Default prediction
                </CheckboxWrap>
            </Box>
        );
    }

    renderExclusionIgnore() {
        return (
            <Box pt={2}>
                <CheckboxWrap
                    value={this.props.job.ignore_exclusion_lists}
                    onChange={(event) => this.props.onUpdateField("ignore_exclusion_lists", event.target.checked)}
                >
                    Ignore exclusion lists
                </CheckboxWrap>
            </Box>
        );
    }
}
