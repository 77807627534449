import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ajax from "remotes/ajax";
import {onArchiveExclusion, onEditExclusion, onRemoveExclusion} from "redux/actions/exclusions";
import Route from "components/notifications/Route";
import {isArchived} from "util/decisions";
import EditIcon from "@material-ui/icons/Edit";
import ArchiveIcon from "@material-ui/icons/Archive";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "components/form/Tooltip";
import SimpleDialog from "components/form/SimpleDialog";

class Exclusion extends Component {
    static propTypes = {
        exclusion: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            dialogAction: "",
        };
    }

    render() {
        return (
            <>
                <tr className={isArchived(this.props.exclusion) ? "archived-job" : ""}>
                    <td>{this.props.exclusion.id}</td>
                    <td>{this.props.exclusion.name}</td>
                    <td>
                        {this.props.exclusion.cust_name} {this.props.exclusion.cust_no ? `(${this.props.exclusion.cust_no})` : ""}
                    </td>
                    <td>
                        <Route params={this.props.exclusion} />
                    </td>
                    <td>{this.getStatus()}</td>
                    <td className="td-actions text-right">
                        {isArchived(this.props.exclusion) ? (
                            undefined
                        ) : (
                            <Tooltip title="Edit">
                                <button
                                    type="button"
                                    className="btn btn-xs linkStyleButton"
                                    onClick={this.onClickEdit.bind(this)}
                                >
                                    <EditIcon />
                                </button>
                            </Tooltip>
                        )}
                        {isArchived(this.props.exclusion) ? (
                            undefined
                        ) : (
                            <Tooltip title="Archive">
                                <button
                                    type="button"
                                    className="btn btn-xs linkStyleButton"
                                    onClick={this.openArchiveDialog.bind(this)}
                                >
                                    <ArchiveIcon />
                                </button>
                            </Tooltip>
                        )}
                        <Tooltip title="Remove">
                            <button
                                type="button"
                                className="btn btn-xs linkStyleButton"
                                onClick={this.openRemoveDialog.bind(this)}
                            >
                                <DeleteIcon />
                            </button>
                        </Tooltip>
                    </td>
                    <SimpleDialog
                        open={this.state.dialogOpen}
                        actionType={this.state.dialogAction}
                        title={`Are you sure you want to ${this.state.dialogAction} this exclusion?`}
                        onRemove={this.onClickRemove.bind(this)}
                        onArchive={this.onClickArchive.bind(this)}
                        onClose={this.onDialogClose.bind(this)}
                        buttonText={this.state.dialogAction.toUpperCase()}
                    />
                </tr>
            </>
        );
    }

    openArchiveDialog() {
        this.setState({
            dialogOpen: true,
            dialogAction: "archive",
        });
    }

    openRemoveDialog() {
        this.setState({
            dialogOpen: true,
            dialogAction: "remove",
        });
    }

    onDialogClose() {
        this.setState({
            dialogOpen: false,
            dialogAction: "",
        });
    }

    getStatus() {
        if (isArchived(this.props.exclusion)) {
            return "Archived";
        }

        if (!this.props.exclusion.is_global) {
            return "Local";
        }

        if (this.props.exclusion.is_global) {
            return "Global";
        }
    }

    async onClickEdit() {
        this.props.onEditExclusion(this.props.exclusion);
    }

    async onClickArchive() {
        const updatedExclusion = await ajax.doArchiveExclusion(this.props.exclusion.id);
        this.props.onArchiveExclusion(updatedExclusion);
        this.onDialogClose();
    }

    async onClickRemove() {
        await ajax.doExclusionRemove(this.props.exclusion.id);
        this.props.onRemoveExclusion(this.props.exclusion.id);
        this.onDialogClose();
    }
}

export default connect(
    undefined,
    {onArchiveExclusion, onRemoveExclusion, onEditExclusion},
)(Exclusion);
