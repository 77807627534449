import * as actionType from "redux/actions/types";

export const onResetBenefits = () => {
    return {
        type: actionType.RESET_BENEFITS,
    };
};
export const onSetBenefits = (benefits) => {
    return {
        type: actionType.SET_BENEFITS,
        benefits,
    };
};
export const onSetBenefitFetchFail = (error) => {
    return {
        type: actionType.BENEFIT_FETCH_FAIL,
        error,
    };
};
