export const defaultStatusFilter = {
    Fresh: true,
    Queued: true,
    Outdated: false,
    Failed: true,
};

export const defaultQuoteStatusFilter = {
    Submitted: true,
    "Offer made": true,
    Opened: true,
    Won: true,
    Archived: false,
};

export const statisticsPeriodFilter = [
    "Current week",
    "Last week",
    "Current month",
    "Last month",
    "Current year",
    "Last year",
];

export const defaultStatisticsPeriod = statisticsPeriodFilter[2];
