import * as actionType from "redux/actions/types";
import {combineReducers} from "redux";
import {defaultStatusFilter} from "maps/filters";

export const chosenStates = (state = defaultStatusFilter, action) => {
    switch (action.type) {
        case actionType.SET_FILTER_STATUS:
            return {
                ...state,
                [action.status]: action.isChosen,
            };

        default:
            return state;
    }
};

const filterReducer = combineReducers({
    chosenStates,
});

const jobSearch = (state, action) => {
    return filterReducer(state, action);
};

export default jobSearch;
