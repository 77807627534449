import PropTypes from "prop-types";
import React, {Component} from "react";
import clsx from "clsx";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {styles} from "../../../styles/materialUIstyles/menuItemStyles";
import {withStyles} from "@material-ui/styles";
import connect from "react-redux/es/connect/connect";
import {onSetPage} from "redux/actions";
import pages from "../../../maps/pages";
import FlagIcon from "@material-ui/icons/Flag";
import AssessmentIcon from "@material-ui/icons/Assessment";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

const mUIstyles = styles;

export class MenuItem extends Component {
    static propTypes = {
        currentPage: PropTypes.string.isRequired,
        classes: PropTypes.object,
        page: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    };

    isPageActive(page) {
        return this.props.currentPage === page;
    }

    renderIcon(page) {
        switch (page) {
            case pages.DASHBOARD:
                return <FlagIcon />;
            case pages.JOBS:
                return <AssessmentIcon />;
            case pages.QUOTES:
                return <AssignmentIcon />;
            case pages.STATISTICS:
                return <TrendingUpIcon />;
            case pages.EXCLUSIONS:
                return <HighlightOffIcon />;
            default:
                return null;
        }
    }

    render() {
        const {classes, page, label} = this.props;
        return (
            <ListItem className={classes.listItem} button onClick={() => this.props.onSetPage(page)}>
                <a className={clsx(classes.navLink, this.isPageActive(page) && classes.navLinkActive)} onClick={this.props.onClick}>
                    <ListItemIcon className={clsx(classes.icon, this.isPageActive(page) && classes.activeLinkIcon)}>
                        {this.renderIcon(page)}
                    </ListItemIcon>
                    <ListItemText primary={label} classes={{primary: classes.listItemText}} />
                </a>
            </ListItem>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentPage: state.page,
        username: state.user.username,
    };
}

export default connect(
    mapStateToProps,
    {onSetPage},
)(withStyles(mUIstyles)(MenuItem));
