import React from "react";
import SalesList from "components/page/statistics/SalesList";
import {Container} from "@material-ui/core";

export default function StatisticsPage() {
    return (
        <Container disableGutters maxWidth={false}>
            <SalesList />
        </Container>
    );
}
